import { Static, Type } from '@sinclair/typebox'
import { InvestmentDto, AccountDto, PortfolioDto, InstrumentOpeningBalanceDto, AccountOpeningBalanceDto } from './common.schema'

export const CreatePortfolioDto = Type.Intersect([
  Type.Omit(PortfolioDto, ['id', 'isConsolidated', 'canEdit', 'user']),
  Type.Object({
    isConsolidated: Type.Optional(Type.Boolean({ default: false }))
  })
])

export const UpdatePortfolioDto = Type.Intersect([
  Type.Omit(PortfolioDto, ['id', 'isConsolidated', 'currencyCode', 'canEdit', 'user']),
  Type.Object({
    isConsolidated: Type.Optional(Type.Boolean({ default: false }))
  })
])

export const OpeningBalanceDto = Type.Object({
  date: Type.String({ format: 'date' }),
  investments: Type.Array(InstrumentOpeningBalanceDto),
  accounts: Type.Array(AccountOpeningBalanceDto)
})

export const ImportResultDto = Type.Object({
  uuid: Type.String(),
  status: Type.String(),
  error: Type.Optional(Type.String()),
  transactionId: Type.Optional(Type.Integer())
})

export type PortfolioDtoType = Static<typeof PortfolioDto>
export type PortfolioCreateDtoType = Static<typeof CreatePortfolioDto>
export type PortfolioUpdateDtoType = Static<typeof UpdatePortfolioDto>
export type OpeningBalanceDtoType = Static<typeof OpeningBalanceDto>
export type ImportResultDtoType = Static<typeof ImportResultDto>

export const GetPortfoliosSchema = {
  tags: ['portfolio'],
  response: {
    200: Type.Array(PortfolioDto)
  }
}

export const CreatePortfolioSchema = {
  tags: ['portfolio'],
  body: CreatePortfolioDto,
  response: {
    200: PortfolioDto
  }
}

export const UpdatePortfolioSchema = {
  tags: ['portfolio'],
  body: UpdatePortfolioDto,
  response: {
    200: PortfolioDto
  }
}

export const GetPortfolioSchema = {
  tags: ['portfolio'],
  params: Type.Object({
    id: Type.Integer()
  }),
  response: {
    200: PortfolioDto
  }
}

export const DeletePortfolioSchema = {
  tags: ['portfolio'],
  params: Type.Object({
    id: Type.Integer()
  }),
  response: {
    204: {
      type: 'null'
    }
  }
}

export const GetAccountsSchema = {
  tags: ['portfolio'],
  params: Type.Object({
    id: Type.Integer()
  }),
  response: {
    200:  Type.Array(AccountDto)
  }
}

export const GetInvestmentsSchema = {
  tags: ['portfolio'],
  params: Type.Object({
    id: Type.Integer()
  }),
  response: {
    200: Type.Array(InvestmentDto)
  }
}

export const ImportTransactionsSchema = {
  tags: ['portfolio'],
  params: Type.Object({
    id: Type.Integer()
  }),
  response: {
    200: Type.Array(ImportResultDto)
  }
}

export const ImportOpeningBalanceSchema = {
  tags: ['portfolio'],
  body: OpeningBalanceDto,
  params: Type.Object({
    id: Type.Integer()
  }),
  response: {
    200: Type.Array(Type.String())
  }
}

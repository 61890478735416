<template>
  <v-alert
    dismissible
    elevation="0"
    color="amber-lighten-3"
    border
    dense
    outlined
    icon="mdi-crown-outline"
    @click="$router.push({ name: 'subscribe' })"
    style="cursor:pointer;"
    >
    {{ authStore.tier === SubscriptionTier.Starter ? $t('portfolio.summary.partial_data_investor') : $t('portfolio.summary.partial_data') }}
  </v-alert>
</template>

<script lang="ts" setup>
import { useAuthStore } from '@/stores/authStore';
import { SubscriptionTier } from '../../../backend/src/enums';

const authStore = useAuthStore();
</script>
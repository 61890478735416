import { computed, type ComputedRef } from "vue";
import { TransactionType } from "~/enums";
import { useI18n } from "vue-i18n";
import { useInstrumentConfig } from "./instrument.helper";

export function useTransaction (form: any, baseCurrencyCode: string, amountType = 'net') {
  return computed(() => {
    const quantityCurrencyCode = form.currencyCode;
    const feeSign = amountType === 'gross' ? 0 : (form.transactionType === TransactionType.Sell ? -1 : 1);
    const exchangeRateRaw = parseFloat(form.exchangeRate ?? 1);
    const exchangeRate = baseCurrencyCode !== quantityCurrencyCode ? exchangeRateRaw : 1 / exchangeRateRaw;
    const value = parseFloat(form.quantity) * parseFloat(form.price ?? 1);
    const feeInLocalCurrency = parseFloat(form.fee || 0) * (form.feeCurrencyCode === quantityCurrencyCode ? 1 : exchangeRate)
    const costbase = value + feeInLocalCurrency * feeSign;
    const inBaseCurrency = costbase / exchangeRate;

    if (baseCurrencyCode !== quantityCurrencyCode) {
      return {
        valueInLocalCurrency: costbase,
        valueInBaseCurrency: inBaseCurrency
      }
    } else {
      return {
        valueInLocalCurrency: inBaseCurrency,
        valueInBaseCurrency: costbase
      }
    }
  })
}

export function useTransactionType (instrument: any) {
  const { t, te } = useI18n();

  const { iConf } = useInstrumentConfig(computed(() => {
    return {
      instrument: instrument.value
    }
  }))

  const allowedTypes = computed(() => {
    return Object.values(TransactionType)
      .filter((type: TransactionType) => iConf.value.allowedTransactionTypes.includes(type))
  })

  const getBroadKey = (key: string) => {
    return `instrumentType.${instrument?.type}.${key}`
  }
  
  const transactionTypeOptions = computed(() => {
    
    return allowedTypes.value.map((type: TransactionType) => {
      const broadKey = getBroadKey(`transactionType.${type}.title`)
      return {
        title: te(broadKey) ? t(broadKey) : t(`transactionType.${type}.title`),
        value: type
      }
    })
  })

  const getTranslation = (transactionType: TransactionType) => {
    if (!instrument?.type) {
      return t(`transactionType.${transactionType}.title`)
    }
    const key = getBroadKey(`transactionType.${transactionType}.title`)
    return te(key) ? t(key) : t(`transactionType.${transactionType}.title`)
  }

  return {
    transactionTypeOptions,
    getTranslation
  }
}
<template>
  <h1 class="mt-10 text-center">
    {{ t('subscription.success_title') }}
  </h1>

  <p class="text-center">
    {{ t('subscription.success_description') }}
  </p>

  <div class="d-flex justify-center mt-10">
    <v-progress-circular :size="150" color="secondary" indeterminate />
  </div>
</template>


<script setup lang="ts">
import { router } from '@/router';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/authStore';
import { onUnmounted } from 'vue';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const authStore = useAuthStore();
const appStore = useAppStore();
const interval = ref(0);
const { t } = useI18n();

onMounted(() => {
  interval.value = setInterval(async () => {
    try {
      const me = await authStore.getMe();
      if (me.subscription.tier !== 'free') {
        clearInterval(interval.value);
        appStore.showNotice(t('subscription.activated'), 'success');
        router.push({ name: 'dashboard' });
      }
    } catch (e) {
      // Ignore
    }
  }, 5000);
});

onUnmounted(() => {
  clearInterval(interval.value);
});
</script>
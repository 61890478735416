import BlankLayout from '@/layouts/blank/BlankLayout.vue';

const AuthRoutes = {
  path: '/auth',
  component: BlankLayout,
  meta: {
    requiresAuth: false
  }
};

export default AuthRoutes;

import { InstrumentType, TransactionType } from "~/enums";
import type { Transaction } from "./types";
import { currencyFormatter, countFormatter } from "@/const";

const addSymbol = (symbol: string) => {
  return `<span class="symbol">${symbol}</span>`;
}

const addWithLabel = (label: string, value: string) => {
  return `${label}: <span class="value">${value}</span>`;
}

export function createTitle (transaction: Transaction) {
  const parts = [];
  let showGross = true;
  let showDescription = false;
  const hideQuantith = [TransactionType.Expenses]

  if ([TransactionType.Interest, TransactionType.Dividend, TransactionType.InterestPaid, TransactionType.Tax, TransactionType.RentalIncome].includes(transaction.transactionType as TransactionType)) {
    if (transaction.instrument.symbol && transaction.instrument.type !== InstrumentType.Cash) {
      parts.push(addSymbol(transaction.instrument.symbol));
    }
    parts.push(currencyFormatter(transaction.account.netAmount, transaction.account.currencyCode as string));
  } else if ([TransactionType.Deposit, TransactionType.Withdraw].includes(transaction.transactionType as TransactionType)) {
    showGross = false;
    showDescription = true;
    parts.push(currencyFormatter(transaction.account.quantity, transaction.account.currencyCode as string));
  } else if ([TransactionType.Conversion].includes(transaction.transactionType as TransactionType)) {
    parts.push(addSymbol(transaction.instrument.symbol || '?'));
    parts.push(
      currencyFormatter(Math.abs(transaction.account.quantity ?? 0), transaction.account.currencyCode as string) + ' -> ' +
      currencyFormatter(transaction.counterparty.quantity, transaction.counterparty.currencyCode as string)
    );
    parts.push(addWithLabel('Kurss', (transaction.exchangeRate || 0).toString()));
  } else {
    parts.push(addSymbol(transaction.instrument.symbol || '?'));
    if (!hideQuantith.includes(transaction.transactionType as TransactionType)) {
      parts.push(addWithLabel('Kogus', countFormatter(transaction.account.quantity)));
      parts.push(addWithLabel('Hind', currencyFormatter(transaction.account.price || 0, transaction.account.currencyCode as string, 5)));
    }
  }

  if (transaction.account.fee) {
    parts.push(addWithLabel('Teenustasu', currencyFormatter(transaction.account.fee, transaction.account.feeCurrencyCode as string)));
  }

  if (transaction.account.tax) {
    parts.push(addWithLabel('Kinnipeetud maksud', currencyFormatter(transaction.account.tax, transaction.account.taxCurrencyCode as string)));
  }

  if (showGross && transaction.account.grossAmount) {
    parts.push(addWithLabel('Summa', currencyFormatter(transaction.account.grossAmount, transaction.account.currencyCode as string)));
  }

  if (transaction.counterpartyName) {
    parts.push(addWithLabel('Saatja/saaja', transaction.counterpartyName));
  }

  if (showDescription && transaction.description) {
    parts.push(transaction.description);
  }

  return parts.join(' • ');
}

export const mapToIntnernal = (prepared: any[]) => {
  prepared.forEach((element: any) => {
    element.selected = false;
    element.isValid = null;
    element.mappingIsValid = null;
    element.control = {
      isSelected: false,
      isValid: null
    }
    element._account = {...element.account};
    element._counterparty = {...element.counterparty};
  });

  return prepared;
}

<script setup lang="ts">
import { computed } from 'vue';
import InteractiveBrokers from './InteractiveBrokers.vue';
import Lightyear from './Lightyear.vue';
import LHV from './LHV.vue';

const props = defineProps({
  providerId: {
    required: false
  }
})

const brokerInfo = computed(() => {
  switch (props.providerId) {
    case 1:
      return LHV;
    case 2:
      return Lightyear;
    case 3:
      return InteractiveBrokers;
  }
})
</script>

<template>
  <component v-if="brokerInfo" :is="brokerInfo"></component>
  <div v-else>
    <div class="text-body-1">
      <p class="mt-5">
        {{ $t('import_page.help_text_1') }}
      </p>
      <p class="mt-5">
        {{ $t('import_page.help_text_2') }}
      </p>
      <p class="mt-5" v-html="$t('import_page.help_text_3')" />
      <p class="mt-5">
        {{ $t('import_page.help_text_4') }}
      </p>
      <p class="mt-5" v-html="$t('import_page.help_text_5')" />
    </div>
  </div>
</template>
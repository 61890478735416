<template>
  <Dialog v-model:model-visible="visible" :title="$t('account_page.linking_failed')">
    <div class="text-body-1">
      {{ $t('account_page.linking_failed_help') }}
    </div>
    <v-btn color="error" @click="visible = false" class="mt-4">
      {{ $t('label.close') }}
    </v-btn>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from '@/components/Dialog.vue';
import { computed } from 'vue';

const props = defineProps({
  modelVisible: Boolean,
  account: {
    type: Object,
    required: false
  }
});

const emit = defineEmits(['update:modelVisible']);

const visible = computed({
  get: () => props.modelVisible,
  set: (value) => {
    emit('update:modelVisible', value)
  }
});
</script>
<template>
  <v-tooltip max-width="300" :close-delay="100">
    <template v-slot:activator="{ props }">
      <v-icon :color="color" :size="size" :icon="icon" v-bind="props" />
    </template>
    <span>{{ computedTooltip }}</span>
  </v-tooltip>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore';
import { computed } from 'vue';
import { SubscriptionTier } from '../../../backend/src/enums';

const authStore = useAuthStore();

const props = defineProps({
  size: {
    type: String,
    default: '26'
  },
  color: {
    type: String,
    default: 'amber'
  },
  icon: {
    type: String,
    default: 'mdi-crown-outline'
  },
  tooltip: {
    type: String,
    default: ''
  }
});

const computedTooltip = computed(() => {
  if (props.tooltip) {
    return props.tooltip;
  }
  if (authStore.tier === SubscriptionTier.Starter) {
    return 'Selle väärtuse arvutamiseks on arvesse võetud kuni 30 sinu portfelli kuuluvast investeeringust. Kui soovid näha kõiki investeeringuid, siis telli järgmine pakett.'
  } else {
    return 'Selle väärtuse arvutamiseks on arvesse võetud kuni 10 sinu portfelli kuuluvast investeeringust. Kui soovid näha kõiki investeeringuid, siis telli tasuline pakett.'
  }
});
</script>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useInvestmentStore } from '@/stores/investmentStore';
import * as I from '~/investment.schema';
import FormContainer from '@/components/shared/FormContainer.vue';
import { reactive } from "vue";
import { fieldDesign } from '@/const';
import { usePortfolioStore } from '@/stores/portfolioStore';

const emit = defineEmits(['update:modelValue', 'update:isValid', 'close']);
const props = defineProps(['action', 'investment', 'modelValue', 'isValid'])

const investmentStore = useInvestmentStore();
const portfolioStore = usePortfolioStore();

const formRef = ref(null);
const isLoading = ref(false);

const investment = computed(() => {
  return props.investment ?? investmentStore.investment as I.InvestmentDtoType;
})

const portfolioOptions = computed(() => {
  if (portfolioStore.portfolios.loading) {
    return [];
  }
  return portfolioStore.portfolios.value
    .filter((portfolio) => !portfolio.isConsolidated)
    .map((portfolio) => {
      return {
        value: portfolio.id,
        title: portfolio.name
      }
    })
})

const isValid = computed({
  get() {
    return props.isValid;
  },
  set(value) {
    emit('update:isValid', value);
  }
})

const isFormLoading = computed(() => {
  return portfolioStore.portfolios.loading;
})

const form = reactive<Partial<I.MoveInvestmentPortfolioDtoType>>({
  portfolioId: portfolioStore.portfolio.id
})

const onSubmit = async () => {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;
  try {
   await investmentStore.moveToOtherPortfolio(investment.value.id, form as I.MoveInvestmentPortfolioDtoType);
   await investmentStore.get(investment.value.id, true);
   emit('close')
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <FormContainer
    v-model="isValid"
    ref="formRef"
    title="Move to other portfolio"
    mode="confirm"
    @submit="onSubmit"
    :is-form-loading="isFormLoading"
    :is-button-loading="isLoading"
    :action="action"
  >
    <v-select
      label="Portfolio"
      v-bind="fieldDesign"
      v-model="form.portfolioId"
      :items="portfolioOptions"
    />
  </FormContainer>
</template>

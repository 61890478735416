import { InstrumentType, TransactionType } from "../enums";
import { InstrumentConfig } from "./Instrument.config";

class DepositConfig extends InstrumentConfig {
  type: InstrumentType = InstrumentType.Deposit;
  hasPrice: boolean = false;
  showQuantityInValue: boolean = false;
  quantityIsCurrency: boolean = true;
  icon: string = '🏦';

  allowedTransactionTypes = [
    TransactionType.Buy,
    TransactionType.Sell,
    TransactionType.Writeoff,
    TransactionType.Opening
  ]
  allowedIncomeTypes = [
    TransactionType.Interest,
    TransactionType.Tax,
  ]
}

export default new DepositConfig();

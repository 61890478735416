<template>
  <Dialog v-model:model-visible="visible" :title="$t('account_page.remove_link')">
    <div class="text-body-1">
      <strong>
        {{ $t('account_page.remove_link_confirm') }}
      </strong>
      <p class="mt-2" v-if="account?.link?.service === 'go-cardless'">
        {{ $t('account_page.remove_link_go_cardless') }}
      </p>
      <p class="mt-2" v-if="account?.link?.service === 'go-cardless'">
        {{ $t('account_page.remove_link_go_cardless_2') }}
      </p>
      <p class="mt-2">
        {{ $t('account_page.remove_link_confirm_2') }}
      </p>
    </div>
    <v-btn color="error" @click="unlink" :loading="isLoading" class="mt-4">
      {{ $t('label.confirm') }}
    </v-btn>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from '@/components/Dialog.vue';
import { useAppStore } from '@/stores/app';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { computed } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  modelVisible: Boolean,
  account: {
    type: Object,
    required: false
  }
});

const emit = defineEmits(['update:modelVisible', 'unlinked']);
const { t } = useI18n();

const visible = computed({
  get: () => props.modelVisible,
  set: (value) => {
    emit('update:modelVisible', value)
  }
});

const appStore = useAppStore();

const isLoading = ref(false);

const unlink = async () => {
  isLoading.value = true;
  try {
    await fetchWrapper.put(`/api/v1/import/unlink/${props.account?.link?.id}`);
    appStore.showNotice(t('account_page.messages.connection_removed'), 'success');
    visible.value = false;
    emit('unlinked');
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
}
</script>
import { usePortfolioStore } from "@/stores/portfolioStore";
import { computed, type Ref } from "vue";
import type { AccountDtoType, CreateCashAccountTransactionDtoType } from "~/cash-account.schema";

export function relatedAccountUtils (account: any, transaction?: Ref<CreateCashAccountTransactionDtoType>) {
  const portfolioStore = usePortfolioStore();

  const relatedAccounts = computed(() => {
    const selectedAccount = portfolioStore.portfolioAccounts.item.find((pAccount) => pAccount.id === transaction?.value?.counterparty?.id);

    const options = portfolioStore.portfolioAccounts.item
      .filter((pAccount) => pAccount.isRoot)
      .filter((pAccount) => !account || !account.id || (pAccount.id !== account.id))
      .map((pAccount) => {
        pAccount.id = selectedAccount && pAccount.id === selectedAccount?.parentAccountId ? selectedAccount.id : pAccount.id
        return pAccount
      })

    return options
  })

  const relatedAccountOptions = computed(() => {
    const options = relatedAccounts.value
      .map((pAccount) => {
        return {
          title: `${pAccount.name}`,
          value: pAccount.id
        }
      })

    return options
  })

  return {
    relatedAccountOptions,
    relatedAccounts,
    isLoading: computed(() => portfolioStore.portfolioAccounts.loading)
  }
}

export function relatedAccount (accountId: any) {
  const portfolioStore = usePortfolioStore();

  return computed(() => {
    if (!accountId.value || !Array.isArray(portfolioStore.portfolioAccounts.item)) {
      return null;
    }
    return portfolioStore.portfolioAccounts.item
      .find((pAccount) => pAccount.id === accountId.value);
  })
}

export function useAccountLink (account: Ref<AccountDtoType>) {
  const isLinkingEnabled = computed(() => {
    if (account.value.link?.service === 'ib') {
      return true;
    } else if (account.value.link?.service === 'go-cardless') {
      return true;
    }
    return false;
  });

  const canBeLinked = computed(() => account.value.link?.canBeLinked && isLinkingEnabled.value);

  return {
    canBeLinked,
    isLinkingEnabled
  }
}

<template>
  <div class="d-flex text-center text-caption flex-column">
    <div class="d-flex ga-2 flex-row justify-center">
      <v-btn density="compact" variant="plain" @click="$i18n.locale='et'" style="font-size:20px;min-width:auto;padding:0;">🇪🇪</v-btn>
      <v-btn density="compact" variant="plain" @click="$i18n.locale='en'" style="font-size:20px;min-width:auto;padding:0;">🇬🇧</v-btn>
      <v-btn density="compact" variant="plain" @click="toggleTheme" style="font-size:14px;min-width:auto;padding:0;">
        <v-icon>{{ $vuetify.theme.current.dark ? 'mdi-white-balance-sunny' : 'mdi-brightness-4' }}</v-icon>
      </v-btn>
    </div>
    <div>
      <a href="https://portfellow.com/terms-and-conditions/" class="text-lightText">{{$t('label.terms_and_conditions')}}</a> {{ $t('register_page.and') }} <a href="https://portfellow.com/privacy-policy/" class="text-lightText">{{ $t('label.privacy_policy') }}</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from '@/stores/app';

const appStore = useAppStore();

const toggleTheme = () => {
  if (appStore.theme === 'MainTheme') {
    appStore.setTheme('MainThemeDark')
  } else {
    appStore.setTheme('MainTheme')
  }
}
</script>
<script setup lang="ts">
import { ref } from 'vue';
import { currencyCodes, fieldDesign, rules } from '@/const';
import type * as S from '~/loan-account.schema';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useLoanStore } from '@/stores/loanAccountStore';
import { computed } from 'vue';
import type { PortfolioDtoType } from '~/portfolio.schema';
import { useRouter } from 'vue-router';
import FormContainer from '@/components/shared/FormContainer.vue';
import { useAppStore } from '@/stores/app';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import CustomGroupSelectFieldVue from '@/components/inputs/CustomGroupSelectField.vue';
import IncomeConfigForm from '@/components/partial/IncomeConfigForm.vue';
import { FixedIncomeType, InstrumentType } from '~/enums';
import TabsLayout from '@/components/layout/TabsLayout.vue';
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue';

const portfolioStore = usePortfolioStore();
const loanStore = useLoanStore();
const appStore = useAppStore();
const router = useRouter();
const { t } = useI18n();

const emit = defineEmits(['created']);
const props = defineProps(['action', 'location'])

const confirmDialog = ref(null);
const isLoading = ref(false);
const isFormLoading = ref(false);
const isUpdate = computed(() => {
  return props.action === 'update';
});

const portfolio = computed(() => portfolioStore.activePortfolio as PortfolioDtoType);

const translations = computed(() => {
  return {
    title: isUpdate.value ? t('loan_page.form.update_title') : t('loan_page.form.create_title')
  }
})

const form = ref<Partial<S.LoanAccountCreateDtoType>>({
  name: undefined,
  symbol: undefined,
  currencyCode: portfolio.value?.currencyCode,
  portfolioId: portfolioStore.activePortfolioId,
  customGroups: [],
  isArchived: false,
  fixedRateInstrumentConfig: {
    taxRate: undefined,
    hasFixedIncome: false,
    incomeType: FixedIncomeType.Bullet,
    incomeAmount: undefined,
    paymentFrequencyType: undefined,
    dateOfFirstPayment: undefined,
    dateOfLastPayment: undefined,
  },
})

watch(portfolio, (portfolio) => {
  if (!portfolio) {
    return;
  }
  form.value.currencyCode = portfolio.currencyCode;
  form.value.portfolioId = portfolio.id;
}, { immediate: true })

watch(() => loanStore.loan, (loan) => {
  if (!loan || !loan.id || !isUpdate.value) {
    return;
  }
  form.value.portfolioId = loan.portfolioId;
  form.value.name = loan.name;
  form.value.symbol = loan.symbol;
  form.value.currencyCode = loan.currencyCode;
  form.value.isArchived = loan.isArchived;
  form.value.fixedRateInstrumentConfig = loan.fixedRateInstrumentConfig || {
    hasFixedIncome: false,
    incomeType: FixedIncomeType.Bullet,
  };
}, { immediate: true });

const onSubmit = async () => {
  isLoading.value = true;

  try {
    if (isUpdate.value) {
      await loanStore.updateLoanAccount(loanStore.loan.id, form.value);
      appStore.showNotice(t('loan_page.messages.updated'), 'success');
    } else {
      const account = await loanStore.createLoanAccount(form.value);
      appStore.showNotice(t('loan_page.messages.created'), 'success');
      if (props.location === 'modal') {
        emit('created', account)
      } else {
        router.push({ name: 'loan', params: { loanId: account.id }});
      }
    }
    await portfolioStore.getCategories();
  } finally {
    isLoading.value = false;
  }
}

const onDelete = async () => {
  isFormLoading.value = true;

  try {
    await loanStore.deleteLanAccount(loanStore.loan.id);
    appStore.showNotice(t('loan_page.messages.deleted'), 'success');
    router.push({ name: 'portfolio', params: { portfolioId: portfolio.value.id }});
  } finally {
    isFormLoading.value = false;
  }
}

const triggerDelete = async () => {
  if (await confirmDialog.value?.open(t('label.confirm'), t('loan_page.form.delete_confirm'))) {
    onDelete();
  }
}

const tab = ref('settings');

const tabs = computed(() => {
  return [
    { label: t('portfolio_page.form.settings'), value: 'settings', icon: 'mdi-cog', disabled: false },
    { label: t('label.cashflow'), cashflow: 'goals', icon: 'mdi-stack-overflow', disabled: false },
    { label: t('investment_page.actions'), value: 'actions', icon: 'mdi-button-pointer', disabled: !isUpdate.value },
  ]
})
</script>

<template>
  <v-row>
    <v-col cols="12" lg="12">
      <FormContainer
        ref="formRef"
        :title="translations.title"
        :mode="isUpdate ? 'update' : 'create'"
        @submit="onSubmit"
        @delete="onDelete"
        :is-form-loading="isFormLoading"
        :is-button-loading="isLoading"
        :description="t('loan_page.description')"
        color="amber"
        :flavour="'tabs'"
        hide-delete
      >
        <TabsLayout :tabs="tabs" v-model="tab">
          <v-tabs-window-item value="settings">
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-bind="fieldDesign"
                  type="text"
                  v-model="form.name"
                  :label="t('label.loan_account_name')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-bind="fieldDesign"
                  type="text"
                  v-model="form.symbol"
                  :label="t('label.loan_code')"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-bind="fieldDesign"
                  :items="currencyCodes"
                  :label="t('label.currency')"
                  v-model="form.currencyCode"
                  :disabled="isUpdate"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" lg="12">
                <CustomGroupSelectFieldVue
                  v-bind="fieldDesign"
                  :label="'Kohandatud grupid'"
                  v-model="form.customGroups"
                  :investmentId="isUpdate ? `L${loanStore.loanId}` : null"
                />
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-bind="fieldDesign"
                  v-model="form.isArchived"
                  :label="t('label.is_archived')"
                />
              </v-col>
            </v-row>
          </v-tabs-window-item>
          <v-tabs-window-item value="cashflow">
            <v-row>
              <IncomeConfigForm
                :form="form"
                variant="loan"
                :instrumentType="InstrumentType.TakenLoan"
              />
            </v-row>
          </v-tabs-window-item>
          <v-tabs-window-item value="actions">
            <v-row>
              <v-col cols="12">
                <div class="d-flex ga-2">
                  <v-btn prepend-icon="mdi-delete" @click="triggerDelete" color="error" :disabled="isLoading" variant="tonal">
                    {{ $t('label.delete') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-tabs-window-item> 
        </TabsLayout>
      </FormContainer>
    </v-col>
  </v-row>
  <ConfirmDialog ref="confirmDialog" />
</template>

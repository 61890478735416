<script setup lang="ts">
import { ref, computed } from 'vue';
import { useInvestmentStore } from '@/stores/investmentStore';
import * as I from '~/investment.schema';
import FormContainer from '@/components/shared/FormContainer.vue';
import { reactive } from "vue";
import InstrumentSearchField from '@/components/inputs/InstrumentSearchField.vue';
import { fieldDesign, rules } from '@/const';
import type { InstrumentDtoType } from '~/instrument.schema';
import { useRouter } from 'vue-router';

const emit = defineEmits(['update:modelValue', 'update:isValid', 'close']);
const props = defineProps(['action', 'investment', 'modelValue', 'isValid'])

const investmentStore = useInvestmentStore();

const formRef = ref(null);
const isLoading = ref(false);
const instrument = ref<InstrumentDtoType | null>(null);
const router = useRouter();

const investment = computed(() => {
  return props.investment ?? investmentStore.investment as I.InvestmentDtoType;
})

const isValid = computed({
  get() {
    return props.isValid;
  },
  set(value) {
    emit('update:isValid', value);
  }
})


const form = reactive<Partial<I.MoveInvestmentDtoType>>({
  instrumentId: undefined
})

const onSubmit = async () => {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;
  try {
    form.instrumentId = instrument.value?.id;
    await investmentStore.moveToListedInstrument(investment.value.id, form as I.MoveInvestmentDtoType);
    router.go(0);
    emit('close');
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <FormContainer
    color="primary"
    v-model="isValid"
    ref="formRef"
    title="Muuda investeeringu instrumenti"
    mode="confirm"
    @submit="onSubmit"
    :is-form-loading="false"
    :is-button-loading="isLoading"
    :action="action"
  >
    <div style="width: 100%;">
      <InstrumentSearchField
        v-model="instrument"
        v-model:model-is-loading="isLoading"
        :fieldDesign="fieldDesign"
        :rules="[rules.required]"
        :hide-create-manual="true"
      />
    </div>
  </FormContainer>
</template>

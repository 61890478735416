import { InstrumentType, TransactionType } from "../enums";
import { InstrumentConfig } from "./Instrument.config";

class Cryptocurrency extends InstrumentConfig {
  type: InstrumentType = InstrumentType.Cryptocurrency;
  canBeMigratedToPublic: boolean = true;
  icon: string = '💎';

  allowedTransactionTypes = [
    TransactionType.Buy,
    TransactionType.Sell,
    TransactionType.Opening,
    TransactionType.Fee,
  ]
  allowedIncomeTypes = [
    TransactionType.Interest,
    TransactionType.OtherIncome,
  ]
}

export default new Cryptocurrency();

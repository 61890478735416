import { currencyFormatter, dateTimeFormatterChart } from '@/const';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';

declare module "highcharts" {
  interface Chart {
    annotations: any[];
    customTooltip: Highcharts.SVGElement;
  }
}

export const enableAnnotationTooltips = (chart: Highcharts.Chart, isDark: boolean) => {
  const annotations = chart.annotations || [];

  chart.customTooltip = chart.renderer
    .label("", 0, 0, "callout", 0, 0, true)
    .attr({
      fill: isDark ? "#171621" : "#fff",
      r: 6,
      "stroke-width": 1,
      stroke: isDark ? "#171621" : "#e2e3e4"
    })
    .add()
    .toFront()
    .hide();

  annotations.forEach((annotation) => {
    annotation.labels.forEach((label: any) => {
      if (!label.options.tooltip) {
        return;
      }
      const onEnter = () => {
        chart.customTooltip
          .attr({
            text: label.options.tooltip.text
          })

        const annGraphic = label.graphic,
          tooltipBBox = chart.customTooltip.getBBox(),
          chartWidth = chart.chartWidth,
          chartHeight = chart.chartHeight,
          annCenterX = annGraphic.x + annGraphic.width / 2,
          annCenterY = annGraphic.y - annGraphic.height / 2;

        
        let tooltipX = annCenterX - tooltipBBox.width / 2;
        let tooltipY = annCenterY - tooltipBBox.height / 2 - 10;


        // Adjust horizontally if the tooltip is too close to the left or right edge
        if (tooltipX < 0) {
          tooltipX = 5;  // Add padding from the left edge
        } else if (tooltipX + tooltipBBox.width > chartWidth) {
          tooltipX = chartWidth - tooltipBBox.width - 5;  // Add padding from the right edge
        }

        // Adjust vertically if the tooltip is too close to the top or bottom edge
        if (tooltipY < 0) {
          tooltipY = 5;  // Add padding from the top edge
        } else if (tooltipY + tooltipBBox.height > chartHeight) {
          tooltipY = chartHeight - tooltipBBox.height - 5;  // Add padding from the bottom edge
        }

        chart.customTooltip
          .attr({
            x: tooltipX,
            y: tooltipY,
            anchorX: annCenterX,
            anchorY: annCenterY
          })
          .show();
      }
      label.graphic.on("mouseover", onEnter);
      label.graphic.on("touchstart", onEnter);

      label.graphic.on("mouseout", () => {
        chart.customTooltip.hide();
      });
      label.graphic.on("touchend", () => {
        chart.customTooltip.hide();
      });
    });
  });
};

export const dateTimeTooltipFormatter = (chart: Highcharts.TooltipFormatterContextObject, props: { currency: string }) => {
  const timestamp = (chart.x as number);
  let date = dayjs(timestamp).utc();
  if (date && date.isSame(dayjs(), 'day') && !date.isSame(date.endOf('day'), 'second')) {
    date = dayjs(timestamp);
  }
  const formattedTimestamp = dateTimeFormatterChart(date.format('YYYY-MM-DD HH:mm:ss'));
  const lines = [
    `<b>${formattedTimestamp}</b>`
  ]
  lines.push(`<span style="color:${chart.color}">\u25CF</span> ${chart.series.name}: ${currencyFormatter(chart.y as number, props.currency)}`)
  return lines.join('<br/>');
}

import type { App } from 'vue';

const marketingQueryVariables = {
  coupon: {},
  referralCode: {},
}

type Variable = keyof typeof marketingQueryVariables;

export default {
  install(app: App) {
    app.provide('marketing', this);
    const valuesFromUrl = new URLSearchParams(window.location.search);
    const foundVariables: { [key in Variable]?: string | null } = {};
    let found = false;

    Object.keys(marketingQueryVariables).forEach((key: string) => {
      if (valuesFromUrl.has(key) && !!valuesFromUrl.get(key)) {
        foundVariables[key as Variable] = valuesFromUrl.get(key);
        found = true;
      }
    });

    if (found) {
      const existingValues = JSON.parse(window.localStorage.getItem('marketing') || '{}');
      window.localStorage.setItem('marketing', JSON.stringify({ ...existingValues, ...foundVariables }));
    }
  },
  getMarketingVariables(): { [key in Variable]?: string | null } {
    return JSON.parse(window.localStorage.getItem('marketing') || '{}');
  }
}

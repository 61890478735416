<script setup lang="ts">
import { useLoanStore } from '@/stores/loanAccountStore';
import { computed, ref } from 'vue';
import { currencyFormatter, signNumberBasedOnTransactionType, formatNumberBasedOnTransactionType } from '@/const';
import { router } from '@/router';
import { HoldingType } from '~/enums';
import { useI18n } from 'vue-i18n';
import TransactionTypeCell from '@/components/table/TransactionTypeCell.vue';
import DateCell from '@/components/table/DateCell.vue';
import { usePortfolioStore } from '@/stores/portfolioStore';

const emit = defineEmits(['edit']);
const props = defineProps({
  mode: {
    type: String,
    required: false
  },
  loanId: {
    type: Number,
    required: true
  }
})

const loanStore = useLoanStore();
const portfolioStore = usePortfolioStore();

const transactions = computed(() => loanStore.transactions);
const portfolio = computed(() => portfolioStore.activePortfolio);
const { t } = useI18n();

const isHoldingMode = computed(() => props.mode === 'holding');

const headers = ref([{
  title: t('label.date'),
  align: 'start',
  sortable: false,
  key: 'dateTransactionAt',
}, {
  title: t('label.transaction_type_short'),
  align: 'start',
  sortable: false,
  key: 'transactionType',
}, {
  title: t('label.description'),
  align: 'start',
  sortable: false,
  key: 'description',
}, {
  title: t('label.related_account'),
  align: 'start',
  sortable: false,
  key: 'relatedHolding.name',
}, {
  title: t('label.sum'),
  align: 'end',
  sortable: false,
  key: 'quantity',
},{
  title: t('label.balance'),
  align: 'end',
  sortable: false,
  key: 'balance',
}, {
  align: 'end',
  title: '',
  key: 'actions',
  sortable: false,
  width: '40px'
}])

const loadItems = (params: any) => {
  if (!props.loanId) {
    return;
  }
  loanStore.getLoanAccountTransactions(props.loanId, {
    limit: params.itemsPerPage,
    offset: (params.page - 1) * params.itemsPerPage,
  });
}

const editTransaction = (transaction: any) => {
  if (isHoldingMode.value) {
    emit('edit', transaction);
    return;
  }

  let name = 'loan-edit-transaction';
  let params: any = { investmentId: transaction.accountIid, transactionId: transaction.id };

  if (transaction.relatedHolding?.type === HoldingType.Investment) {
    if (transaction.isIncome) {
      name = 'investment-edit-income';
      params = { investmentId: transaction.relatedHolding.id, incomeId: transaction.relatedTransactionId };
    } else {
      name = 'investment-edit-transaction';
      params = { investmentId: transaction.relatedHolding.id, transactionId: transaction.relatedTransactionId };
    }
  }

  router.push({
    name,
    params,
  })
}
</script>

<template>
   <v-data-table-server
    class="mb-4 hide-per-page"
    density="compact"
    v-model:items-per-page="transactions.value.pageSize"
    :headers="headers"
    :items-length="transactions.value.totalItems ?? 0"
    :items="transactions.value.items"
    :loading="!!transactions.loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template v-slot:item.dateTransactionAt="{ value }">
      <DateCell :value="value" />
    </template>
    <template v-slot:item.transactionType="{ value, item }">
      <TransactionTypeCell :value="value" :transaction="item" :instrumentType="'loan'" :isLoan="true" />
    </template>
    <template v-slot:item.quantity="{ item }">
      <span v-bind="formatNumberBasedOnTransactionType(item.transactionType, item.quantity)">{{ currencyFormatter(signNumberBasedOnTransactionType(item.transactionType, item.quantity), item.currencyCode) }}</span>
    </template>
    <template v-slot:item.balance="{ item }">
      {{ item.balance !== null ? currencyFormatter(item.balance, item.currencyCode) : '' }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon @click="editTransaction(item)" size="small" class="me-2" v-if="portfolio?.canEdit">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ t('label.no_data') }}
    </template>
  </v-data-table-server>
</template>
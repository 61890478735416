<script setup lang="ts">


</script>

<template>
  <v-row>
    <v-col cols="12">
      Add Transaction
    </v-col>
  </v-row>
</template>

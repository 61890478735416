<script setup lang="ts">
import { computed } from 'vue';
import AmountField from './AmountField.vue';

const emit = defineEmits(['update:modelValue', 'update:currencyCodeValue']);

const props = defineProps({
  modelValue: null,
  currencyCodeValue: null,
  fieldDesign: {
    type: Object,
    default: () => ({})
  },
  rules: {
    type: Array,
    default: () => ([])
  },
  label: {
    type: String,
    default: 'Transaction fees'
  },
  portfolioCurrency: {
    type: String,
    default: null
  },
  instrumentCurrency: {
    type: String,
    default: null
  },
  toCurrency: {
    type: String,
    default: null
  }
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const currencyCodeValue = computed({
  get() {
    return props.currencyCodeValue
  },
  set(value) {
    emit('update:currencyCodeValue', value)
  }
});

const currencyOptions = computed(() => {
  const options = []

  if (props.instrumentCurrency) {
    options.push({
      title: props.instrumentCurrency,
      value:props.instrumentCurrency
    })
  }
  if (props.portfolioCurrency && props.portfolioCurrency !== props.instrumentCurrency) {
    options.push({
      title: props.portfolioCurrency,
      value: props.portfolioCurrency
    })
  }
  if (props.toCurrency && props.toCurrency !== props.instrumentCurrency && props.toCurrency !== props.portfolioCurrency) {
    options.push({
      title: props.toCurrency,
      value:props.toCurrency
    })
  }

  return options;
})

</script>

<template>
  <amount-field
    v-model:model-value="value"
    v-model:currency-code-value="currencyCodeValue"
    :currencyOptions="currencyOptions"
    :label="props.label"
  />
</template>

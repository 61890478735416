<template>
  <DisplayCard :title="$t('label.future_cashflow')" class="overflow-hidden" v-if="isFutureIncomeLoading || futureIncome?.transactions?.length">
    <template v-slot:actions>
      <div class="d-flex ga-2 align-center">
        <v-btn color="primary" variant="tonal" :to="{
          name: 'future-cashflow-report',
          params: {
            portfolioId: portfolioStore.selectedPortfolioId || 0
          },
          query: {
            period: 'next_6_months',
            interval: 'month'
          }
        }">{{ $t('label.view_report') }}</v-btn>
        <slot name="configuration" />
      </div>
    </template>
    <v-row>
      <v-col cols="12" md="7" class="pl-5">
        <BarChart
          class="mt-2"
          :values="chartData"
          :categories="chartCategories"
          :isLoading="isLoading"
          stacking="normal"
          v-if="!isFutureIncomeLoading"
        />
        <div v-else class="px-5 pb-5">
          <v-skeleton-loader :boilerplate="true" height="280" style="width:100%;" type="ossein" />
        </div>
      </v-col>
      <v-col cols="12" md="5">
        <v-list>
          <v-list-item v-for="tx in transactions">
            <template v-slot:prepend>
              <Avatar
                :code="tx.codeValue"
                :logo="tx.logoValue"
                :size="40"
              />
            </template>
            <template v-slot:title>
              <Investment :holding="tx.holding" flavour="name" />
            </template>
            <template v-slot:subtitle>
              <div class="d-flex align-center ga-2">
                {{ dateFormatter(tx.dateTransactionAt) }}
              </div>
            </template>
            <template v-slot:append>
              <div class="d-flex flex-column align-end">
                <PriceCell
                  :value="tx.totalValue"
                  :transactionType="tx.transactionType"
                  :currency-code="tx.currencyCode"
                  :maximumFractionDigits="2"
                  is-total-value
                />
                <TransactionTypeCell 
                  :value="tx.transactionType" 
                  :transaction="tx" 
                  is-cashflow 
                  hide-icon
                  :upper-case="false"
                  class="text-body-1"
                />
              </div>
            </template>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </DisplayCard>
</template>

<script setup lang="ts">
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useFutureCashflowReport } from '@/utils/chart.utils';
import { computed, ref, watch } from 'vue';
import { dateFormatter } from '@/const';
import { useReportStore } from '@/stores/reportStore';
import BarChart from '@/components/BarChart.vue';
import Avatar from '@/components/Avatar.vue';
import TransactionTypeCell from '@/components/table/TransactionTypeCell.vue';
import PriceCell from '@/components/table/PriceCell.vue';
import { useInvestment } from '@/utils/instrument.utils';

const portfolioStore = usePortfolioStore();
const reportStore = useReportStore();

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  }
});

const futureIncome = ref<any>([]);
const isFutureIncomeLoading = ref(true);

const { chartData, chartCategories } = useFutureCashflowReport(futureIncome);

async function loadFutureIncomeReport() {
  try {
    if (!portfolioStore.selectedPortfolioId) {
      return
    }
    isFutureIncomeLoading.value = true;
    reportStore.getFutureFashflowReport(portfolioStore.selectedPortfolioId, {
      period: 'next_6_months',
      interval: 'month',
      page: 1,
      pageSize: 7
    }).then((data) => {
      futureIncome.value = data;
    }).finally(() => {
      isFutureIncomeLoading.value = false;
    });
  } finally {

  }
}

const transactions = computed(() => (futureIncome.value.transactions || []).map((tx: any) => {
  const { codeValue, logoValue } = useInvestment(
    computed(() => tx.holding),
    computed(() => undefined),
    computed(() => undefined),
  )

  return {
    ...tx,
    codeValue: codeValue.value,
    logoValue: logoValue.value,
  }
}));

watch(() => portfolioStore.selectedPortfolioId, async () => {
  await loadFutureIncomeReport();
}, { immediate: true });
</script>
<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import PriceCell from '@/components/table/PriceCell.vue';
import { useI18n } from 'vue-i18n';
import DateCell from '@/components/table/DateCell.vue';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import { usePagination } from '@/filter.helper';
import { useAccountStore } from '@/stores/accountStore';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { usePortfolioStore } from '@/stores/portfolioStore';

const emits = defineEmits(['edit:balance']);

const { t } = useI18n();
const { page, perPage, perPageOptions } = usePagination()
const accountStore = useAccountStore();
const portfolioStore = usePortfolioStore();

const isDeleteActionLoading = ref(false);
const portfolio = computed(() => portfolioStore.activePortfolio);

const balanceItems = computed(() => accountStore.balanceItems);

const loadData = async () => {
  if (!accountStore.account.id) {
    return;
  }
  return accountStore.getCashAccountBalanceItems(accountStore.account.id, {
    limit: perPage.value,
    offset: (page.value - 1) * perPage.value,
  });
}

watch(() => [page.value, perPage.value], () => {
  loadData();
});

const headers = ref([{
  title: t('label.date'),
  align: 'start',
  sortable: false,
  key: 'date',
}, {
  title: t('label.balance'),
  align: 'end',
  sortable: false,
  key: 'balance',
}, {
  align: 'end',
  title: '',
  key: 'actions',
  sortable: false,
  width: '40px'
}]);

const del = async (item: any) => {
  try {
    isDeleteActionLoading.value = true;
    await fetchWrapper.delete(`/api/v1/account/${accountStore.account.id}/balance/${item.id}`);
    await loadData();
  } finally {
    isDeleteActionLoading.value = false;
  }
}
</script>

<template>
  <DisplayCard :title="$t('account_page.balance_history')" color="panel-heading-bg">
    <v-data-table-server
      class="mb-4"
      density="compact"
      v-model:items-per-page="perPage"
      v-model:page="page"
      :headers="headers"
      :items-length="balanceItems.value.totalItems ?? 0"
      :items="balanceItems.value.items"
      :loading="!!balanceItems.loading"
      item-value="name"
      :items-per-page-options="perPageOptions"
    >
      <template v-slot:item.date="{ value }">
        <DateCell :value="value" />
      </template>
      <template v-slot:item.balance="{ value, item }">
        <PriceCell :value="value" :currency-code="item.currencyCode" isTotalValue adjustSign />
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-center" v-if="portfolio?.canEdit">
          <v-btn variant="plain" @click="$emit('edit:balance', item)" size="small" icon="mdi-pencil" />
          <v-btn variant="plain" @click="del(item)" :loading="isDeleteActionLoading" size="small" icon="mdi-delete" color="error" />
        </div>
      </template>
      <template v-slot:no-data>
        {{ t('label.no_data') }}
      </template>
    </v-data-table-server>
  </DisplayCard>
</template>

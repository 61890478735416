<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useInvestmentStore } from '@/stores/investmentStore';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import { useLoanStore } from '@/stores/loanAccountStore';
import { watch } from 'vue';
import TransactionsTable from '../loan/TransactionsTable.vue';
import { usePortfolioStore } from '@/stores/portfolioStore';

const router = useRouter();
const investmentStore = useInvestmentStore();
const loanStore = useLoanStore();
const portfolioStore = usePortfolioStore();
const { t } = useI18n();

const investment = computed(() => investmentStore.investment);
const hasLoan = computed(() => !!investmentStore.investment?.loanHoldingId);
const portfolio = computed(() => portfolioStore.activePortfolio);

watch (() => investment.value?.loanHoldingId, async (loanId) => {
  if (loanId) {
    await loanStore.getLoanAccountTransactions(loanId)
  } else {
    loanStore.transactions.value = []
  }
}, { immediate: true })

const create = () => {
  router.push({
    name: 'loan-create-transaction',
    params: { loanId: investment.value?.loanHoldingId }
  })
}

const createLoan = async () => {
  try {
    await investmentStore.createLoan(investment.value.id);
    await investmentStore.get(investment.value.id, true);
  } catch (_e) {
    // ignore
  }
}

const onEdit = (transaction: any) => {
  router.push({
    name: 'loan-edit-transaction',
    params: {
      loanId: investment.value?.loanHoldingId,
      transactionId: transaction.id
    }
  })
}
</script>

<template>
  <DisplayCard :title="t('investment_page.loan.title')" color="orange" v-if="hasLoan">
    <template v-slot:actions>
      <v-btn v-if="portfolio?.canEdit" @click="create" variant="flat" color="orange" :size="$vuetify.display.xs ? 'small' : 'default'" prepend-icon="mdi-plus-circle-outline">
        {{ t('investment_page.loan.create') }}
      </v-btn>
    </template>
    <TransactionsTable @edit="onEdit" mode="holding" :loan-id="investment?.loanHoldingId" />
  </DisplayCard>
  <v-alert type="warning" color="orange-lighten-3" :title="$t('investment_page.loan.create_title')" v-else-if="portfolio?.canEdit">
    <div class="d-flex justify-space-between align-center">
      <span>{{ $t('investment_page.loan.no_loan') }}</span>
      <v-btn @click="createLoan" variant="flat" color="orange" :size="$vuetify.display.xs ? 'small' : 'default'">
        {{ $t('investment_page.loan.create_loan') }}
      </v-btn>
    </div>
  </v-alert>
</template>
<script setup lang="ts">
import { onMounted, computed } from 'vue';

const emit = defineEmits(['update:modelVisible']);

const props = defineProps({
  modelVisible: Boolean,
  account: {
    type: Object,
    required: false
  }
});

const visible = computed({
  get: () => props.modelVisible,
  set: (value) => {
    emit('update:modelVisible', value)
  }
});

onMounted(() => {
  const cookieScript = document.createElement('script');
  cookieScript.setAttribute('src', 'https://consent.cookiebot.com/22fc9dbd-a4c3-43c0-a93b-eaf78aceac10/cd.js');
  document.getElementById('cookie-consent')?.appendChild(cookieScript);
});
</script>

<template>
  <Dialog v-model:model-visible="visible" :title="'Halda küpsiste seadeid'" :maxWidth="1000">
    <div class="text-body-1" id="cookie-consent"></div>
  </Dialog>
</template>

<style lang="scss" scoped>
#cookie-consent {
  line-height: 1.2em;

  :deep(a) {
    color: #3699ff !important;
  }

  :deep(.CookieDeclarationType) {
    border-color: #738FA7;
  }

  :deep(th),
  :deep(td) {
    border-color: #738FA7;
  }
}
</style>

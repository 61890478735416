<script setup lang="ts">
import { computed, type PropType } from 'vue';
import type { Transaction } from './types';
import { createTitle } from './utils';
import { useI18n } from 'vue-i18n';
import Avatar from '@/components/Avatar.vue';
import { InstrumentType } from '~/enums';
import { usePortfolioStore } from '@/stores/portfolioStore';

const emit = defineEmits(['update:modelValue']);
const { t, te } = useI18n();

const props = defineProps({
  modelValue: {
    type: Object as PropType<Transaction>,
    required: true
  },
  editable: {
    type: Boolean,
    default: true
  }
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const portfolioStore = usePortfolioStore();

const titleText = computed(() => {
  return createTitle(value.value);
});

const onClick = (e: Event) => {
  e.stopPropagation();
}

const getBroadKey = (key: string) => {
  const type = value?.value.instrument?.type
  return `instrumentType.${type}.${key}`
}

const getTitle = (type: string | undefined) => {
  const broadKey = getBroadKey(`transactionType.${type}.title`)
  return te(broadKey) ? t(broadKey) : t(`transactionType.${type}.title`)
}

const logo = computed(() => {
  if (!value.value.instrument?.id) {
    return null
  }
  if (value.value.instrument?.type === InstrumentType.Cash) {
    const account = (portfolioStore.portfolioAccounts.item || []).find(account => account.id === value.value.instrument?.id)
    return {
      logo: account?.logo,
      code: account?.name
    }
  }
  return {
    logo: value.value.instrument?.logo,
    code: value.value.instrument?.symbol
  }
})
</script>


<template>
  <v-expansion-panel-title class="px-2 py-2">
    <div class="d-flex flex-column flex-md-row justify-start align-start align-md-center ga-2">
      <div class="d-flex align-center justify-start ga-2">
        <v-checkbox
          v-if="editable"
          v-model="value.control.isSelected"
          hide-details
          @click="onClick"
          density="compact"
          style="min-width:20px;"
        />
        <div class="date">{{ value.date }}</div>
        <div class="type">
          <template v-if="value.control.isTransactionTypeValid">
            {{ getTitle(value.transactionType) }}
          </template>
          <template v-else>
            <span class="text-red">{{ value.transactionType }}</span>
          </template>
        </div>
      </div>
      <div class="d-flex align-center justify-start ga-1">
        <Avatar v-if="logo && (logo.code || logo.logo)" :logo="logo.logo" :code="logo.code" />
        <div class="text" v-html="titleText" />
      </div>
      <div v-if="value.error" class="text-error">{{ value.error }}</div>
      <HoverHelpIcon
        v-if="value.usedPrediction"
        icon="mdi-information-box"
        color="teal"
        size="24"
        :helpText="$t('import_page.used_prediction')"
      />
    </div>
  </v-expansion-panel-title>
</template>

<style lang="scss">
.date {
  white-space: nowrap;
  font-weight: 300;
}
.type {
  white-space: nowrap;
  font-weight: 500;
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #232323;
  letter-spacing: -1px;
}
.symbol {
  font-weight: 900;
}
.text {
  font-weight: 400;
}
.value {
  font-weight: 500;
}
</style>

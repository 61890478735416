<template>
  <Dialog title="Pangakontode lisamise kinnitus" v-model:model-visible="visible">
    <div class="text-body-1 d-flex flex-column ga-4">
      <p>
        <strong>Pane tähele!</strong> Iga kord kui sa kasutad "ühenda pangaga" funktsionaalsust, suurendab see kasutusel olevate ühenduste arvu.
      </p>
      <p>
        Näiteks kui sa ühendad samas pangas eraisiku pangakontod ja ettevõtte pangakontod, siis läheb kasutusse kaks ühendust.
        Kui sul on samas pangas ühel isikul mitu kontot, siis saad need ühendada ühe ühendusega.
      </p>
      <p>
        Sa saad igal ajal pangagaühenduse eemaldada, aga see vabastab kasutusel oleva ühenduse alles järgmise kuu alguses.
      </p>
      <v-alert color="warning" type="warning" dense class="text-body-1">
        <strong>Väga oluline!</strong> Liidestuse loomisel vali korraga kõik pakutavad pangakontod, mida soovid Portfellow'ga liidestada. Nii säästad ühenduste arvu ja väldid 
        juba ühendatud kontode ühenduse katkemist.
      </v-alert>
    </div>
    <v-divider class="my-4"></v-divider>
    <div class="d-flex ga-2">
      <v-btn color="primary" @click="$emit('confirm')" :loading="isLoading">
        {{ $t('account_page.confirm') }}
      </v-btn>
      <v-btn variant="tonal" color="error" @click="$emit('close')">
        {{ $t('account_page.cancel') }}
      </v-btn>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  modelVisible: {
    type: Boolean,
    required: true
  },
  isLoading: {
    type: Boolean,
    required: false
  }
});

const emit = defineEmits(['close', 'update:modelVisible', 'confirm']);

const visible = computed({
  get: () => props.modelVisible,
  set: (value) => {
    emit('update:modelVisible', value)
  }
});
</script>
<script setup lang="ts">
import { fieldDesign } from '@/const';
import CashAccountTransactionForm from '@/views/account/TransactionForm.vue';
import InvestmentTransactionForm from '@/views/investment/TransactionForm.vue';
import { nextTick, onMounted } from 'vue';
import { computed, ref } from 'vue';

const props = defineProps(['account', 'cashAccount', 'modelValue', 'editable', 'isValid']);
const emit = defineEmits(['update:modelValue', 'update:isValid']);

const formRef = ref(null);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
})

const isValid = computed({
  get() {
    return props.isValid;
  },
  set(value) {
    emit('update:isValid', value);
  }
})

const validate = () => {
  nextTick(() => {
    // @ts-ignore
    return formRef.value?.validate();
  });
};

onMounted(() => {
  console.log('mounted validate');
  validate();
});

defineExpose({
  validate
})
</script>

<template>
  <CashAccountTransactionForm
    v-if="account.type === 'cash'"
    action="import"
    :account="account"
    v-model="value"
    v-model:is-valid="isValid"
    ref="formRef"
  />
  <InvestmentTransactionForm
    v-else-if="account.id"
    action="import"
    :investment="account"
    v-model="value"
    v-model:is-valid="isValid"
    ref="formRef"
  />
  <v-row v-if="value.counterpartyName || value.counterpartyAccount" dense>
    <v-col cols="12" lg="6" v-if="value.counterpartyName">
      <v-text-field
        v-bind="(fieldDesign as any)"
        type="text"
        disabled
        v-model="value.counterpartyName"
        :label="$t('label.counterpartyName')"
      />
    </v-col>
    <v-col cols="12" lg="6" v-if="value.counterpartyAccount">
      <v-text-field
        v-bind="(fieldDesign as any)"
        type="text"
        disabled
        v-model="value.counterpartyAccount"
        :label="$t('label.counterpartyAccount')"
      />
    </v-col>
  </v-row>
</template>
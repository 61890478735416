<template>
  <p><strong>LHVst</strong> tuleb importida tehtud tehingud ja konto väljavõte eraldi.
    Soovitame kõigepealt importida tehingud ja seejärel konto väljavõtte ning väljavõttest ostu ja müügi tehingud kustutada.
  </p>
  <v-tabs class="mt-2" v-model="tab">
    <v-tab value="1">Tehingud</v-tab>
    <v-tab value="2">Kontoväljavõte</v-tab>
  </v-tabs>
  <v-window v-model="tab">
    <v-window-item value="1">
      <ol class="ml-7 mt-5 text-body-1">
        <li>Logi sisse oma <a href="https://www.lhv.ee" target="_blank">LHV</a> kontole</li>
        <li>Vali menüüst "Tehingute väljavõte"</li>
        <li>Vali konto, periood ning kas "väärtpaberid" või "krüptovarad"</li>
        <li>Vajuta "Saada päring"</li>
        <li>Vajuta lehe üla osas "CSV" ikoonile</li>
        <li>Lae loodud fail Portfellowsse üles</li>
      </ol>
    </v-window-item>
    <v-window-item value="2">
      <ol class="ml-7 mt-5 text-body-1">
        <li>Logi sisse oma <a href="https://www.lhv.ee" target="_blank">LHV</a> kontole</li>
        <li>Vali menüüst "Konto väljavõte"</li>
        <li>Vali konto ja periood</li>
        <li>Vajuta üleval asuvat "Salvesta" nuppu ning vali "CSV"</li>
        <li>Lae loodud fail Portfellowsse üles</li>
      </ol>
    </v-window-item>
  </v-window>
  <p class="mt-5">
    <a class="text-blue" href="#">Loe juhendit</a>
  </p>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const tab = ref(0)
</script>
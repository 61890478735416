import Cleave from 'cleave.js';

export default {
  beforeMount(el, binding) {
    el.cleave = new Cleave(el.querySelector("input"), binding.value || {})
  },
  updated(el, binding) {
    const { value } = binding;
    if (value === el.cleave.properties) {
      return;
    }
    el.cleave.destroy();
    el.cleave = new Cleave(el.querySelector("input"), binding.value || {});
  },
  unmounted(el) {
    el.cleave.destroy();
  },
};

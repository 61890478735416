<template>
  <div class="d-flex align-center ga-3">
    <Logo avatar :broker="account.providerId" :name="account.name" />
    <router-link 
      :to="to" 
      class="font-weight-bold" 
      :class="{
        'text-secondary': !account.isArchived,
        'text-decoration-line-through': account.isArchived,
        'text-disabled': account.isArchived
      }">{{ account.name }}</router-link>
  </div>
</template>

<script setup lang="ts">
import Logo from '@/components/logos/Logo.vue';
import { computed } from 'vue';

const props = defineProps({
  account: {
    type: Object,
    required: true
  }
})

const to = computed(() => {
  return `/${props.account.type === 'loan' ? 'loan' : 'account'}/${props.account.id}`;
})
</script>
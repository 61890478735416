<script setup lang="ts">
import DisplayCard from '@/components/shared/DisplayCard.vue';
import Filter from '@/components/table/Filter.vue';
import { fieldDesign } from '@/const';
import { useInstrumentStore } from '@/stores/instrumentStore'
import { usePortfolioStore } from '@/stores/portfolioStore';
import { ref, computed } from 'vue';
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import type { InstrumentDtoExtendedType } from '~/instrument.schema';
import Investment from '@/components/Investment.vue';
import isNil from 'lodash/isNil';
import HeaderCell from '@/components/table/HeaderCell.vue';
import { useFilters } from '@/filter.helper';
import { useAuthStore } from '@/stores/authStore';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { InstrumentType } from '~/enums';

const instrumentStore = useInstrumentStore()
const portfolioStore = usePortfolioStore()
const authStore = useAuthStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const { smAndDown } = useDisplay()

const isLoading = ref(true)
const activeFilters = ref({
  portfolios: [],
  currencyCode: [],
  isArchived: false,
  isPublic: null,
  displayNameSearch: '',
})

const sorting = ref({
  key: authStore.getUserPreference('instrumentSortKey', 'displayName'),
  direction: authStore.getUserPreference('instrumentSortDir', 'asc')
})

const mode = computed(() => {
  return route.name === 'portfolio-instruments' ? 'portfolio' : 'all';
})

const sort = (key: string) => {
  sorting.value.direction = sorting.value.key === key && sorting.value.direction === 'desc' ? 'asc' : 'desc';
  sorting.value.key = key;
  updateRoute()
  authStore.setUserPreference('instrumentSortKey', sorting.value.key)
  authStore.setUserPreference('instrumentSortDir', sorting.value.direction)
}

const { sync, updateRoute } = useFilters([{
  key: 'sortBy',
  ref: computed({
    get: () => sorting.value.key,
    set: (value) => sorting.value.key = value,
  }),
  type: 'string',
}, {
  key: 'sortDir',
  ref: computed({
    get: () => sorting.value.direction,
    set: (value) => sorting.value.direction = value,
  }),
  type: 'string',
}, {
  key: 'portfolios',
  ref: computed({
    get: () => activeFilters.value.portfolios,
    set: (value) => activeFilters.value.portfolios = value
  }),
  type: 'int[]'
}, {
  key: 'currencyCode',
  ref: computed({
    get: () => activeFilters.value.currencyCode,
    set: (value) => activeFilters.value.currencyCode = value
  }),
  type: 'string[]'
}, {
  key: 'isArchived',
  ref: computed({
    get: () => activeFilters.value.isArchived,
    set: (value) => activeFilters.value.isArchived = value
  }),
  type: 'boolean',
}, {
  key: 'isPublic',
  ref: computed({
    get: () => activeFilters.value.isPublic,
    set: (value) => activeFilters.value.isPublic = value
  }),
  type: 'boolean',
}, {
  key: 'displayNameSearch',
  ref: computed({
    get: () => activeFilters.value.displayNameSearch,
    set: (value) => activeFilters.value.displayNameSearch = value
  }),
  type: 'string',
}], undefined, true)

const instruments = ref<InstrumentDtoExtendedType[]>([])
const filteredInstruments = computed(() => {
  return instruments.value
    .filter((instrument) => {
      return Object.keys(activeFilters.value).every((key) => {
        if (activeFilters.value[key] === null || Array.isArray(activeFilters.value[key]) && activeFilters.value[key].length === 0){
          return true
        }
        if (key.includes('Search')) {
          return instrument.displayName.toLowerCase()
            .includes(activeFilters.value[key].toLowerCase()) || instrument.name.toLowerCase().includes(activeFilters.value[key].toLowerCase())
        }
        if (Array.isArray(activeFilters.value[key])) {
          if (key === 'portfolios') {
            return activeFilters.value[key].some((portfolioId) => instrument[key].map((portfolio) => portfolio.id).includes(portfolioId))
          }
          return activeFilters.value[key].includes(instrument[key])
        }
        return instrument[key] === activeFilters.value[key]
      })
    })
    .sort((a, b) => {
      if (sorting.value.key === 'value') {
        return 0;
      }
      if (sorting.value.direction === 'asc') {
        return a[sorting.value.key] > b[sorting.value.key] ? 1 : -1
      }
      return a[sorting.value.key] < b[sorting.value.key] ? 1 : -1
    })
})

const uniqueCurrencyCodes = computed(() => {
  return Array.from(new Set(instruments.value.map((instrument) => instrument.currencyCode)))
})

const portfoliosList = computed(() => {
  if (mode.value === 'all') {
    return portfolioStore.portfolios.value
      .filter((portfolio) => !portfolio.isConsolidated)
      .map((portfolio) => ({
        title: portfolio.name,
        value: portfolio.id,
      }))
  } else {
    return portfolioStore.portfolios.value
      .filter((portfolio) => (!portfolioStore.selectedPortfolio?.isConsolidated && portfolio.id === portfolioStore.selectedPortfolioId) || portfolioStore.selectedPortfolio?.consolidatedPortfolioIds?.includes(portfolio.id))
      .map((portfolio) => ({
        title: portfolio.name,
        value: portfolio.id,
      }))
  }
})

const title = computed(() => {
  if (smAndDown.value && mode.value !== 'all') {
    return t('instrument_page.instruments')
  }
  return  mode.value === 'all' ? t('instrument_page.cutom_instruments_title') : t('instrument_page.portfolio_instruments_title')
})

const getRoute = (instrument: InstrumentDtoExtendedType) => {
  const investments = instrument.investments;

  if (instrument.type === InstrumentType.TakenLoan) {
    return {
      name: 'loan',
      params: { loanId: investments[0].id }
    }
  }

  if (investments.length === 1) {
    return {
      name: 'investment',
      params: { investmentId: investments[0].id }
    }
  }

  return {
    name: 'instrument',
    params: { instrumentId: instrument.id }
  }
}

const createInvestment = () => {
  router.push({name: 'add-investment', params: { portfolioId: portfolioStore.selectedPortfolioId }})
}

const createLoan = () => {
  router.push({name: 'add-loan-account'})
}

const hasHiddenAccounts = computed(() => {
  return instruments.value.some((holding) => holding.isArchived);
});

watch(mode, async () => {
  try {
    sync();
    isLoading.value = true;
    if (mode.value === 'portfolio') {
      instruments.value = await instrumentStore.getAllByPortfolioId(portfolioStore.selectedPortfolioId);
    } else {
      instruments.value = await instrumentStore.getAll();
    }
  } catch (error) {
    //
  } finally {
    isLoading.value = false
  }
}, { immediate: true })
</script>

<template>
  <v-row>
    <v-col cols="12">
      <DisplayCard
        icon="mdi-clipboard-text-outline"
        color="panel-heading-bg"
        :title="title"
      >
        <template v-slot:actions  v-if="!portfolioStore.selectedPortfolio?.isConsolidated && portfolioStore.selectedPortfolio?.canEdit">
          <DropdownMenu
            :activator-size="$vuetify.display.xs ? 'small' : 'default'"
            :label="$t('portfolio.add_new')"
            density="default"
            color="blue"
            :items="[{
              value: 'investment',
              onClick: () => createInvestment(),
              title: $t('label.investment'),
              icon: 'mdi-clipboard-text-outline'
            }, {
              value: 'loan',
              onClick: () => createLoan(),
              title: $t('label.taken_loan'),
              icon: 'mdi-cash'
            }]"
          />
        </template>
        <v-table class="radius-0" style="border-radius:0;">
          <thead>
            <tr class="bg-table-heading-bg">
              <th>
                <HeaderCell
                  :title="$t('label.instrument')"
                  sortingKey="displayName"
                  :sorting="sorting"
                  justify="start"
                  default-color="primary"
                  @sort="sort"
                >
                  <v-text-field
                    style="max-width:300px;"
                    v-model="activeFilters.displayNameSearch"
                    :label="$t('label.search')"
                    prepend-inner-icon="mdi-magnify"
                    v-bind="(fieldDesign as any)"
                    density="compact"
                    hide-details
                  />
                </HeaderCell>
              </th>
              <th>
                <div class="d-flex ga-1 align-center">
                  {{ $t('label.portfolio') }}
                  <Filter :is-active="!isNil(activeFilters.portfolios) && !!activeFilters.portfolios.length">
                    <v-checkbox
                      v-for="portfolio in portfoliosList"
                      :false-value="null"
                      hide-details
                      v-model="activeFilters.portfolios"
                      :label="portfolio.title"
                      :value="portfolio.value"
                    />
                  </Filter>
                </div>
              </th>
              <th class="text-no-wrap">
                <HeaderCell
                  :title="$t('label.instrument_type')"
                  sortingKey="type"
                  :sorting="sorting"
                  justify="start"
                  default-color="primary"
                  @sort="sort"
                />
              </th>
              <th>
                <div class="d-flex ga-1 align-center justify-center">
                  {{ $t('label.currency') }}
                  <Filter :is-active="!isNil(activeFilters.currencyCode) && !!activeFilters.currencyCode.length">
                    <v-select
                      v-bind="fieldDesign"
                      label="Valuuta"
                      v-model="activeFilters.currencyCode"
                      :items="uniqueCurrencyCodes"
                      multiple
                    />
                  </Filter>
                </div>
              </th>
              <th>
                <div class="d-flex ga-1 align-center justify-center">
                  {{ $t('label.public_investment') }}
                  <Filter :is-active="!isNil(activeFilters.isPublic)">
                    <v-checkbox :false-value="null" hide-details v-model="activeFilters.isPublic" :label="$t('label.yes')" :value="true"></v-checkbox>
                    <v-checkbox :false-value="null" hide-details v-model="activeFilters.isPublic" :label="$t('label.no')" :value="false"></v-checkbox>
                  </Filter>
                </div>
              </th>
              <th>
                <div class="d-flex ga-1 align-center justify-center">
                  {{ $t('label.archived') }}
                  <Filter :is-active="!isNil(activeFilters.isArchived)">
                    <v-checkbox :false-value="null" hide-details v-model="activeFilters.isArchived" :label="$t('label.yes')" :value="true"></v-checkbox>
                    <v-checkbox :false-value="null" hide-details v-model="activeFilters.isArchived" :label="$t('label.no')" :value="false"></v-checkbox>
                  </Filter>
                </div>
              </th>
            </tr>
          </thead>
          <tbody v-if="isLoading">
            <tr>
              <td :colspan="5" class="text-center">
                <v-progress-circular indeterminate color="primary" />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="item in filteredInstruments" :key="item.id">
              <td>
                <Investment
                  :code="item.displayName"
                  :logo="item.logo"
                  :route="getRoute(item)"
                  :name="item.name"
                  :isCash="false"
                  :isLoan="item.type === InstrumentType.TakenLoan"
                  :showPortfolios="false"
                  :size="32"
                  :holding="item"
                />
              </td>
              <td>
                <v-chip color="secondary" size="x-small" v-if="item.portfolios.length > 1" class="d-flex">
                  {{ $t('label.n_portfolios', item.portfolios.length, { count: item.portfolios.length })}}
                </v-chip>
                <span v-else>
                  {{ item.portfolios[0]?.name }}
                </span>
              </td>
              <td>
                {{ $t(`instrumentType.${item.type}.title`) }}
              </td>
              <td class="text-center">
                {{ item.currencyCode }}
              </td>
              <td class="text-center">
                {{ item.isPublic ? $t('label.yes') : $t('label.no') }}
              </td>
              <td class="text-center">
                {{ item.isArchived ? $t('label.yes') : $t('label.no') }}
              </td>
            </tr>
            <tr v-if="hasHiddenAccounts">
              <td colspan="5">
                <v-btn variant="plain" color="primary" @click="activeFilters.isArchived = !activeFilters.isArchived">{{ activeFilters.isArchived ? $t('instrument_page.show_all') : $t('instrument_page.show_hidden') }}</v-btn>
              </td>
            </tr>
            <tr v-if="filteredInstruments.length === 0">
              <td :colspan="5">
                <template v-if="instruments.length === 0">
                  {{ $t('instrument_page.no_instruments') }}
                </template>
                <template v-else>
                  {{ $t('instrument_page.no_instruments_filtered') }}
                </template>
              </td>
            </tr>
          </tbody>
        </v-table>
      </DisplayCard>
    </v-col>
  </v-row>
</template>


<style scoped lang="scss">
.filter {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

:deep(tbody tr:nth-of-type(even)) {
  background-color: rgba(0, 0, 0, .03);
}
</style>
<script setup lang="ts">
import { computed } from 'vue';
import LogoDark from './LogoDark.vue';
import { useCustomizerStore } from '@/stores/customizer';

const customizer = useCustomizerStore();

const dark = computed(() => {
  if (
    customizer.actTheme === 'DarkPurpleTheme' ||
    customizer.actTheme === 'DarkGreenTheme' ||
    customizer.actTheme === 'DarkSpeechBlueTheme' ||
    customizer.actTheme === 'DarkOliveGreenTheme' ||
    customizer.actTheme === 'DarkPinkTheme' ||
    customizer.actTheme === 'DarkYellowTheme' ||
    customizer.actTheme === 'DarkSeaGreenTheme'
  ) {
    return true;
  } else {
    return false;
  }
});
</script>
<template>
  <LogoDark />
</template>

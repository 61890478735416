<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

type slideType = {
  title: string;
  description: string;
};

const { t } = useI18n();

const slides = ref<slideType[]>([
  {
    title: t('register_page.slider.slide_1.title'),
    description: t('register_page.slider.slide_1.description'),
  },
  {
    title: t('register_page.slider.slide_2.title'),
    description: t('register_page.slider.slide_2.description'),
  },
  {
    title: t('register_page.slider.slide_3.title'),
    description: t('register_page.slider.slide_3.description'),
  }
]);
</script>
<template>
  <v-carousel color="primary" cycle height="250" hide-delimiter-background class="custom-delimiter" :show-arrows="false">
    <v-carousel-item v-for="(slide, i) in slides" :key="i">
      <div class="text-center">
        <h1 class="text-h1 mb-2" style="line-height:110%;">{{ slide.title }}</h1>
        <p class="font-weight-light mb-2">{{ slide.description }}</p>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<style lang="scss">
.custom-delimiter {
  .v-btn {
    font-size: 5px;
  }
}
</style>

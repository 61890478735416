import { InstrumentType, TransactionType } from "../enums";
import { InstrumentConfig } from "./Instrument.config";

class RealEstateConfig extends InstrumentConfig {
  type: InstrumentType = InstrumentType.RealEstate;

  allowedTransactionTypes = [
    TransactionType.Opening,
    TransactionType.Buy,
    TransactionType.Sell,
    TransactionType.InterestPaid,
    TransactionType.LoanPrincipalPaid,
    TransactionType.Expenses
  ]

  allowedIncomeTypes = [
    TransactionType.RentalIncome,
    TransactionType.OtherIncome,
  ]

  hasPrice: boolean = true
  allowRelatedLoan: boolean = true
  showQuantityInValue: boolean = false
  showQuantity: boolean = false;
  icon: string = '🏠';

  showQuantityField (transactionType: TransactionType | undefined) {
    if (transactionType === undefined) return false;
    return ![TransactionType.Buy, TransactionType.Sell, TransactionType.Opening].includes(transactionType);
  }

  getDefaultQuantity (transactionType: TransactionType | undefined) {
    if ([TransactionType.Buy, TransactionType.Sell, TransactionType.Opening].includes(transactionType)) {
      return 1;
    }
    return super.getDefaultQuantity(transactionType);
  }

  showPriceField (_transactionType: TransactionType | undefined) {
    if ([TransactionType.InterestPaid, TransactionType.LoanPrincipalPaid].includes(_transactionType)) {
      return false;
    }
    return super.showPriceField(_transactionType);
  }
}

export default new RealEstateConfig();

import { computed, type Ref } from 'vue';
import { instrumentConfig } from '~/config';
import { HoldingType, type InstrumentType, type TransactionType } from '~/enums';
import { useI18n } from "vue-i18n";
import { useRouter } from 'vue-router';

export function useInstrumentConfig(holding: Ref<any>) {
  const config = computed(() => {

    return instrumentConfig[(holding.value?.instrument?.type || holding.value?.type) as InstrumentType];
  });

  return {
    iConf: config
  };
}

export function useTranslationHelper (investment: Ref<any>, transactionType: Ref<TransactionType>) {
  const { t, te } = useI18n();

  const getBroadKey = (key: string) => {
    const type = investment.value?.instrument?.type || investment.value?.type;
    return `instrumentType.${type}.${key}`
  }

  const tBroad = (key: string) => {
    const specificKey = getBroadKey(`${transactionType.value}.${key}`);
    const broadKey = getBroadKey(key);
    const transactionKey = `transactionType.${transactionType.value}.${key}`;

    if (te(specificKey)) {
      return t(specificKey);
    } else if (te(broadKey)) {
      return t(broadKey);
    } else if (te(transactionKey)) {
      return t(transactionKey);
    } else if (te(key)) {
      return t(key);
    } else {
      return undefined;
    }
  }

  return {
    tBroad,
    getBroadKey
  }
}

export function useTransactionHelper () {
  const router = useRouter();

  const onEditTransactionClick = (transaction: any) => {
    let name = 'account-edit-transaction';
    let params: any = { accountId: transaction.accountId, transactionId: transaction.id };
  
    if (transaction.holding?.type === HoldingType.Investment) {
      if (transaction.isIncome) {
        name = 'investment-edit-income';
        params = { investmentId: transaction.holding.id, incomeId: transaction.id };
      } else {
        name = 'investment-edit-transaction';
        params = { investmentId: transaction.holding.id, transactionId: transaction.id };
      }
    } else if (transaction.holding?.type === HoldingType.Loan) {
      name = 'loan-edit-transaction';
    }

    router.push({
      name,
      params,
    })
  }

  return {
    onEditTransactionClick
  }
}
<script setup lang="ts">
import { computed } from 'vue';
import { countFormatter } from '@/const';
import { TransactionType } from '~/enums';

const props = defineProps({
  value: null,
  transactionType: String
});

const formattedValue = computed(() => {
  return countFormatter(props.value);
});

const showQuantity = computed(() => {
  return !props.transactionType || [TransactionType.Buy, TransactionType.Sell, TransactionType.Opening, TransactionType.BonusShares, TransactionType.Opening, TransactionType.Split].includes(props.transactionType);
});
</script>

<template>
  <span v-if="showQuantity" v-bind="{}" class="balance">{{ formattedValue }}</span>
  <span v-else class="text-disabled">-</span>
</template>


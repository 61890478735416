import { InstrumentType, TransactionType } from "../enums";
import { InstrumentConfig } from "./Instrument.config";

class BondConfig extends InstrumentConfig {
  type: InstrumentType = InstrumentType.Bond;

  canBeMigratedToPublic: boolean = true;
  icon: string = '📄';

  allowedTransactionTypes = [
    TransactionType.Buy,
    TransactionType.Sell,
    TransactionType.Opening,
    TransactionType.Fee,
  ]
  allowedIncomeTypes = [
    TransactionType.Interest,
  ]
}

export default new BondConfig();

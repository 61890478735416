<script setup lang="ts">
import { useInvestmentStore } from '@/stores/investmentStore';
import { computed, ref, watch, defineAsyncComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import { HoldingType } from '~/enums';
import { useAccountStore } from '@/stores/accountStore';
import { useLoanStore } from '@/stores/loanAccountStore';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { useTheme } from 'vuetify';
import ConfirmLeavePage from '@/components/ConfirmLeavePage.vue';

const MarkdownEditor = defineAsyncComponent(() =>
  import('../../components/inputs/MarkdownEditor.vue')
)

const investmentStore = useInvestmentStore();
const accountStore = useAccountStore();
const loanStore = useLoanStore();
const portfolioStore = usePortfolioStore();
const theme = useTheme();

const { t } = useI18n();
const portfolio = computed(() => portfolioStore.activePortfolio);
const isEdit = ref(false);
const isSaveLoading = ref(false);

const props = defineProps<{
  holding?: any | undefined,
  title?: string | undefined,
  note?: {
    portfolioId: number
    holdingId: number | undefined
    content: string
  },
  color?: string | undefined
  icon?: string | undefined
}>()

const form = ref('')

const noteContent = computed(() => {
  return props.note?.content || props.holding?.note?.content || props.holding?.notes || form.value;
});

watch(noteContent, (note) => {
  form.value = noteContent.value;
}, { immediate: true });

const isDarkTheme = computed(() => {
  return theme.current.value.dark;
})

const onEdit = () => {
  isEdit.value = !isEdit.value;
  if (!isEdit.value) {
    onSave();
  }
}

const saveNote = async () => {
  try {
    await fetchWrapper.put(`/api/v1/portfolio/${props.note.portfolioId}/notes`, {
      portfolioId: props.note.portfolioId,
      holdingId: props.note.holdingId,
      content: form.value
    });
  } catch {
    //
  }
}

const onSave = async () => {
  try {
    isSaveLoading.value = true;
    if (props.note) {
      await saveNote()
    } else if (props.holding.type === HoldingType.Investment) {
      await investmentStore.updateInvestment(props.holding.id, {
        note: {
          content: form.value,
          portfolioId: props.holding.portfolioId
        }
      })
    } else if (props.holding.type === HoldingType.Cash) {
      await accountStore.updateCashAccount(props.holding.id, {
        note: {
          content: form.value,
          portfolioId: props.holding.portfolioId
        }
      })
    } else if (props.holding.type === HoldingType.Loan) {
      await loanStore.updateLoanAccount(props.holding.id, {
        note: {
          content: form.value,
          portfolioId: props.holding.portfolioId
        }
      })
    }

    if (props.note) {
      props.note.content = form.value
    } else if (props.holding) {
      props.holding.notes = form.value
    }
  } catch (err) {
    console.log(err)
    //
  } finally {
    isSaveLoading.value = false;
  }
}
</script>

<template>
  <DisplayCard :icon="icon" :title="title || t('investment_page.notes.title')" :color="color ?? 'primary-lighten-4'">
    <template v-slot:actions>
      <v-btn
        v-if="portfolio?.canEdit"
        @click="onEdit"
        variant="flat"
        :color="!isEdit ? 'primary-lighten-3' : 'success'"
        :size="$vuetify.display.xs ? 'small' : 'default'"
        :loading="isSaveLoading"
        prepend-icon="mdi-pencil">
        {{ isEdit ? t('investment_page.notes.save') : t('investment_page.notes.edit')}}
      </v-btn>
    </template>
    <div class="pa-4">
      <div class="text-body-1" v-if="!noteContent && !isEdit">
        {{ t('investment_page.notes.no-notes') }}
      </div>
      <MarkdownEditor :key="isDarkTheme" v-model="form" :editMode="isEdit" />
    </div>
  </DisplayCard>
  <ConfirmLeavePage
    :can-leave="true"
  />
</template>

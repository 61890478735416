<script lang="ts" setup>
import { accountProviders } from '@/const';
import Logo from '@/components/logos/Logo.vue';
import { defineEmits, computed } from 'vue';

const emit = defineEmits([
  'update:modelValue'
]);

const props = defineProps({
  modelValue: null,
  label: {
    type: String,
  },
  fieldDesign: {
    type: Object,
    default: () => ({})
  }
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const getProvider = (id: any) => {
  return accountProviders.value.find((provider: any) => provider.id === id) || accountProviders.value[0];
}
</script>

<template>
  <v-autocomplete
    v-bind="(fieldDesign as any)"
    v-model="value"
    :items="accountProviders"
    label="Teenusepakkuja"
    item-title="title"
    item-value="id"
    return-object
  >
    <template v-slot:prepend-inner>
      <Logo
        avatar
        :broker="value?.id || value"
        :name="getProvider(value?.id || value)?.shortName ?? getProvider(value?.id || value)?.title"
        :size="fieldDesign.density === 'compact' ? 24 : 32"
      />
    </template>
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="''">
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
        <template v-slot:prepend>
          <Logo
            avatar
            :broker="item.raw.id ?? ''"
            :name="item.raw.shortName ?? item.raw.title"
          />
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

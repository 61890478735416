<template>
  <v-btn size="small" :icon="icon" variant="plain" @click="toggleHideBalances" />
</template>

<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { computed } from 'vue';

const appStore = useAppStore();
const icon = computed(() => !appStore.hideBalances ? 'mdi-eye-off-outline' : 'mdi-eye-outline');

const toggleHideBalances = () => {
  appStore.hideBalances = !appStore.hideBalances;
}
</script>
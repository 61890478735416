<template>
  <p><strong>Lightyearist</strong> on võimalik importida ühe failiga nii tehingud kui rahavoo ülekanded.</p>
  
  <ol class="ml-7 mt-5 text-body-1">
    <li>Logi sisse oma <a href="https://lightyear.com/login" target="_blank">Lightyear</a> kontole</li>
    <li>Vali menüüst "Activity"</li>
    <li>Vajuta "Download statement" nuppu</li>
    <li>Vali "Account statement"</li>
    <li>Vali soovitud periood</li>
    <li>Vajuta "Export CSV"</li>
    <li>Lae loodud fail Portfellowsse üles</li>
  </ol>

  <p class="mt-5">
    <a class="text-blue" href="#">Loe juhendit</a>
  </p>
</template>

<script setup lang="ts">

</script>
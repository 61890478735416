<template>
  <div class="text-center d-flex flex-column justify-center align-center" style="min-height:60vh;" id="registered-onboarding">
    <h2 class="font-weight-black text-darkprimary" id="welcome">
      ✅ {{ $t('onboarding.title') }}
    </h2>
    <p class="">
      ℹ️  {{ $t('onboarding.info_1') }}
      <a class="text-blue" href="https://portfellow.com/help/kuidas-alustada-portfellow-kasutamisega/" target="_blank">
        {{ $t('onboarding.info_2') }}
      </a>.</p>
    <div style="width:100%;">
      <v-row class="mt-5 text-left">
        <v-col cols="12" sm="6" md="4" class="d-flex">
          <DisplayCard 
            variant="outlined"
            elevation="0"
            :title="$t('onboarding.add_opening_balances')"
            icon="mdi-format-list-numbered"
            class="d-flex flex-column"
            color="primary">
            <div class="d-flex flex-column flex-fill">
              <v-card-text class="text-medium-emphasis pb-4">
                {{ $t('onboarding.add_opening_balances_info') }}
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-btn :active="false" variant="tonal" color="blue" :to="{name: 'open-balance', params: {portfolioId }}" size="large">
                  {{ $t('onboarding.add_opening_balances') }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </div>
          </DisplayCard>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="d-flex">
          <DisplayCard variant="outlined" elevation="0" :title="$t('onboarding.add_manually')" icon="mdi-pencil-circle-outline" class="d-flex flex-column" color="primary">
            <div class="d-flex flex-column flex-fill">
              <v-card-text class="text-medium-emphasis pb-4">
                {{ $t('onboarding.add_manually_info') }}
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-btn :active="false" variant="tonal" color="blue" :to="{name: 'portfolio', params: {portfolioId }}" size="large">
                  {{ $t('onboarding.add_manually') }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </div>
          </DisplayCard>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="d-flex">
          <DisplayCard variant="outlined" elevation="0" :title="$t('onboarding.import_files')" icon="mdi-file-upload" class="d-flex flex-column" color="primary">
            <div class="d-flex flex-column flex-fill">
              <v-card-text class="text-medium-emphasis pb-4">
                {{ $t('onboarding.import_files_info') }}
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-btn :active="false" variant="tonal" color="blue" :to="{name: 'import', params: {portfolioId }}" size="large">
                  {{ $t('onboarding.import_files') }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </div>
          </DisplayCard>
        </v-col>
      </v-row>
      <p class="my-5 text-caption text-medium-emphasis">
        {{ $t('onboarding.info_3') }}
      </p>
      <h2 class="font-weight-black text-darkprimary mb-2">
        👀 {{ $t('onboarding.info_4') }}
      </h2>
      <v-row class="justify-center">
        <v-col cols="12" sm="6" md="4" class="d-flex">
          <DisplayCard variant="plain" elevation="0" title="" icon="mdi-pencil-circle-outline" class="d-flex flex-column" color="primary">
            <div class="d-flex flex-column flex-fill bg-primary-lighten-4">
              <v-card-text class="text-left">
                {{ $t('onboarding.demo_portfolio_info') }}
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-btn :disabled="isLoading" :loading="isLoading" :active="false" variant="tonal" color="primary" @click="addDemoPortfolio" size="large">
                  {{ $t('onboarding.add_demo_portfolio') }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </div>
          </DisplayCard>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePortfolioStore } from '@/stores/portfolioStore';
import { ref } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const portfolioStore = usePortfolioStore();
const router = useRouter();

const portfolioId = computed(() => portfolioStore.selectedPortfolioId);
const isLoading = ref(false);

const addDemoPortfolio = async () => {
  try {
    isLoading.value = true;
    await portfolioStore.addDemoPortfolio();
    await router.push({ name: 'dashboard' });
  } finally {
    isLoading.value = false;
  }
};
</script>
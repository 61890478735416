<script setup lang="ts">
import { computed, ref } from 'vue';
import debounce from 'lodash/debounce';
import { useInstrumentStore } from '@/stores/instrumentStore';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { i18n } from '@/plugins/i18n';
import Avatar from '../Avatar.vue';

const emit = defineEmits(['update:modelValue', 'update:modelIsLoading', 'onSelect', 'onAddCustomClick']);

const { t } = useI18n();

const props = defineProps({
  modelValue: null,
  modelIsLoading: null,
  disabled: {
    type: Boolean,
    default: false
  },
  fieldDesign: {
    type: Object,
    default: () => ({})
  },
  label: {
    type: String,
    default: () => i18n.global.t('label.search_by_instrument')
  },
  placeholder: {
    type: String,
    default: () => i18n.global.t('label.search_instrument')
  },
  initialSearchText: {
    type: String,
    default: ''
  },
  filter: {
    type: Object,
    default: () => ({})
  },
  allowAccounts: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Array,
    default: () => []
  },
  hideCreateManual: {
    type: Boolean,
    default: false
  }
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const isLoading = computed({
  get() {
    return props.modelIsLoading
  },
  set(value) {
    emit('update:modelIsLoading', value)
  }
});


const instrumentStore = useInstrumentStore();

const searchResults = ref([]);
const searchText = ref('');
const isSearchLoading = ref(false);
const instrument = ref(null);
const filterCategory = ref('all');
const menu = ref(!!props.initialSearchText);

watch(() => value.value, (newValue) => {
  instrument.value = newValue;
}, { immediate: true });

const debouncedSearch = debounce(async (value) => {
  try {
    if (value === value.value?.name) {
      return
    }
    const filter = {
      allowAccounts: props.allowAccounts,
      ...props.filter
    }
    searchResults.value = await instrumentStore.search(value, filter);
  } finally {
    isSearchLoading.value = false;
  }
}, 300);


const categories = computed(() => {
  return searchResults.value.reduce((acc, curr: any) => {
    if (!acc[curr.exchange]) {
      acc[curr.exchange] = {
        key: curr.exchange,
        name: curr.exchange === 'OTHER' ? 'Minu instrumendid' : curr.exchange,
        items: 0,
        active: filterCategory.value === curr.exchange
      }
    }
    acc[curr.exchange].items++;
    return acc;
  }, {
    'all': {
      key: 'all',
      name: t('label.all'),
      items: searchResults.value.length,
      active: filterCategory.value === 'all'
    }
  } as any)
});

const searchResultsFiltered = computed(() => {
  if (filterCategory.value === 'all') {
    return searchResults.value;
  }
  return searchResults.value.filter((item: any) => item.exchange === filterCategory.value);
});

const onSearchInput = async (_value: string) => {
  if (_value === instrument.value?.name) {
    searchText.value = searchText.value;
    return;
  }
  isSearchLoading.value = true;
  searchText.value = _value;
  await debouncedSearch(_value);
}

const onSelect = (_value: any) => {
  if (_value?.symbol) {
    value.value = _value;
    if (!_value.id && _value.uniqueId) {
      isLoading.value = true;
      instrumentStore.triggerSelect(_value.uniqueId).then((instrument) => {
        value.value.id = instrument.id;
      }).finally(() => {
        isLoading.value = false;
      });
    }
  }
}

const onFilterCategory = (category: any) => {
  filterCategory.value = category.key;
}

const showSuggestions = computed(() => {
  const suggestions = (props.filter.suggestedInstrumentIds || []).length;
  return suggestions === searchResultsFiltered.value.length && !searchText.value;
});

if (props.initialSearchText || (props.filter.suggestedInstrumentIds || []).length) {
  instrument.value = props.initialSearchText;
  onSearchInput(props.initialSearchText);
}
</script>

<template>
  <div class="d-flex justify-space-between text-body-1 mb-1" v-if="!props.hideCreateManual">
    <span>
      {{ t('label.search_by_instrument') }}
    </span>
    <span class="d-flex align-center ga-1">{{ $t('label.or') }} 
      <span style="cursor:pointer;" @click="$emit('onAddCustomClick')" class="text-primary text-decoration-underline">
        <template v-if="$vuetify.display.smAndDown">
          {{ $t('label.add_custom') }}
        </template>
        <template v-else>
          {{ $t('label.add_custom_long') }}
        </template>
      </span>
      <HoverHelpIcon :helpText="$t('investment_page.form.find_or_manual')" />
    </span>
  </div>
  <v-combobox
    v-model="instrument"
    v-bind="fieldDesign"
    :placeholder="props.placeholder"
    :label="props.hideCreateManual ? props.label : ''"
    item-title="name"
    item-value="uniqueId"
    :items="searchResultsFiltered"
    :search="searchText"
    :disabled="props.disabled"
    :rules="(props.rules as any)"
    @update:search="onSearchInput"
    @update:modelValue="onSelect"
    :hide-no-data="isSearchLoading"
    no-filter
    return-object
    :menu="!!(menu || searchResults.length) && !isSearchLoading"
  >
    <template v-slot:prepend-item>
      <template v-if="searchResults.length > 0">
        <div v-if="!showSuggestions" class="d-flex justify-start flex-wrap px-4 ga-1" style="overflow: hidden;max-width:866px;">
          <v-chip v-for="(cat, i) in categories" :key="i" variant="flat" :color="cat.active ? 'primary-lighten-1' : 'grey-lighten-2'" v-on:click="onFilterCategory(cat)">{{ cat.name }}</v-chip>
        </div>
        <div class="px-4 text-caption mt-2" v-if="!showSuggestions">
          {{ searchResultsFiltered.length === 100 ? '100+' : searchResultsFiltered.length }} {{ t('label.results') }}
        </div>
        <v-list-subheader v-if="showSuggestions">
          {{ t('label.suggestions') }}: 
        </v-list-subheader>
      </template>
    </template>
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="''" :class="{'bg-primary-lighten-4': !item.raw.isPublic }">
        <v-list-item-title>
          <strong :class="{'text-decoration-line-through': item.raw.isArchived, 'text-disabled': item.raw.isArchived}">{{ item.raw.displayName }}</strong>
        </v-list-item-title>
        <v-list-item-subtitle :class="{'text-decoration-line-through': item.raw.isArchived}">
          {{ item.raw.name }}
        </v-list-item-subtitle>
        <template v-slot:append>
         {{ item.raw.currencyCode }}
        </template>
        <template v-slot:append v-if="false">
          <v-icon color="green-darken-3">mdi-check</v-icon>
        </template>
        <template v-slot:prepend>
          <avatar :size="32" class="mr-n2" :code="item.raw.symbol" :logo="item.raw.logo" />
        </template>
      </v-list-item>
    </template>
    <template v-slot:append-inner>
      <v-progress-circular
        v-show="isSearchLoading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </template>
    <template v-slot:no-data>
      <v-list-item v-if="!isSearchLoading">
        <v-list-item-title>
          {{ t('label.no_results') }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-combobox>
</template>
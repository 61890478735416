import FullLayout from '@/layouts/full/FullLayout.vue';
import Success from '@/views/subscribe/Success.vue';
import Cancel from '@/views/subscribe/Cancel.vue';

const SettingsRoutes = {
  path: '/subscribe',
  meta: {
    requiresAuth: true
  },
  component: FullLayout,
  children: [
    {
      name: 'success',
      path: 'success',
      component: Success,
      meta: {
        title: 'Checkout successful'
      }
    },
    {
      name: 'cancel',
      path: 'cancel',
      component: Cancel,
      title: {
        title: 'Checkout cancelled'
      }
    }
  ]
};

export default SettingsRoutes;

<script setup lang="ts">
import { fieldDesign } from '@/const';
import { computed, ref, watch } from 'vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { CsvImportType } from '~/enums';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import SelectColumnsStep from './SelectColumnsStep.vue';
import type { AccountDtoType } from '~/cash-account.schema';
import AccountSearchField from '@/components/inputs/AccountSearchField.vue';
import Note from '@/components/Note.vue';
import Help from './help/Help.vue';
import { useRoute } from 'vue-router';
import { mapToIntnernal } from './utils';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['update:modelValue']);
const route = useRoute();
const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: Object,
    required: false
  }
})

const item = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const currentStep = ref();
const file = ref<any>(null);
const importType = ref<CsvImportType>(CsvImportType.Transactions);
const isLoading = ref(false);
const cashAccount = ref<AccountDtoType>();
const portfolioStore = usePortfolioStore();
const fileUploadResult = ref<any>(null);
const isColumnsValid = ref(false);
const importWithoutAccount = ref(false);

const selectFile = (e: any) => {
  file.value = e.target.files[0];
}

const submitFile = async () => {
  try {
    isLoading.value = true;
    fileUploadResult.value = await portfolioStore.uploadCsvFile(portfolioStore.selectedPortfolio.id, file.value, {
      importType: importType.value,
      cashAccountId: cashAccount.value?.id
    });
    if (fileUploadResult.value.score >= 1) {
      await submitMappings();
    }
  } finally {
    isLoading.value = false;
  }
}

const submitMappings = async () => {
  try {
    isLoading.value = true;

    const response = await portfolioStore.prepareCsvFile(portfolioStore.selectedPortfolio.id, fileUploadResult.value);
    mapToIntnernal(response);

    item.value = {
      transactions: response,
      cashAccountId: cashAccount.value?.id,
      cashAccount: cashAccount.value
    }
  } finally {
    isLoading.value = false;
  }
}

const steps = computed(() => {
  return [
     {
      id: 1,
      title: t('import_page.select_account'),
      isValid: !!cashAccount.value || importWithoutAccount.value
    },
    {
      id: 2,
      title: t('import_page.select_file'),
      isValid: !!file.value
    },
    {
      id: 3,
      title: t('import_page.select_columns'),
      isValid: isColumnsValid
    }
  ]
})

const currentStepObject = computed(() => {
  return steps.value.find((step: any) => step.id === currentStep.value);
})

const buttonProps = computed(() => {
  return {
    loading: isLoading.value,
    disabled: !currentStepObject.value?.isValid,
    color: 'primary'
  }
})

const handleButtonClick = async (props: any) => {
  if (buttonProps.value.disabled || buttonProps.value.loading) {
    return;
  }
  if (currentStep.value === 2) {
    await submitFile();
    props.onClick();
  } else if (currentStep.value === 3) {
    submitMappings();
  } else {
    props.onClick();
  }
}

watch(() => route.query.accountId, (accocuntId) => {
  if (!accocuntId) {
    return;
  }
  const account = portfolioStore.portfolioAccounts.item.find((account) => account.id.toString() === accocuntId.toString());
  if (account) {
    cashAccount.value = account;
  }
}, { immediate: true });
</script>

<template>
  <v-row>
    <v-col cols="12" md="7">
      <DisplayCard :title="$t('import_page.file_card_title')" color="panel-heading-bg">
        <v-stepper v-model="currentStep" :items="steps" elevation="0" :next-text="t('label.next')" :prev-text="t('label.back')">
          <template v-slot:item.1>
            <h3 class="mb-5">{{ $t('import_page.select_account') }}</h3>
            <div>
              <AccountSearchField
                :fieldDesign="fieldDesign"
                v-model:model-object="cashAccount"
              />
              <h3 class="mt-5">
                {{ $t('label.or') }}
              </h3>
              <v-checkbox
                v-bind="(fieldDesign as any)"
                v-model="importWithoutAccount"
                :items="Object.values(CsvImportType)"
                :label="$t('import_page.import_without_account')"
              />
            </div>
          </template>

          <template v-slot:item.2>
            <v-card :title="$t('import_page.select_file')" flat>
              <div class="px-6">
                <v-file-input
                  v-bind="(fieldDesign as any)"
                  :label="$t('import_page.file')"
                  @change="selectFile"
                  accept=".csv,.xml"
                  clearable
                  prepend-icon="mdi-file-delimited-outline"
                />
              </div>
            </v-card>
          </template>
          <template v-slot:item.3>
            <SelectColumnsStep
              v-if="!isLoading"
              v-model:is-valid="isColumnsValid"
              :file-upload-result="fileUploadResult"
            />
          </template>

          <template v-slot:actions="{ prev, next }">
            <v-divider></v-divider>
            <v-stepper-actions class="mt-6 mb-3" @click:next="next" @click:prev="prev">
              <template v-slot:next="{ props }">
                <v-btn v-bind="buttonProps" @click="handleButtonClick(props)" color="primary" />
              </template>
            </v-stepper-actions>
          </template>
        </v-stepper>
      </DisplayCard>
    </v-col>
    <v-col cols="12" md="5">
      <Note>
        <h1 class="font-weight-thin">
          {{ $t('import_page.help_title') }}
        </h1>
        <Help :provider-id="cashAccount?.providerId" />
      </Note>
    </v-col>
  </v-row>
</template>

import { defineStore } from 'pinia';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import type * as I from '~/investment.schema';
import type * as X from '~/summary.schema';
import type { CreatePriceDtoType, InvestmentPricesDtoType, PriceDtoType } from '~/price.schema';
import type { RemoteObject } from '@/const';
import { ref } from 'vue';
import type { CreateCashAccountTransactionDtoType } from '~/cash-account.schema';

type State = {
  investment: I.InvestmentDtoType | RemoteObject
  investmentSummary: X.SummaryItemDtoType | RemoteObject
  transaction: CreateCashAccountTransactionDtoType | RemoteObject,
  transactions: {
    value: I.InvestmentTransactionDtoType[],
    loading: boolean,
    error: string
  },
  income: I.IncomeTransactionDtoType | RemoteObject,
  incomes: {
    value?:  I.IncomeTransactionDtoType[],
    loading: boolean,
    error: string
  },
  prices: InvestmentPricesDtoType | RemoteObject,
}

export const useInvestmentStore = defineStore('investment', () => {
  const investment = ref<State['investment']>({})
  const investmentSummary = ref<State['investmentSummary']>({})
  const transaction = ref<State['transaction']>({})
  const transactions = ref<State['transactions']>({
    value: [],
    loading: false,
    error: ''
  })
  const income = ref<State['income']>({})
  const incomes = ref<State['incomes']>({
    value: undefined,
    loading: false,
    error: ''
  })
  const prices = ref({
    value: [],
    loading: false,
    error: ''
  })

  const getInvestmentSummary = async (investmentId: string, period: string, range: {}) => {
    investmentSummary.value = { loading: true }

    const filters = new URLSearchParams(
      Object.assign({}, range, { period })
    ).toString()

    fetchWrapper
      .get(`/api/v1/investment/${investmentId}/summary?${filters}`)
      .then((summaryResponse) => {
        investmentSummary.value = summaryResponse
      })
      .catch((error) => (investmentSummary.value = { error }));
  }

  const getInvestmentNetworthSummary = async (investmentId: string, params: any) => {
    return fetchWrapper
      .get(`/api/v1/investment/${investmentId}/summary/networth?${new URLSearchParams(params).toString()}`)
      .then((summaryResponse) => {
        return summaryResponse
      })
      .catch(() => ({}));
  }

  const getInvestmentPrefillInfo = async (investmentId: string) => {
    return fetchWrapper
      .get(`/api/v1/investment/${investmentId}/prefill`)
  }

  const getInvestmentPrices = async (investmentId: string, query: any = {limit: 10, offset: 0}) => {
    prices.value.loading = true

    fetchWrapper
      .get(`/api/v1/investment/${investmentId}/prices?limit=${query.limit}&offset=${query.offset}`)
      .then((pricesResponse) => {
        prices.value.value = pricesResponse
      })
      .catch((error) => prices.value.error = error)
      .then(() => {
        prices.value.loading = false
      });
  }

  const createPrice = async (investmentId: string, price: CreatePriceDtoType): Promise<PriceDtoType> => {
    return fetchWrapper.post(`/api/v1/investment/${investmentId}/price`, price)
      .then((priceResponse) => {
        getInvestmentPrices(investmentId)
        return priceResponse
      })
  }

  const deletePrice = async (investmentId: string, priceId: string): Promise<void> => {
    return fetchWrapper.delete(`/api/v1/investment/${investmentId}/price/${priceId}`)
      .then(() => {
        getInvestmentPrices(investmentId)
      })
  }

  const deleteInvestment = async (investmentId: string): Promise<void> => {
    return fetchWrapper.delete(`/api/v1/investment/${investmentId}`)
  }

  const importPrices = async (investmentId: number,  file: File): Promise<void> => {
    const formData = new FormData()
    formData.append('options', JSON.stringify({ importType: 'price' }))
    formData.append('file', file)
    return fetchWrapper.post(`/api/v1/investment/${investmentId}/prices/import`, formData)
  }

  return {
    getInvestmentSummary,
    getInvestmentNetworthSummary,
    getInvestmentPrices,
    getInvestmentPrefillInfo,
    investment,
    investmentSummary,
    transaction,
    transactions,
    income,
    incomes,
    prices,
    deletePrice,
    deleteInvestment,
    createPrice,
    importPrices,
    async get (id: string, forceRefresh = false) {
      if (!forceRefresh && investment?.id === id || !id) {
        return
      }

      investment.value = { loading: true }
      transactions.value.loading = true

      fetchWrapper
        .get(`/api/v1/investment/${id}`)
        .then((investmentResponse) => {
          investment.value = investmentResponse
        })
        .catch((error) => (investment.value = { error }));
    },
    async createInvestment(investment: I.InvestmentCreateDtoType): Promise<I.InvestmentDtoType> {
      return fetchWrapper.post('/api/v1/investment', investment);
    },
    async updateInvestment (investmentId: number, investment: Partial<I.InvestmentDtoType>): Promise<I.InvestmentDtoType> {
      return fetchWrapper.put(`/api/v1/investment/${investmentId}`, investment);
    },
    async createTransaction(investmentId: number, transaction: CreateCashAccountTransactionDtoType) {
      return fetchWrapper.post(`/api/v1/investment/${investmentId}/transaction`, transaction);
    },
    async createLoan(investmentId: number) {
      return fetchWrapper.post(`/api/v1/investment/${investmentId}/loan`);
    },
    async createIncome(investmentId: number, income: CreateCashAccountTransactionDtoType) {
      return fetchWrapper.post(`/api/v1/investment/${investmentId}/income`, income);
    },
    async getTransaction(investmentId: number, transactionId: number) {
      transaction.value = { loading: true }

      fetchWrapper
        .get(`/api/v1/investment/${investmentId}/transaction/${transactionId}`)
        .then((transactionResponse) => {
          transaction.value = transactionResponse
        })
        .catch((error) => (transaction.value = { error }));
    },
    async deleteTransaction(investmentId: number, transactionId: number) {
      return fetchWrapper.delete(`/api/v1/investment/${investmentId}/transaction/${transactionId}`);
    },
    async updateTransaction(investmentId: number, transactionId: number, transaction: CreateCashAccountTransactionDtoType) {
      return fetchWrapper.put(`/api/v1/investment/${investmentId}/transaction/${transactionId}`, transaction);
    },
    async getTransactions(investmentId: number, query: any = {limit: 10, offset: 0}) {
      transactions.value.loading = true

      fetchWrapper
        .get(`/api/v1/investment/${investmentId}/transactions?limit=${query.limit}&offset=${query.offset}`)
        .then((transactionsResponse) => {
          transactions.value.value = transactionsResponse
        })
        .catch((error) => (transactions.value.error = error))
        .then(() => {
          transactions.value.loading = false
        });
    },
    async getIncome(investmentId: number, incomeId: number) {
      income.value = { loading: true }

      fetchWrapper
        .get(`/api/v1/investment/${investmentId}/income/${incomeId}`)
        .then((incomeResponse) => {
          income.value = incomeResponse
        })
        .catch((error) => (income.value = { error }));
    },
    async updateIncome(investmentId: number, incomeId: number, income: CreateCashAccountTransactionDtoType) {
      return fetchWrapper.put(`/api/v1/investment/${investmentId}/income/${incomeId}`, income);
    },
    async getIncomes(investmentId: string, query: any = {limit: 10, offset: 0}) {
      incomes.value.loading = true

      fetchWrapper
        .get(`/api/v1/investment/${investmentId}/incomes?limit=${query.limit}&offset=${query.offset}`)
        .then((transactions) => {
          incomes.value.value = transactions
        })
        .catch((error) => (incomes.error = error ))
        .then(() => {
          incomes.value.loading = false
        });
    },
    async moveToListedInstrument(investmentId: number, form: I.MoveInvestmentDtoType) {
      return fetchWrapper.post(`/api/v1/investment/${investmentId}/migrate`, form);
    },
    async moveToOtherPortfolio(investmentId: number, form: I.MoveInvestmentPortfolioDtoType) {
      return fetchWrapper.post(`/api/v1/investment/${investmentId}/move`, form);
    }
  }
});

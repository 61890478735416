<script setup lang="ts">
import { currencyCodes, fieldDesign, currencyCodesMap } from '@/const';
import { computed, type PropType } from 'vue';
import type { Currency } from '~/enums';
import CurrencyItem from './CurrencyItem.vue';

const props = defineProps({
  modelValue: {
    type: String as PropType<Currency>,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Array,
    default: () => []
  },
  helpText: {
    type: String,
    default: ''
  },
  helpPosition: {
    type: String,
    default: 'label',
  },
  hint: {
    type: String,
    default: ''
  },
  allowedCurrencies: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['update:modelValue']);

const currencyCode = computed<Currency>({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const flag = computed(() => {
  return currencyCodesMap[currencyCode.value]?.flag
});

const allRules = computed(() => {
  if (props.disabled) return []
  return [...props.rules]
});

const currencyCodesOptions = computed(() => {
  return currencyCodes
    .value
    .filter((currencyCode) => props.allowedCurrencies.length === 0 || props.allowedCurrencies.includes(currencyCode))
});

</script>

<template>
   <v-select
    v-bind="(fieldDesign as any)"
    :items="currencyCodesOptions"
    :label="label"
    v-model="currencyCode"
    :disabled="disabled"
    :rules="allRules"
    v-bind:hint="hint"
    persistent-placeholder
  >
    <template v-slot:append v-if="helpText && props.helpPosition === 'append'">
      <HoverHelpIcon size="24">
        {{ helpText }}
      </HoverHelpIcon>
    </template>
    <template v-slot:item="{ props, item }">
      <CurrencyItem :currency="item.value" v-bind="props" :title="''" />
    </template>
    <template v-slot:prepend-inner v-if="flag">
      <div style="font-size:30px">
        {{ flag }}
      </div>
    </template>
    <template v-slot:label>
      {{ props.label }}
      <HoverHelpIcon
        color="lightText"
        v-if="helpText && props.helpPosition === 'label'"
        size="16"
        :helpText="helpText"
      />
    </template>
  </v-select>
</template>
<script setup lang="ts">
import { useGroupBy } from '@/filter.helper';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    default: ''
  }
});

const emit = defineEmits(['update:modelValue']);

const { groupByOptions } = useGroupBy();

const groupBy = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});
</script>

<template>
   <v-select
    :label="$t('label.group_by')"
    v-model="groupBy"
    :items="groupByOptions"
    hide-details
    class="text-left"
    variant="outlined"
    style="min-width: 200px;"
    id="performance-group-by-select"
  >
    <template v-slot:item="{ props, item }">
      <template v-if="item.value === 'manage-custom'">
        <v-divider />
      </template>
      <template v-if="item.value === 'manage-custom'">
        <v-list-item :title="''" class="d-flex align-center" density="compact">
          <router-link @click.stop :to="{ name: 'list-groups' }" class="text-primary">
            {{ item.title }}
          </router-link>
        </v-list-item>
      </template>
      <v-list-item v-else v-bind="props" :title="''" class="d-flex align-center" density="compact">
        {{ item.title }}
      </v-list-item>
    </template>
  </v-select>
</template>
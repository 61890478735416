import en from './en.json';
import et from './et.json';
import { en as enVuetify, et as etVuetify } from 'vuetify/locale'

const messages = {
  en: {
    ...en,
    $vuetify: enVuetify
  },
  et: {
    ...et,
    $vuetify: etVuetify
  }
};

export default messages;

<template>
  <Dialog v-model:model-visible="visible" :title="$t('account_page.add_currency')">
    <div class="text-body-1">
      <CurrencyField
        v-model="(form.currencyCode as Currency)"
        :label="$t('label.currency')"
      />
    </div>
    <v-btn color="success" @click="add" :loading="isLoading" class="mt-4">
      {{ $t('label.add') }}
    </v-btn>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from '@/components/Dialog.vue';
import { useAppStore } from '@/stores/app';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { computed } from 'vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import CurrencyField from '@/components/inputs/CurrencyField.vue';
import type { Currency } from '~/enums';
import { usePortfolioStore } from '@/stores/portfolioStore';

const props = defineProps({
  modelVisible: Boolean,
  account: {
    type: Object,
    required: false
  }
});

const emit = defineEmits(['update:modelVisible', 'added']);

const router = useRouter();
const portfolioStore = usePortfolioStore();
const { t } = useI18n();

const form = ref({
  currencyCode: ''
});

const visible = computed({
  get: () => props.modelVisible,
  set: (value) => {
    emit('update:modelVisible', value)
  }
});

const appStore = useAppStore();

const isLoading = ref(false);

const add = async () => {
  isLoading.value = true;
  try {
    const account = await fetchWrapper.put(`/api/v1/account/${props.account?.id}/currency`, {
      currencyCode: form.value.currencyCode
    });
    appStore.showNotice(t('account_page.messages.currency_added'), 'success');
    portfolioStore.getAllAccounts(portfolioStore.activePortfolioId);
    router.push({ name: 'account', params: { accountId: account.id } });
    visible.value = false;
    emit('added');
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
}
</script>

<script setup lang="ts">
import FormContainer from '@/components/shared/FormContainer.vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { fieldDesign } from '@/const';
import { investmentTypeOptions, assetClassTypeOptions } from '@/utils/instrument.utils';
import { watch } from 'vue';
import { useInstrumentStore } from '@/stores/instrumentStore';
import type { UpdateCustomInstrumentDtoType } from '~/instrument.schema';
import { useRouter } from 'vue-router';
import { useAppStore } from '@/stores/app';

const props = defineProps({
  instrument: {
    type: Object,
    default: null
  },
  isLoading: {
    type: Boolean,
    default: true
  }
})

const { t } = useI18n()

const isDeleteLoading = ref(false);
const isLoading = ref(false);
const instrumentStore = useInstrumentStore();
const router = useRouter();
const appStore = useAppStore();

const translations = computed(() => {
  return {
    title: t('instrument_page.edit_custom_title')
  }
})

const form = ref({
  id: undefined,
  name: undefined,
  symbol: undefined,
  type: undefined,
  assetClass: undefined,
  consolidationLabel: undefined,
})

watch(() => props.instrument, (instrument) => {
  if (!instrument) {
    return;
  }
  form.value = {
    id: instrument.id,
    name: instrument.name,
    symbol: instrument.symbol,
    type: instrument.type,
    assetClass: instrument.assetClass,
    consolidationLabel: instrument.consolidationLabel,
  }
}, { immediate: true })


const onSubmit = async () => {
  isLoading.value = true;

  try {
    await instrumentStore.updateCustomInstrument(props.instrument.id, form.value as any as UpdateCustomInstrumentDtoType);
  } finally {
    isLoading.value = false;
  }
}

const onDelete = async () => {
  try {
    isDeleteLoading.value = true;
    await instrumentStore.deleteCustomInstrument(props.instrument.id);
    router.push({ name: 'all-instruments' });
    appStore.showNotice(t('instrument_page.delete_success'), 'success');
  } finally {
    isDeleteLoading.value = true;
  }
}

const isFormLoading = computed(() => {
  return isLoading.value || props.isLoading;
})
</script>

<template>
  <FormContainer
    :title="translations.title"
    :mode="'update'"
    @submit="onSubmit"
    @delete="onDelete"
    :is-form-loading="isFormLoading"
    :is-button-loading="isLoading"
    :confirm-text="t('instrument_page.confirm_delete')"
    color="panel-heading-bg"
  >
    <v-row class="mb-2">
      <v-col cols="12">
        <p class="text-body-1">
          {{ t('instrument_page.description') }}
        </p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-bind="fieldDesign"
          type="text"
          v-model="form.symbol"
          :label="t('label.instrument_code')"
          :disabled="instrument.isPublic"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-bind="fieldDesign"
          type="text"
          v-model="form.name"
          :label="t('label.instrument_name')"
          :disabled="instrument.isPublic"
        />
      </v-col>
      <v-col cols="12">
        <v-select
          v-bind="fieldDesign"
          :items="investmentTypeOptions"
          :label="t('label.instrument_type')"
          v-model="form.type"
          :disabled="true"
        />
      </v-col>
      <v-col cols="12" v-if="!instrument.isPublic">
        <v-text-field
          v-bind="fieldDesign"
          type="text"
          v-model="form.consolidationLabel"
          :label="t('label.consolidation_label')"
        >
          <template v-slot:append>
            <HoverHelpIcon size="24" :helpText="$t('instrument_page.consolidation_label_help')" />
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" v-if="!instrument.isPublic">
        <v-select
          v-bind="fieldDesign"
          :items="assetClassTypeOptions"
          :label="t('label.asset_class')"
          v-model="form.assetClass"
        />
      </v-col>
    </v-row>
    <template v-slot:actions v-if="instrument?.isPublic">
      <span></span>
    </template>
  </FormContainer>
</template>
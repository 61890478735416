<template>
  <v-dialog v-model:model-value="visible"  transition="dialog-bottom-transition" :max-width="maxWidth" :persistent="false" scrollable>
    <v-card>
      <v-toolbar color="primary-lighten-1">
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-btn v-if="!hideClose" icon="mdi-close" @click="visible = false"></v-btn>
      </v-toolbar>
      <v-card-text class="pa-4">
        <slot></slot>
        <div v-if="confirm?.show">
          <v-divider class="my-4"></v-divider>
          <v-btn @click="emit('confirm')" :color="confirm.color">{{ confirm.label }}</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  modelVisible: Boolean,
  title: String,
  maxWidth: {
    default: 500
  },
  hideClose: {
    type: Boolean,
    default: false
  },
  confirm: {
    default: {
      show: false,
      label: 'Kinnita',
      color: 'warning'
    }
  }
});

const emit = defineEmits(['update:modelVisible', 'confirm']);

const visible = computed({
  get: () => props.modelVisible,
  set: (value) => {
    emit('update:modelVisible', value)
  }
});

</script>
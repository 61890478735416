import { i18n } from '@/plugins/i18n';
import { computed, ref } from 'vue';
import { fetchWrapper } from './helpers/fetch-wrapper';
import { useAuthStore } from '@/stores/authStore';

const { t } = i18n.global;

const statusLevels: Record<any, any> = {
  '-1': {
    title: t('settings_page.permissions.rejected'),
    textColour: 'red',
    icon: 'mdi-close-circle'
  },
  '0': {
    title: t('settings_page.permissions.pending'),
    textColour: 'blue',
    icon: 'mdi-clock-outline'
  },
  '1': {
    title: t('settings_page.permissions.accepted'),
    textColour: 'green',
    icon: 'mdi-check-circle'
  }
}

export const mapPermissions = (permissions: {
  id: number,
  level: number,
  permissionUser: { email: string },
  status: number,
  portfolio: { id: number, name: string },
}[]) => {
  return permissions.map((permission) => {
    return {
      ...permission,
      status: statusLevels[permission.status],
      levelTitle: permission.level === 0 ? t('settings_page.permissions.read_only') : t('settings_page.permissions.read_write')
    }
  });
};

export function usePermissions() {
  const isGivenPermissionsLoading = ref(false);
  const permissions = ref([]);
  const authStore = useAuthStore();

  const getAllGivenPermissions = () => {
    isGivenPermissionsLoading.value = true;

    fetchWrapper.get(`/api/v1/user/${authStore.user.id}/permissions`)
      .then((response) => {
        permissions.value = response;
      })
      .finally(() => {
        isGivenPermissionsLoading.value = false;
      })
  }

  const mappedPermissions = computed(() => mapPermissions(permissions.value));
  
  return {
    isGivenPermissionsLoading,
    getAllGivenPermissions,
    mappedPermissions,
  }
}

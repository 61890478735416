<script setup lang="ts">
import { watch } from 'vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useRoute } from 'vue-router'

const route = useRoute()
const portfolioStore = usePortfolioStore();

watch(() => route.params.portfolioId, async newId => {
  portfolioStore.select(newId as string);
}, { immediate:true });
</script>

<template>
  <router-view />
</template>

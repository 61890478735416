<script setup lang="ts">
import { computed } from 'vue';
import TransactionForm from './../investment/TransactionForm.vue';
import { useLoanStore } from '@/stores/loanAccountStore';
import { InstrumentType } from '~/enums';

const loanStore = useLoanStore();

const loan = computed(() => {
  return {
    ...loanStore.loan,
    type: InstrumentType.TakenLoan
  }
});

</script>

<template>
  <v-row>
    <v-col cols="12" lg="12">
      <v-card variant="flat">
        <transaction-form
          action="create"
          :investment="loan"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from 'vue';
import { fieldDesign, rules } from '@/const';
import * as S from '~/investment.schema';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useInvestmentStore } from '@/stores/investmentStore';
import { computed } from 'vue';
import type { PortfolioDtoType } from '~/portfolio.schema';
import { useRouter } from 'vue-router';
import { instrumentConfig } from '~/config';
import InstrumentSearchField from '@/components/inputs/InstrumentSearchField.vue';
import type { InstrumentDtoType } from "~/instrument.schema";
import TransactionForm from "./TransactionForm.vue";
import DisplayCard from "@/components/shared/DisplayCard.vue";
import { watch } from "vue";
import { Currency, InstrumentType } from "~/enums";
import Avatar from "@/components/Avatar.vue";
import CurrencyField from "@/components/inputs/CurrencyField.vue";

const { t } = useI18n();
const emit = defineEmits(['created', 'selected']);
const props = defineProps(['type', 'variant', 'prefill', 'title', 'allowAccounts'])
const isCustomMode = ref(false);
const instrument = ref<InstrumentDtoType | null>(null);
const transactionFormRef = ref(null);
const isTransactionValid = ref(false);
const isValidCustomForm = ref(false);
const isSearchLoading = ref(false);
const isSearchMode = computed(() => props.variant === 'search')

const portfolioStore = usePortfolioStore();
const investmentStore = useInvestmentStore();
const router = useRouter();

const portfolio = computed(() => portfolioStore.selectedPortfolio as PortfolioDtoType);

const translations = computed(() => {
  return {
    instrumentHint: form.value.instrumentType ? t(`instrumentType.${form.value.instrumentType}.help`) : ''
  }
})

const investmentTypeOptions = computed(() => {
  return Object.values(instrumentConfig)
    .filter((instrument) => instrument.type !== InstrumentType.Cash)
    .map((instrument) => {
      return {
        title: (instrument.icon ? `${instrument.icon} `: '') + t(`instrumentType.${instrument.type}.title`),
        value: instrument.type
      }
    })
})

const investment = computed(() => {
  return {
    currencyCode: instrument.value?.currencyCode,
    instrument: instrument.value,
    portfolioId: portfolio.value.id
  }
})

const form = ref<Partial<S.InvestmentCreateDtoType>>({
  name: props.prefill?.name,
  symbol: props.prefill?.symbol,
  currencyCode: props.prefill?.currencyCode ?? portfolio.value.currencyCode,
  portfolioId: portfolio.value.id,
  instrumentId: undefined,
  instrumentType: undefined,
  instrument: undefined,
  transaction: {
    portfolioId: portfolio.value.id,
    account: {
      currencyCode: undefined,
      feeCurrencyCode: undefined
    },
    counterparty: {
      currencyCode: undefined,
      feeCurrencyCode: undefined
    }
  }
});

watch(() => instrument.value, (newValue) => {
  if (newValue) {
    form.value.transaction.account.currencyCode = newValue.currencyCode;
    form.value.transaction.counterparty.currencyCode = newValue.currencyCode;
    form.value.transaction.account.feeCurrencyCode = newValue.currencyCode;
    form.value.transaction.counterparty.feeCurrencyCode = newValue.currencyCode;
    if (newValue.type === InstrumentType.TakenLoan) {
      form.value.transaction.account.id = newValue.id;
    }
  }
})

const submit = async () => {
  transactionFormRef.value?.update();

  form.value.portfolioId = portfolio.value.id;
  
  if (!form.value.currencyCode) {
    form.value.currencyCode = portfolio.value.currencyCode;
  }

  if (!isCustomMode.value) {
    form.value.instrumentId = instrument.value?.id;
    form.value.instrument = instrument.value;
  } else {
    form.value.instrumentId = undefined;
    form.value.transaction = undefined;
  }

  if (props.variant === 'search' && !isCustomMode.value) {
    emit('selected', instrument.value)
    return;
  }

  await investmentStore.createInvestment(form.value).then((investment) => {
    if (props.variant === 'search') {
      emit('created', investment.instrument)
      return;
    }
    if (investment.instrument.type === InstrumentType.TakenLoan) {
      router.push({ name: 'loan', params: { loanId: investment.id } });
      return;
    }
    router.push({ name: 'investment', params: { investmentId: investment.id } });
  })
}

const onManual = (e: Event) => {
  e.preventDefault();
  isCustomMode.value = true;
}

const onSearch = (e: Event) => {
  e.preventDefault();
  isCustomMode.value = false;
}

const isValid = computed(() => {
  return (!isCustomMode.value && isTransactionValid.value) || (isCustomMode.value && isValidCustomForm.value);
})

const showTransactionInfo = computed(() => {
  return Object.keys(props.prefill || {})
    .filter(key => !!props.prefill[key])
    .some(key => ['description', 'counterpartyName', 'counterpartyName'].includes(key))
})
</script>

<template>
  <DisplayCard :title="props.title ?? t('investment_page.form.add_title')" color="panel-heading-bg">
    <v-card-text class="px-4" :style="`max-height:${isSearchMode ? '90vh' : 'auto'};`">
      <v-alert class="mb-2 pa-2 text-body-1" v-if="showTransactionInfo">
        <h4>{{ $t('investment_page.form.transaction_info_heading') }}</h4>
        <ul>
          <ol>{{ $t('investment_page.form.counterparty') }}: <strong>{{ prefill.counterpartyName }} {{ prefill.counterpartyAccount }}</strong></ol>
          <ol>{{ $t('investment_page.form.description') }}: <strong>{{ prefill.description }}</strong></ol>
        </ul>
      </v-alert>
      <v-row>
        <v-col cols="12" v-if="isCustomMode">
          <v-row>
            <v-col cols="12">
              {{ t('investment_page.form.create_custom') }} <a class="text-blue" @click="onSearch" href="#">{{ t('investment_page.form.back_to_public') }}</a>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="12" v-show="!isCustomMode">
          <instrument-search-field
            :field-design="fieldDesign"
            v-model="instrument"
            v-model:model-is-loading="isSearchLoading"
            :initial-search-text="prefill?.symbol"
            :filter="{...prefill, portfolioId: portfolio.id}"
            :allow-accounts="props.allowAccounts"
            @onAddCustomClick="() => isCustomMode = true"
          />
          <div class="mt-7 text-body-1" v-show="!instrument">
            <p class="text-h4 mb-2">
              {{ t('investment_page.form.public_investments') }}
            </p>
            <ul class="ml-3 text-medium-emphasis">
              <li>
                {{ t('investment_page.form.public_investments_list_1') }}
              </li>
              <li>
                {{ t('investment_page.form.public_investments_list_2') }}
              </li>
              <li>
                {{ t('investment_page.form.public_investments_list_3') }}
              </li>
              <li>
                {{ t('investment_page.form.public_investments_list_4') }}
              </li>
            </ul>
            <p class="mt-5 text-caption">
              {{ t('investment_page.form.missing_instruments') }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-4" style="margin-left:-17px;margin-right: -17px;">
        <v-col class="pa-0" cols="12" v-if="instrument && !isCustomMode">
          <div style="width:100%;" class="pa-3 bg-table-heading-bg mb-3 border pattern-bg" v-if="instrument && !isCustomMode">
            <div class="d-flex align-center flex-column">
              <div class="text-subtitle-2 font-weight-bold text-uppercase">{{ instrument.name }}</div>
              <div class="font-weight-medium d-flex align-center ga-2">
                <Avatar v-if="$vuetify.display.smAndUp" class="ml-n11" :code="instrument.symbol" :logo="instrument.logo" :size="36" />
                <span class="text-h2">{{ instrument.displayName }}</span>
              </div>
              <div class="font-weight-normal text-center">
                <p v-if="instrument.isinCode" class="text-caption">{{ t('label.isin_number') }}: {{ instrument.isinCode }}</p>
                <p class="text-caption">{{ t('label.currency') }}: {{ instrument.currencyCode }}</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" v-if="!isCustomMode && instrument && props.variant !== 'search'">
          <transaction-form
            ref="transactionFormRef"
            action="import"
            :investment="investment"
            v-model="form.transaction"
            v-model:is-valid="isTransactionValid"
          />
        </v-col>
        <v-col cols="12" md="6" v-show="isCustomMode">
          <v-form ref="formRef" v-model="isValidCustomForm">
            <v-row>
              <v-col cols="12" v-show="isCustomMode">
                <v-select
                  v-bind="fieldDesign"
                  :items="investmentTypeOptions"
                  :label="t('label.instrument_type')"
                  v-model="form.instrumentType"
                  v-bind:hint="translations.instrumentHint"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" v-show="isCustomMode">
                <v-text-field
                  v-bind="fieldDesign"
                  type="text"
                  v-model="form.name"
                  v-bind:hint="t('help.investment_name')"
                  :label="t('label.investment_name')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" v-show="isCustomMode">
                <v-text-field
                  v-bind="fieldDesign"
                  type="text"
                  v-model="form.symbol"
                  v-bind:hint="t('help.investment_code')"
                  :label="t('label.investment_code')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" v-show="isCustomMode">
                <CurrencyField
                  :label="t('label.currency')"
                  v-model="form.currencyCode as Currency"
                  :rules="[rules.required]"
                  :hint="$t('help.currency')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="12" :md="6" v-if="isCustomMode && form.instrumentType === InstrumentType.P2PLoan">
          <Note>
            <h3>Ühisrahastusportaali investeeringud</h3>
            <div class="mt-4 text-body-1">
              Soovitame lisaks investeeringule lisada portfelli ka eraldi konto, kus kajastada ühisrahastusportaalis seisvat raha.
              See aitab paremini jälgida, mis osa rahast on investeeritud.
            </div>
            <div class="mt-4 text-body-1">
              Kõige lihtsam on ühisrastusportaalide investeeringuid kajastada kuupõhiselt.
              Näiteks kui kuu jooksul investeerisid kokku 100 €, siis kajasta see kuu lõpu seisuga ühe tehinguna.
            </div>
          </Note>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <div class="pa-6 text-sm-right text-center">
      <v-btn v-if="isCustomMode || variant !== 'search'" @click="submit" color="primary" :disabled="!isValid">
        {{ t('button.add_to_portfolio') }}
      </v-btn>
      <v-btn v-else-if="variant === 'search'" @click="submit" color="primary" :loading="isSearchLoading" :disabled="isSearchLoading || !instrument">
        {{ t('button.select') }}
      </v-btn>
    </div>
  </DisplayCard>
</template>

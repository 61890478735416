import { InstrumentType, TransactionType } from "../enums";
import { InstrumentConfig } from "./Instrument.config";

export class EquityConfig extends InstrumentConfig {
  type: InstrumentType = InstrumentType.Equity;
  canBeMigratedToPublic: boolean = true;
  icon: string = '🏢';

  allowedTransactionTypes = [
    TransactionType.Buy,
    TransactionType.Sell,
    TransactionType.Opening,
    TransactionType.Split,
    TransactionType.Fee,
  ]

  allowedIncomeTypes = [
    TransactionType.Dividend,
    TransactionType.Tax,
  ]
}

export default new EquityConfig();

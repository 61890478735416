<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useCustomizerStore } from '../../../stores/customizer';
import Logo from '../logo/LogoMain.vue';
import ProfileDD from '../vertical-header/ProfileDD.vue';
import MegaMenuDD from '../vertical-header/MegaMenuDD.vue';
import vuetify from '@/plugins/vuetify';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/authStore';
import { usePortfolioStore } from '@/stores/portfolioStore';
import NotificationDD from '../vertical-header/NotificationDD.vue';

const customizer = useCustomizerStore();
const app = useAppStore();
const userStore = useAuthStore();
const portfolioStore = usePortfolioStore();
const authStore = useAuthStore();

const priority = ref(customizer.setHorizontalLayout ? 0 : 0);
const showNotifications = ref(false);
const showSettingsMenu = ref(false);

const isSmall = computed(() => {
  return vuetify.display.xs.value;
});

const height = computed(() => {
  return isSmall.value ? 60 : 70;
});

const notificationCount = computed(() => {
  return userStore.notifications.length;
});

const isSharedPortfolio = computed(() => {
  if (!portfolioStore.selectedPortfolio || !authStore.user) {
    return false;
  }
  return portfolioStore.selectedPortfolio?.user?.id !== authStore.user?.id
});

watch(priority, (newPriority) => {
  // yes, console.log() is a side effect
  priority.value = newPriority;
});

</script>

<template>
  <v-app-bar id="main-header" elevation="0" :priority="priority" :height="height" class="px-4" absolute>
    <div style="flex-grow:1;" class="maxWidth d-flex align-center ga-1 ga-sm-2">
      <Logo />

      <v-spacer />

      <v-menu 
        :close-on-content-click="true"
        v-model="app.portfoliosMenuState"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            rounded="xl"
            id="portfolios-dd"
            :color="isSharedPortfolio ? 'green-lighten-1' : 'white'"
            variant="tonal"
            v-bind="props"
            append-icon="mdi-chevron-down"
            :prepend-icon="isSmall ? undefined : 'mdi-briefcase-variant-outline'"
            :size="isSmall ? 'small' : 'default'"
          >
            <span :style="`max-width:${$vuetify.display.xs ? '100px' : 'auto'}`" class="text-truncate">{{ portfolioStore.selectedPortfolio?.name || $t('header.portfolios')}}</span>
          </v-btn>
        </template>
        <v-sheet max-width="500" elevation="12" rounded="md" class="pa-4">
          <MegaMenuDD @click:outside="() => app.hidePortfoliosMenu()" />
        </v-sheet>
      </v-menu>
      <v-menu v-model:model-value="showNotifications" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-badge v-if="$vuetify.display.smAndUp && notificationCount > 0" color="warning" :content="notificationCount">
            <v-btn
              rounded="xl"
              color="white"
              variant="tonal"
              v-bind="props"
              icon="mdi-bell-outline"
              :size="isSmall ? 'small' : 'small'"
            />
          </v-badge>
          <v-btn
            v-else-if="$vuetify.display.smAndUp" 
            rounded="xl"
            color="white"
            variant="tonal"
            v-bind="props"
            icon="mdi-bell-outline"
            :size="isSmall ? 'small' : 'small'"
            id="notification-dd"
          />
          <span v-else class="py-5" v-bind="props"></span>
        </template>
        <v-sheet max-width="500" elevation="12" rounded="md" class="pa-2">
          <NotificationDD @onClose="showNotifications = false" />
        </v-sheet>
      </v-menu>
      <v-menu v-model:model-value="showSettingsMenu" :close-on-content-click="true">
        <template v-slot:activator="{ props }">
          <v-btn
            id="settings-dd"
            :size="isSmall ? 'small' : 'default'"
            color="white"
            variant="tonal"
            v-bind="props"
            rounded="xl"
          >
            <div class="d-flex ga-1 align-center mx-n2">
              <v-avatar :size="isSmall ? 18 : 24" color="blue">
                <v-img :src="userStore.avatarUrl">
                  <template v-slot:placeholder>
                    <div class="text-center" style="padding-top:4px;padding-right:1px;">
                      {{ userStore.initials }}
                    </div>
                  </template>
                </v-img>
              </v-avatar>
              <v-icon :size="isSmall ? 18 : 24">mdi-cog</v-icon>
            </div>
          </v-btn>
          <v-badge v-if="!$vuetify.display.smAndUp && notificationCount > 0 && !showSettingsMenu" color="warning" :content="notificationCount" :offset-y="-12" :offset-x="8"></v-badge>
        </template>
        <v-sheet rounded="md" width="330" elevation="12">
          <ProfileDD
            @showNotifications="showNotifications = true"
            @close="showSettingsMenu = false"
          />
        </v-sheet>
      </v-menu>
    </div>
  </v-app-bar>
</template>

import FullLayout from '@/layouts/full/FullLayout.vue';

const ReportRoutes = {
  path: '/portfolio/:portfolioId/reports',
  meta: {
    requiresAuth: true
  },
  component: FullLayout,
  children: [
    {
      path: '',
      component: () => import('@/views/report/Layout.vue'),
      meta: {
        parent: 'report-dashboard'
      },
      children: [
        {
          name: 'report-dashboard',
          path: '',
          component: () => import('@/views/report/Index.vue'),
          meta: {
            title: 'Reports'
          }
        },
        {
          name: 'diversity-report',
          path: 'diversity',
          component: () => import('@/views/report/DiversityReport.vue'),
          meta: {
            title: 'Diversity report'
          }
        },
        {
          name: 'transactions-report',
          path: 'transactions',
          component: () => import('@/views/report/TransactionsReport.vue'),
          meta: {
            title: 'Transactions report'
          }
        },
        {
          name: 'future-cashflow-report',
          path: 'future-cashflow',
          component: () => import('@/views/report/FutureCashflowReport.vue'),
          meta: {
            title: 'Future cashflow report'
          }
        },
        {
          name: 'performance-report',
          path: 'performance',
          component: () => import('@/views/report/PerformanceReport.vue'),
          meta: {
            title: 'Performance report'
          }
        }
      ]
    },
  ]
};

export default ReportRoutes;

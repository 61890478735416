<script langs="ts" setup>
import { watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/authStore';

const { locale, t } = useI18n();
const authStore = useAuthStore();
const docs = ref();

watch(() => [locale.value, authStore.user?.disableHelp], () => {
  if (docs.value) {
    docs.value.innerHTML = '';
  }
  if (authStore.user?.disableHelp === true) {
    return;
  }
  setupConfig();
  loadScript();
}, { immediate: true });

function setupConfig () {
  const langPath = locale.value === 'en' ? '' : 'et/';
  window.betterdocs = {
    "IA_NONCE":"62d41944eb",
    "BASE_URL":`https:\/\/portfellow.com\/${langPath}wp-json\/`,
    "CHAT": {
      "show":true,
      "label": t('help.ask'),
      "subtitle": t('help.subtitle_two'),
      "subtitle_two": t('help.subtitle_three'),
    },
    "ANSWER": {
      "label":"Answer",
      "subtitle":"Instant Answer"
    },
    "URL":`https:\/\/portfellow.com\/${langPath}wp-json\/wp\/v2\/docs`,
    "SEARCH": {
      "show":false,
      "SEARCH_URL":`https:\/\/portfellow.com\/${langPath}wp-json\/wp\/v2\/docs`,
      "SEARCH_PLACEHOLDER": t('help.search'),
      "OOPS": t('help.oops'),
      "NOT_FOUND": t('help.not_found'),
    },
    "FEEDBACK": {
      "DISPLAY":true,
      "SUCCESS": t('help.success'),
      "TEXT": t('help.feedback'),
      "URL":"https:\/\/portfellow.com\/wp-json\/betterdocs\/v1\/feedback"
    },
    "RESPONSE":[],
    "ASKFORM": {
      "NAME": t('help.name'),
      "EMAIL": t('help.email'),
      "SUBJECT": t('help.subject'),
      "TEXTAREA": t('help.message'),
      "ATTACHMENT":"Accepts .gif, .jpeg, png, pdf, jpg and .png",
      "SENDING": t('help.sending'),
      "SEND": t('help.send'),
      "FILE_UPLOAD_SWITCH":true
    },
    "ASK_URL":"https:\/\/portfellow.com\/wp-json\/betterdocs\/v1\/ask",
    "HOME_FAQ": {
      "FAQ_URL":`https:\/\/portfellow.com\/${langPath}wp-json\/wp\/v2\/betterdocs_faq?per_page=100`,
      "HOME_FAQ_CONTENT_TYPE":"faq-list"
    },
    "FAQ":{
      "faq-title": t('help.faq'),
      "faq-switch":true,
      "faq_content_type":"faq-list",
      "faq-terms":[],
      "faq-list":[],
      "faq-terms-order":"asc",
      "faq-terms-order-by":"name",
      "faq-list-orderby":"id",
      "faq-list-order":"asc"
    },
    "DOC_CATEGORY":{
      "doc-title": t('help.docs_categories'),
      "doc-terms":[],
      "doc-category-switch":true,
      "doc-terms-order":"asc",
      "doc-terms-order-by":"name",
      "doc-subcategory-switch":true
    },
    "HOME_TAB_TITLE":t('help.home'),
    "HOME_CONTENT":"docs",
    "HOME_CONTENT_DOC_CATEGORY_TITLE": t('help.docs_categories'),
    "HOME_CONTENT_DOCS_TITLE": t('help.docs'),
    "HOME_TITLE": t('help.title'),
    "HOME_SUBTITLE": t('help.subtitle'),
    "RESOURCES_TITLE": t('help.resources'),
    "RESOURCES_TAB_TITLE": t('help.resources'),
    "HEADER_ICON":[],
    "HEADER_LOGO":[],
    "TAB_HOME_ICON":[],
    "TAB_MESSAGE_ICON":[],
    "TAB_RESOURCE_ICON":[],
    "BRANDING":{"show":false},
    "THANKS":{
      "title":"Thanks",
      "text":"Your Message Has Been Sent Successfully"
    }
  }
}

function loadScript() {
  const script = document.createElement('script');
  script.src = 'https://portfellow.com/wp-content/plugins/betterdocs-pro/assets/public/js/instant-answer-cd.js';
  script.async = true;
  document.body.appendChild(script);
}
</script>

<template>
  <div class="betterdocs-cross-domain-code" v-show="authStore.user?.disableHelp !== true">
    <div ref="docs" id="betterdocs-ia"></div>
  </div>
</template>

<style lang="scss">
@import url('https://portfellow.com/wp-content/plugins/betterdocs-pro/assets/public/css/instant-answer.css');

.betterdocs-ia-launcher-wrapper .betterdocs-ia-launcher,
.betterdocs-ia-common-header,
.betterdocs-ia-common-header .betterdocs-ia-search .betterdocs-ia-search-icon,
.betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-submit button,
.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-footer .betterdocs-ia-footer-feedback {
  background-color: #00b682;
}

.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header,
.betterdocs-ia-tabs .active p {
  color: #00b682;
}

.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header .content-icon-expand svg path,
.betterdocs-ia-docs-content .content-icon svg path,
.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header .content-icon-back svg path {
  fill: #00b682;
}

.betterdocs-ia-tabs li.active svg g path {
  fill: #00b682;
}

.betterdocs-ia-launcher-wrapper .betterdocs-ia-launcher:hover {
  background-color: #00b682;
  ;
}

.betterdocs-ia-common-header .betterdocs-title:not(:last-child) {
  color: #FFFFFF;
}

.betterdocs-ia-common-header h2 {
  color: #FFFFFF;
}

.betterdocs-ia-common-header .betterdocs-info {
  color: #FFFFFF;
}

.betterdocs-ia-docs .betterdocs-ia-docs-heading .doc-title {
  color: #111213;
}

.betterdocs-ia-docs .betterdocs-ia-docs-heading {
  background-color: #FFFFFF;
}

.betterdocs-ia-docs-content .content-item h4 {
  color: #111213;
}

.betterdocs-ia-docs-content .content-item p {
  color: #6D7175;
}

.betterdocs-ia-docs-content {
  background-color: #FFFFFF;
}

.betterdocs-ia-common-header .betterdocs-ia-search,
.betterdocs-ia-common-header .betterdocs-ia-search .betterdocs-ia-search-field {
  background-color: #FFFFFF;
}

.betterdocs-ia-common-header .betterdocs-ia-search .betterdocs-ia-search-field::placeholder {
  color: #1c1c1c;
}

.betterdocs-ia-common-header .betterdocs-ia-search .betterdocs-ia-search-field {
  color: #000000;
}

.betterdocs-ia-common-header .betterdocs-ia-search .betterdocs-ia-search-icon svg {
  fill: #FFFFFF;
}

.betterdocs-ia-tabs {
  background-color: #FFFFFF;
}

.betterdocs-ia-tabs li p {
  color: #202223;
}

.betterdocs-ia-tab-message-container .message__header .header__content h4 {
  color: #FFFFFF;
}

.betterdocs-ia-tab-message-container .message__header .header__content p {
  color: #FFFFFF;
}

.betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-group .ia-input,
.betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-group>textarea {
  background-color: #FFFFFF;
  color: #202223;
}

.betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-email-group p,
.betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-name-group p,
.betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-subject-group p,
.betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-message-group p {
  color: #202223;
}

.betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-attachments-group button {
  background-color: #FFFFFF;
}

.betterdocs-ia-tab-message-container .betterdocs-ia-feedback-form .betterdocs-ia-attachments-group p {
  color: #6d7175;
}

.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-content .doc-title {
  color: #111213;
}

.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header.on-scroll h2 {
  color: #111213;
}

.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header {
  background-color: #F6F6F7;
}

.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header .content-icon-back,
.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-header .content-icon-expand {
  background-color: #f6f6f7;
}

.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-footer .betterdocs-ia-footer-feedback .betterdocs-ia-reaction-group .ia-reaction {
  background-color: #00A375;
}

.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-footer .betterdocs-ia-footer-feedback .betterdocs-ia-reaction-group .ia-reaction .betterdocs-emo {
  fill: #FFFFFF;
}

.betterdocs-ia-single-docs-wrapper .betterdocs-ia-singleDoc-footer .betterdocs-ia-footer-feedback p {
  color: #FAFAFA;
}

.betterdocs-ia-launcher-wrapper {
  right: 20px;
}

.betterdocs-ia-main-wrapper {
  right: 20px;
}

.betterdocs-ia-singleDoc-content ul {
  color: #111213;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 1.5rem;
  padding-left: 15px;
}

.gb-block-notice {
  padding: 10px;
  border-radius: 10px;
}

.gb-notice-title {
  font-weight: bold;
  font-size: 120%;
}
</style>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { watch } from 'vue';
import isNil from 'lodash/isNil';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['update:modelValue']);
const { t } = useI18n();

const props = defineProps({
  modelValue: null,
  disabled: {
    type: Boolean,
    default: false
  },
  fieldDesign: {
    type: Object,
    default: () => ({})
  },
  label: {
    type: String,
    default: () => 'Konto'
  },
  showCreate: {
    type: Boolean,
    default: () => true
  },
  currencyCode: {
    type: String,
    required: false
  },
  hint: {
    type: String,
    required: false
  },
  multiple: {
    type: Boolean,
    default: false
  },
  investmentId: {
    required: false
  }
});

const value = ref([]);
const portfolioStore = usePortfolioStore();

const modelValue = computed({
  get() {
    return value.value
  },
  set(value) {
    emit('update:modelValue', value.map(i => i.value))
  }
});

const findParentId = (group: any, category: any) => {
  let previousParent = -1;

  for (let n = 0; n < group.config.categories.length; n++) {
    const current = group.config.categories[n];
    if (isNil(current.parentId)) {
      previousParent = n;
    }
    if (current.title === category.title) {
      return previousParent;
    }
  }

  return -1;
}

const generateName = (group: any, category: any) => {
  const items = [
    group.name
  ];

  if (!isNil(category.parentId)) {
    const parentId = findParentId(group, category);
    const parent = group.config.categories[parentId];
    items.push(parent.title);
  }

  items.push(category.title);

  return items.join(' > ');
}

const itemsGrouped = computed(() => {
  const all = (portfolioStore.categories || []).reduce((list, group) => {
    list.push({
      title: '',
      value: '',
      header: group.name,
    });
    list.push(...Object.entries((group.config.categories || [])).map(([k,category]) => {
      return {
        title: category.title,
        name: generateName(group, category),
        value: `${group.id}:${k}`,
      }
    }))
    return list;
  }, [] as any[]);
  all.push({
    value: 'manage-custom',
    title: t('label.manage_custom_groups')
  });
  return all;
});

watch(() => portfolioStore.categories, (list) => {
  value.value = [];
  portfolioStore.categories?.forEach(group => {
    (group.config.categories || []).forEach((category,k) => {
      Object.entries(category.investments).forEach(([id]) => {
        if (props.investmentId == id) {
          const item = itemsGrouped.value.find(i => i.value === `${group.id}:${k}`);
          if (item) {
            value.value.push(item);
          }
        }
      })
    })
  })
}, { immediate: true });

watch(value, (_value) => {
  modelValue.value = _value;
  if (_value.length <= 0) {
    return;
  }
  // latest added item
  const latest = _value[_value.length - 1];
  const [groupId, categoryId] = latest.value.split(':');
  const filtered = value.value.filter(i => i === latest || i.value.split(':')[0] !== groupId);
  if (filtered.length !== _value.length) {
    value.value = filtered;
    modelValue.value = filtered;
  }
}, { immediate: true });

</script>

<template>
  <v-autocomplete
    v-bind="(fieldDesign as any)"
    v-model="value"
    :items="itemsGrouped"
    item-title="name"
    item-value="id"
    :label="label"
    return-object
    clearable
    :multiple="true"
    chips
    closable-chips
  >
    <template v-slot:item="{ props, item }">
      <template v-if="item.value.header">
        <v-list-subheader>
          {{ item.value.header }}
        </v-list-subheader>
        <v-divider />
      </template>
      <template v-else-if="item.value.value === 'manage-custom'">
        <v-divider />
        <v-list-item :title="''" density="compact" >
          <router-link @click.stop :to="{ name: 'list-groups' }" class="text-primary">
            {{ item.value.title }}
          </router-link>
        </v-list-item>
      </template>
      <v-list-item v-else v-bind="props" :title="''" density="compact">
        <v-list-item-title>
          {{ item.raw.name }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:prepend-inner="{}">
      <v-icon>mdi-magnify</v-icon>
    </template>
  </v-autocomplete>
</template>
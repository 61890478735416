<template>
  <v-tabs
    :style="`min-width: 150px;`"
    v-model="value"
    color="blue"
    :direction="!isMobileView ? 'vertical' : 'horizontal'"
    :class="{'mb-4': isMobileView}"
    :centered="$vuetify.display.xs"
    :stacked="$vuetify.display.xs"
    :grow="$vuetify.display.xs">
    <v-tab v-for="tab in tabs" :value="tab.value" :prepend-icon="tab.icon" :disabled="tab.disabled">
      {{ tab.label }}
    </v-tab>
  </v-tabs>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useDisplay } from 'vuetify';

const props = defineProps({
  modelValue: null,
  tabs: {
    default: () => [] as { label: string, value: string, icon: string, disabled: boolean }[]
  }
})

const emit = defineEmits([
  'update:modelValue'
]);

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const { xs } = useDisplay();

const isMobileView = computed(() => {
  return xs.value;
})
</script>
<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const appStore = useAppStore();
const tabs = computed(() => appStore.tabs);
const router = useRouter();

const tab = computed({
  get() {
    const currentTab = (tabs.value || [])
      .find((tab) => tab.to.name === router.currentRoute.value.name || (router.currentRoute.value.meta?.parent && tab.to.name === router.currentRoute.value.meta?.parent));
    return currentTab ? currentTab.to.name : '-';
  },
  set(value) {
    // something
  }
})

const isTabActive = (item: any) => {
  return item.items.some((item) => item.to.name === router.currentRoute.value.name);
}
</script>

<template>
  <div class="maxWidth" v-if="tabs.length">
    <v-tabs v-model="tab" color="green-lighten-3" dark :mandatory="false" :centered="$vuetify.display.xs" :stacked="$vuetify.display.xs" :grow="$vuetify.display.xs">
      <template v-for="(item, i) in tabs" :key="item.to.name">
        <v-tab v-if="!item.items" :value="item.to.name" :to="item.to" :exact="true" :id="item.id">
          <v-icon v-if="item.icon" size="24" stroke-width="1.5"  :class="{'mb-1': $vuetify.display.xs, 'mr-2': !$vuetify.display.xs}">{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-tab>
        <v-menu v-else :open-on-hover="!$vuetify.display.xs" v-model="appStore.toolsMenuState">
          <template v-slot:activator="{ props }">
            <v-btn
              class="align-self-center"
              height="100%"
              rounded="0"
              variant="text"
              v-bind="props"
              :id="item.id"
            >
              <div class="d-flex flex-column flex-sm-row align-center">
                <v-icon :color="isTabActive(item) ? 'green-lighten-3' : undefined" v-if="item.icon" size="24" stroke-width="1.5"  :class="{'mb-1': $vuetify.display.xs, 'mr-2': !$vuetify.display.xs}">{{ item.icon }}</v-icon>
                <div :class="{'text-green-lighten-3': isTabActive(item)}">
                  {{ item.title }}
                  <v-icon class="mr-n2" :color="isTabActive(item) ? 'green-lighten-3' : undefined" icon="mdi-menu-down"></v-icon>
                </div>
              </div>
              <div v-if="isTabActive(item)" class="bg-green-lighten-3" style="height:2px;background:red;position: absolute;bottom:0;left:0;right:0;"></div>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(subitem, k) in item.items" :key="k" :to="subitem.to" :id="subitem.id">
              <v-list-item-title class="text-body-1 d-flex align-center ga-2">
                <v-icon color="secondary" size="24">
                  {{ subitem.icon }}
                </v-icon>
                <span>
                  {{ subitem.title }}
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-tabs>
  </div>
</template>

<style lang="scss">
.maxWidth .v-slide-group__content {
  overflow-x: auto !important;
}
</style>

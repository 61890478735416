export const AccountsConfig = {
  0: {
    id: 0,
    key: 'other',
    title: 'Other',
    shortName: '?',
    backgroundColor: 'primary',
    color: '#ffffff',
    goId: null
  },
  1: {
    id: 1,
    key: 'lhv',
    title: 'LHV',
    shortName: 'LHV',
    backgroundColor: 'primary',
    color: '#ffffff',
    logo: '/logos/lhv1t.svg',
    goId: 'LHV_LHVBEE22'
  },
  2: {
    id: 2,
    key: 'lightyear',
    title: 'Lightyear',
    shortName: 'LY',
    backgroundColor: 'primary',
    color: '#ffffff',
    logo: '/logos/lightyear.png',
    goId: null
  },
  3: {
    id: 3,
    key: 'ib',
    title: 'Interactive Brokers',
    shortName: 'IB',
    backgroundColor: 'primary',
    color: '#ffffff',
    logo: '/logos/ib.svg',
    goId: null,
  },
  4: {
    id: 4,
    key: 'swedbank',
    title: 'Swedbank',
    shortName: 'Swedbank',
    backgroundColor: 'primary',
    color: '#ffffff',
    logo: '/logos/sweda.svg',
    goId: 'SWEDBANK_HABAEE2X'
  },
  5: {
    id: 5,
    key: 'seb',
    title: 'SEB',
    shortName: 'SEB',
    backgroundColor: 'primary',
    color: '#ffffff',
    logo: '/logos/seb.svg',
    goId: 'SEB_EEUHEE2X'
  },
  6: {
    id: 6,
    key: 'wise',
    title: 'Wise',
    shortName: 'Wise',
    backgroundColor: 'primary',
    color: '#ffffff',
    logo: '/logos/wise.svg',
    goId: 'WISE_TRWIGB22',
    accessValidForDaysMax: 90
  },
  7: {
    id: 7,
    key: 'revolut',
    title: 'Revolut',
    shortName: 'Revolut',
    backgroundColor: 'white',
    color: '#ffffff',
    logo: '/logos/revolut.png',
    goId: null,
  },
  8: {
    id: 8,
    key: 'mintos',
    title: 'Mintos',
    shortName: 'Mintos',
    backgroundColor: 'white',
    color: '#ffffff',
    logo: '/logos/mintos.jpeg',
    goId: null,
  },
  9: {
    id: 9,
    key: 'omaraha',
    title: 'Omaraha',
    shortName: 'Omaraha',
    backgroundColor: 'white',
    color: '#ffffff',
    logo: '/logos/omaraha.jpeg',
    goId: null,
  },
  10: {
    id: 10,
    key: 'funderbeam',
    title: 'Funderbeam',
    shortName: 'Funderbeam',
    backgroundColor: 'white',
    color: '#ffffff',
    logo: '/logos/funderbeam.svg',
    goId: null,
  }
}

export const findAccountConfigByGoId = (goId: string) => {
  if (!goId) {
    return null
  }
  return Object.values(AccountsConfig).find((config: any) => config.goId === goId)
}
<template>
  <v-list-item @click="$emit('onSelect', currency)" :title="''">
    <v-list-item-title class="d-flex ga-2 align-center">
      <div style="font-size:30px">
        {{ item.flag }}
      </div>
      <v-chip border="xs" density="compact" class="px-1" label>
        {{ item.title }}
      </v-chip>
      <div>
        {{ item.name }}
      </div>
    </v-list-item-title>
  </v-list-item>
</template>

<script setup lang="ts">
import { currencyCodesMap } from '@/const';
import { computed, type PropType } from 'vue';
import { Currency } from '~/enums';

const emits = defineEmits(['onSelect']);

const props = defineProps({
  currency: String as PropType<Currency>
});

const item = computed(() => {
  const data = currencyCodesMap[props.currency as Currency];

  return {
    flag: data.flag,
    title: props.currency,
    name: data.name,
  }
});
</script>
import { defineStore } from 'pinia';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import type { RemoteObject } from '@/const';
import { usePortfolioStore } from './portfolioStore';
import { ref } from 'vue';
import type { LoanAccountCreateDtoType, LoanAccountDtoType, LoanAccountTransactionDtoType } from '~/loan-account.schema';
import type { CreateCashAccountTransactionDtoType } from '~/cash-account.schema';

type State = {
  loan: LoanAccountDtoType | RemoteObject
  loans: LoanAccountDtoType[] | RemoteObject,
  transaction: CreateCashAccountTransactionDtoType | RemoteObject,
  transactions:  {
    value: LoanAccountTransactionDtoType[],
    loading: boolean,
    error: string
  },
}

export const useLoanStore = defineStore('loan-account', () => {
  const portfolioStore = usePortfolioStore()

  const loanId = ref(null)
  const loan = ref<State['loan']>({})
  const loans = ref<State['loans']>({})
  const transaction = ref<State['transaction']>({})
  const transactions = ref<State['transactions']>({
    value: [],
    loading: false,
    error: ''
  })

  const get = async (id: string, forceRefresh = false) => {
    if (!forceRefresh && loan.value?.id === id || !id) {
      return
    }

    loanId.value = id
    loan.value = { loading: true }
    transactions.value.loading = true

    fetchWrapper
      .get(`/api/v1/loan/${id}`)
      .then((_response) => {
        loan.value = _response
      })
      .catch((error) => (loan.value = { error }));
  }


  const getAllLoans = async () => {
    try {
      loans.value = await fetchWrapper.get('/api/v1/loan');
      return loans.value;
    } finally {
      // ignore
    }
  }
  
  const createLoanAccount = async (account: LoanAccountCreateDtoType): Promise<LoanAccountDtoType> => {
    return fetchWrapper.post('/api/v1/loan', account)
      .then((_response) => {
        portfolioStore.getAllAccounts(_response.portfolioId)
        return _response
      })
  }

  const updateLoanAccount = async (id: string, accountDto: LoanAccountCreateDtoType): Promise<LoanAccountDtoType> => {
    return fetchWrapper.put(`/api/v1/loan/${id}`, accountDto)
      .then((_response) => {
        portfolioStore.getAllAccounts(_response.portfolioId)
        loan.value = _response
        return _response
      })
  }

  const deleteLanAccount = async (id: string): Promise<void> => {
    return fetchWrapper.delete(`/api/v1/loan/${id}`)
      .then(() => {
        portfolioStore.getAllAccounts(portfolioStore.portfolio.id)
      })
  }

  const createLoanAccountTransaction = async (accountId: number, transaction: CreateCashAccountTransactionDtoType) => {
    return fetchWrapper.post(`/api/v1/loan/${accountId}/transaction`, transaction);
  }

  const getLoanAccountTransaction = async (accountId: string, transactionId: string) => {
    transaction.value = { loading: true }

    fetchWrapper
      .get(`/api/v1/loan/${accountId}/transaction/${transactionId}`)
      .then((_response) => {
        transaction.value = _response
      })
      .catch((error) => (transaction.value = { error }));
  }

  const deleteLoanAccountTransaction = async (accountId: string, transactionId: string) => {
    return fetchWrapper.delete(`/api/v1/loan/${accountId}/transaction/${transactionId}`);
  }
  
  const updateLoanAccountTransaction = async (accountId: number, transactionId: string, transaction: CreateCashAccountTransactionDtoType) => {
    return fetchWrapper.put(`/api/v1/loan/${accountId}/transaction/${transactionId}`, transaction);
  }

  const getLoanAccountTransactions = async (accountId: number, query: any = {limit: 10, offset: 0}) => {
    transactions.value.loading = true

    fetchWrapper
      .get(`/api/v1/loan/${accountId}/transactions?limit=${query.limit}&offset=${query.offset}`)
      .then((transactionsResponse) => {
        transactions.value.value = transactionsResponse
      })
      .catch((error) => {
        transactions.value.error = error
        transactions.value.value = []   
      })
      .then(() => {
        transactions.value.loading = false
      });
  }

  return {
    loanId,
    loan,
    loans,
    transaction,
    transactions,
    get,
    createLoanAccount,
    updateLoanAccount,
    deleteLanAccount,
    createLoanAccountTransaction,
    getLoanAccountTransaction,
    deleteLoanAccountTransaction,
    updateLoanAccountTransaction,
    getLoanAccountTransactions,
    getAllLoans
  }
});

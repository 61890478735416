<script setup lang="ts">
import { watch } from 'vue';
import TransactionForm from './IncomeForm.vue';
import { useInvestmentStore } from '@/stores/investmentStore';
import { useRoute } from 'vue-router';

const investmentStore = useInvestmentStore();
const route = useRoute();

watch(() => route.params.incomeId, (incomeId) => {
  if (!incomeId) {
    return;
  }
  investmentStore.getIncome(route.params.investmentId as string, incomeId as string);
}, { immediate: true });
</script>

<template>
  <v-row>
    <v-col cols="12" md="12">
      <transaction-form action="update" />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const authStore = useAuthStore();

const loginForm = ref();
const isVerifyLoading = ref(false);
const otpRequestToken = ref('');
const verifyError = ref('');
const isValid = ref(false);
const show1 = ref(false);
const password = ref('');
const username = ref('');
const otp = ref('');
const remember = ref(false);
const isSubmitting = ref(false);
const apiError = ref('');

const passwordRules = ref([
  (v: string) => !!v || 'Password is required',
]);
const emailRules = ref([(v: string) => !!v || t('login_page.email_required'), (v: string) => /.+@.+\..+/.test(v) || t('login_page.email_valid')]);

async function validate() {
  const { valid } = await loginForm.value.validate();
 
  if (!valid || isSubmitting.value) {
    return;
  }

  isValid.value = valid;
  isSubmitting.value = true;
  apiError.value = '';

  return authStore.login(username.value, password.value)
    .catch((error) => {
      isSubmitting.value = false;
      if (error?.errorCode === 'otp_required') {
        otpRequestToken.value = error.token;
      } else {
        apiError.value = t(`error.${error.message.replace(/\.|"/g, '_')}`, error)
      }
    }
  );
}

watch(() => otp.value, () => {
  if (otp.value.length === 6) {
    verify();
  }
});

async function verify() {
  try {
    isVerifyLoading.value = true;
    await authStore.loginOtp(otp.value, remember.value, otpRequestToken.value);
  } catch (e: any) {
    if (e.message === 'Invalid OTP') {
      verifyError.value = t('error.invalid_otp');
    }
  } finally {
    isVerifyLoading.value = false;
  }
}
</script>

<template>
  <div v-if="otpRequestToken">
    <h5 class="text-h2 text-center my-4 mb-2">{{ $t('login_page.two_factor_title') }}</h5>
    <p class="text-center">{{ $t('login_page.two_factor_description') }}</p>
    <div class="d-flex flex-column">
      <v-otp-input bg-color="surface" class="ml-n2" v-model="otp" :error="!!verifyError" :loading="isVerifyLoading" @input="verifyError = ''"></v-otp-input>
      <p class="text-center mt-n2 text-body-2 text-error" v-if="verifyError">
        {{ verifyError }}
      </p>
    </div>
    <div class="d-flex justify-center">
      <v-checkbox bg-color="surface" v-model="remember" :label="$t('login_page.two_factor_remember')"></v-checkbox>
    </div>
    <v-btn color="blue" :loading="isVerifyLoading" block class="mt-2" variant="flat" :disabled="!otp || isVerifyLoading" @click="verify">
      {{ $t('login_page.verify') }}
    </v-btn>
  </div>
  <div v-else>
    <div class="mt-5" :style="{colorScheme: $vuetify.theme.current.dark ? 'light' : 'light'}">
      <div id="g_id_onload"
        data-client_id="517474754173-s0f971mj4jpm1qq8v3d4rjml25vmanda.apps.googleusercontent.com"
        data-context="signin"
        data-ux_mode="popup"
        data-login_uri="https://app.portfellow.com/api/v1/auth/google"
        data-auto_select="false"
        data-itp_support="true">
      </div>

      <div class="g_id_signin"
          data-type="standard"
          data-shape="rectangular"
          data-theme="filled_blue"
          data-text="continue_with"
          data-size="large"
          data-logo_alignment="left">
      </div>

      <v-divider class="my-7 divider text-center" />
    </div>

    <v-form ref="loginForm" lazy-validation class="mt-7 loginForm" @keyup.enter="validate">
      <div class="text-body-1 text-center">{{ $t('login_page.use_email') }}</div>

      <v-text-field
        v-model="username"
        :rules="emailRules"
        :label="$t('login_page.email')"
        class="mt-4 mb-5"
        required
        density="comfortable"
        hide-details="auto"
        variant="outlined"
        color="primary"
        autocomplete="username"
        bg-color="surface"
      />
      <v-text-field
        v-model="password"
        :rules="passwordRules"
        :label="$t('login_page.password')"
        required
        density="comfortable"
        variant="outlined"
        color="primary"
        hide-details="auto"
        autocomplete="current-password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        @click:append="show1 = !show1"
        bg-color="surface"
        class="pwdInput"
      ></v-text-field>

      <div class="d-flex justify-space-between align-center mt-4">
        <div>
          <div>
            <v-btn variant="plain" class="ma-0 pa-0" to="/auth/forgot-pwd">
              {{ $t('login_page.forgot_password') }}
            </v-btn>
          </div>
        </div>
        <v-btn color="blue" @click="validate()" :loading="isSubmitting" variant="flat" size="large" :disabled="isSubmitting">
          {{ $t('login_page.login') }}
        </v-btn>
      </div>
      <div v-if="apiError" class="mt-2">
        <v-alert color="error">{{ apiError }}</v-alert>
      </div>
    </v-form>


    <div class="mt-5 text-center">
      <v-btn variant="plain" to="/auth/register" class="text-capitalize ma-0 pa-0">
        {{ $t('login_page.no_account') }}
      </v-btn>
      <v-btn color="blue" variant="text" to="/auth/register" class="text-capitalize ma-0 pa-0 ml-1">
        {{ $t('login_page.register') }}
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss">
.custom-devider {
  border-color: rgba(0, 0, 0, 0.08) !important;
}
.googleBtn {
  border-color: rgba(0, 0, 0, 0.08);
  margin: 30px 0 20px 0;
}
.outlinedInput .v-field {
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: none;
}
.orbtn {
  padding: 2px 40px;
  border-color: rgba(0, 0, 0, 0.08);
  margin: 20px 15px;
}
.pwdInput {
  position: relative;
  .v-input__append {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.loginForm {
  .v-text-field .v-field--active input {
    font-weight: 500;
  }
}
</style>

import { InstrumentType } from "../enums";
import Peer2PeerConfig from "./PeerToPeer.config";
import BondConfig from "./Bond.config";
import { InstrumentConfig } from "./Instrument.config";
import LoanConfig from "./Loan.config";
import ShareConfig from "./Share.config";
import CryptocurrencyConfig from "./Cryptocurrency.config";
import DepositConfig from "./Deposit.config";
import ETFConfig from "./ETF.config";
import FundConfig from "./Fund.config";
import RealEstateConfig from "./RealEastate";
import EquityConfig from "./Equity.config";
import CashConfig from "./Cash.config";
import CommodityConfig from "./Commodity.config";
import OtherConfig from "./Other.config";
import TakenLoanConfig from "./TakenLoan.config";

// @ts-expect-error
export const instrumentConfig: Record<InstrumentType, InstrumentConfig> = {
  [InstrumentType.OrdinaryShare]: ShareConfig,
  [InstrumentType.ExchangeTradedFund]: ETFConfig,
  [InstrumentType.ManagedFund]: FundConfig,
  [InstrumentType.Bond]: BondConfig,
  [InstrumentType.Deposit]: DepositConfig,
  [InstrumentType.RealEstate]: RealEstateConfig,
  [InstrumentType.Loan]: LoanConfig,
  [InstrumentType.TakenLoan]: TakenLoanConfig,
  [InstrumentType.P2PLoan]: Peer2PeerConfig,
  [InstrumentType.Cryptocurrency]: CryptocurrencyConfig,
  [InstrumentType.Equity]: EquityConfig,
  [InstrumentType.Cash]: CashConfig,
  [InstrumentType.Commodities]: CommodityConfig,
  [InstrumentType.Other]: OtherConfig,
}

<script lang="ts" setup>
import { usePermissions } from '@/utils/permissions.utils';
import { computed, onMounted } from 'vue';

const props = defineProps({
  portfolioId: {
    type: Number,
    required: true
  }
});

const { mappedPermissions, isGivenPermissionsLoading, getAllGivenPermissions } = usePermissions();

const permissions = computed(() => {
  return mappedPermissions.value.filter(permission => permission.portfolio.id === props.portfolioId);
});

onMounted(() => {
  getAllGivenPermissions();
});
</script>

<template>
  <v-row class="pt-2">
    <v-col cols="12">
      {{ $t('portfolio_page.permissions_description') }}
      <RouterLink class="text-blue" :to="{ name: 'permissions' }">{{ $t('portfolio_page.permissions_link') }}</RouterLink>
    </v-col>
    <v-col cols="12">
      <div class="d-flex">
        <v-table>
          <thead>
            <tr class="bg-table-heading-bg">
              <th>{{ $t('label.email') }}</th>
              <th>{{ $t('settings_page.permission.level') }}</th>
              <th>{{ $t('settings_page.permission.status') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isGivenPermissionsLoading">
              <td :colspan="5" class="text-center">
                <v-progress-circular indeterminate color="primary" />
              </td>
            </tr>
            <tr v-else-if="permissions.length" v-for="permission in permissions" :key="permission.id">
              <td class="col">{{ permission.permissionUser.email }}</td>
              <td class="col">{{ permission.levelTitle }}</td>
              <td class="col status" :class="`text-${permission.status.textColour}`">
                <div class="d-flex align-center ga-1">
                  <v-icon v-if="permission.status.icon">{{ permission.status.icon }}</v-icon>
                  {{ permission.status.title }}
                </div>
              </td>
            </tr>
            <tr v-else>
              <td :colspan="5" class="text-center">{{ $t('settings_page.permissions.no_permissions') }}</td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </v-col>
  </v-row>
</template>
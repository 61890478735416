import { Static, Type } from '@sinclair/typebox';
import { InstrumentDto, InvestmentDto, PortfolioDto } from "./common.schema";
import { TransactionType } from "./enums";

export const InstrumentDtoExtended = Type.Intersect([
  InstrumentDto,
  Type.Object({
    portfolios: Type.Array(PortfolioDto),
    investments: Type.Array(InvestmentDto),
    hasActiveInvestments: Type.Union([Type.Boolean(), Type.Null()]),
  })
])

export const CreateInstrumentDto = Type.Intersect([
  Type.Omit(InstrumentDto, ['id', 'displayName', 'uniqueId', 'isPublic']),
])

export const UpdateCustomInstrumentDto = Type.Object({
  id: Type.Integer(),
  name: Type.String(),
  symbol: Type.String(),
  consolidationLabel: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  assetClass: Type.Optional(Type.Union([Type.String(), Type.Null()]))
})

export const ImportDto = Type.Object({
  uuid: Type.String(),
  order: Type.Optional(Type.Integer()),
  portfolioId: Type.Integer(),
  transactionType: Type.Enum(TransactionType),
  referenceId: Type.Optional(Type.String()),
  referenceIds: Type.Optional(Type.Array(Type.String())),
  dateTransactionAt: Type.String({ format: 'date' }),
  description: Type.Optional(Type.String()),
  counterpartyAccount: Type.Optional(Type.String()),
  counterpartyName: Type.Optional(Type.String()),
  account: Type.Object({
    id: Type.Optional(Type.Integer()),
    quantity: Type.Number(),
    currencyCode: Type.Optional(Type.String()),
    netAmount: Type.Optional(Type.Number()),
    grossAmount: Type.Optional(Type.Number()),
    fee: Type.Optional(Type.Number()),
    feeCurrencyCode: Type.Optional(Type.String()),
    tax: Type.Optional(Type.Number()),
    taxCurrencyCode: Type.Optional(Type.String()),
    price: Type.Optional(Type.Number())
  }),
  counterparty: Type.Object({
    id: Type.Optional(Type.Integer()),
    currencyCode: Type.String(),
    netAmount: Type.Number(),
    grossAmount: Type.Number(),
    fee: Type.Optional(Type.Number()),
    feeCurrencyCode: Type.Optional(Type.String()),
    tax: Type.Optional(Type.Number()),
    taxCurrencyCode: Type.Optional(Type.String()),
    price: Type.Number()
  }),
  metadata: Type.Optional(Type.Object({
    costbase: Type.Optional(Type.Number())
  })),
  rawSymbol: Type.String(),
  instrument: Type.Optional(Type.Object({
    id: Type.Integer(),
    symbol: Type.Optional(Type.String()),
    exchange: Type.Optional(Type.String()),
    type: Type.Optional(Type.String()),
  })),
})

export const ImportAccountStatementDto = Type.Object({
  splits: Type.Array(ImportDto)
})

export const SearchInstrumentInputDto = Type.Object({
  keyword: Type.Optional(Type.String()),
  isin: Type.Optional(Type.String()),
  currencyCode: Type.Optional(Type.String()),
  allowAccounts: Type.Optional(Type.Boolean()),
  suggestedInstrumentIds: Type.Optional(Type.Union([Type.String(), Type.Array(Type.Number())])),
  portfolioId: Type.Optional(Type.Integer())
})

export type InstrumentDtoType = Static<typeof InstrumentDto>
export type InstrumentDtoExtendedType = Static<typeof InstrumentDtoExtended>
export type CreateInstrumentDtoType = Static<typeof CreateInstrumentDto>
export type UpdateCustomInstrumentDtoType = Static<typeof UpdateCustomInstrumentDto>
export type ImportDtoType = Static<typeof ImportDto>
export type ImportAccountStatementDtoType = Static<typeof ImportAccountStatementDto>
export type SearchInstrumentInputType = Static<typeof SearchInstrumentInputDto>

export const SearchInstrumentSchema = {
  tags: ['instrument'],
  body: SearchInstrumentInputDto,
  response: {
    200: Type.Array(InstrumentDto)
  }
}

export const UpdateCustomInstrumentSchema = {
  tags: ['instrument'],
  body: UpdateCustomInstrumentDto,
  response: {
    200: InstrumentDtoExtended
  }
}

export const DeleteInstrumentSchema = {
  tags: ['instrument'],
  response: {
    200: Type.Boolean()
  }
}

<template>
  <div class="d-flex flex-column flex-sm-row overflow-hidden">
    <SettingsTabs :tabs="tabs" v-model="tab" />
    <v-tabs-window v-model="tab" style="width:100%;" :class="{'pa-4': true}">
      <slot />
    </v-tabs-window>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import SettingsTabs from './SettingsTabs.vue';

const props = defineProps({
  modelValue: null,
  tabs: {
    default: () => [] as { label: string, value: string, icon: string, disabled: boolean }[]
  }
})

const emit = defineEmits([
  'update:modelValue'
]);

const tab = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});
</script>
import { Static, Type } from '@sinclair/typebox'
import { InstrumentType, TransactionType } from './enums'
import { InvestmentDto, PaginatedResponseDto, currencyCodeType, TransactionDto, FixedRateInstrumentConfigDto } from './common.schema'
import { CreateInstrumentDto } from './instrument.schema'
import { CreateCashAccountTransactionDto } from './cash-account.schema'

export const InvestmentTransactionDto = Type.Intersect([TransactionDto, Type.Object({
  transactionType: Type.Enum(TransactionType),
  fee: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
  feeCurrencyCode: currencyCodeType,
  tax: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
  taxCurrencyCode: currencyCodeType,
  metadata: Type.Optional(Type.Object({
    priceAtOpeningBalanceDate: Type.Optional(Type.Number()),
  })),
})])

export const InvestmentTransactionsDto = Type.Intersect([PaginatedResponseDto, Type.Object({
  items: Type.Array(InvestmentTransactionDto)
})])


export const CreateInvestmentTransactionDto = Type.Intersect([
  Type.Omit(InvestmentTransactionDto, ['id', 'relatedTransactionId', 'relatedHolding', 'isIncome', 'balance', 'totalValue', 'baseCurrencyCode']), 
  Type.Object({
    relatedHoldingId: Type.Optional(Type.Union([Type.Null(), Type.Number()])),
    cashAccountCurrencyCode: Type.Optional(Type.String()),
    amount: Type.Optional(Type.Integer()),
  })
])

export const CreateInvestmentDto = Type.Intersect([
  Type.Omit(InvestmentDto, ['id', 'type', 'instrument', 'instrumentId']),
  Type.Object({
    instrumentType: Type.Optional(Type.Enum(InstrumentType)),
    instrumentId: Type.Optional(Type.Union([Type.Null(), Type.Number()])),
    instrument: Type.Optional(CreateInstrumentDto),
    transaction: Type.Optional(CreateCashAccountTransactionDto),
    fixedRateInstrumentConfig: Type.Optional(FixedRateInstrumentConfigDto),
    customGroups: Type.Optional(Type.Array(Type.String()))
  })
])

export const UpdateInvestmentDto = Type.Intersect([
  Type.Intersect([
    Type.Omit(InvestmentDto, ['id', 'type', 'instrument', 'instrumentId']),
    Type.Object({
      instrumentType: Type.Optional(Type.Enum(InstrumentType)),
      fixedRateInstrumentConfig: Type.Optional(FixedRateInstrumentConfigDto),
      customGroups: Type.Optional(Type.Array(Type.String()))
    })
  ])
])

export const IncomeTransactionDto = Type.Intersect([Type.Omit(TransactionDto, ['price', 'balance', 'totalValue', 'baseCurrencyCode']), Type.Object({
  transactionType: Type.Enum(TransactionType),
  fee: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
  tax: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
  taxCurrencyCode: Type.Optional(Type.Union([Type.String(), Type.Null()])),
})])

export const CreateIncomeTransactionDto = Type.Intersect([
  Type.Omit(IncomeTransactionDto, ['id', 'relatedTransactionId', 'relatedHolding', 'isIncome']),
  Type.Object({
    cashAccountCurrencyCode: Type.Optional(Type.String())
  })
])

export const IncomeTransactionsDto = Type.Intersect([PaginatedResponseDto, Type.Object({
  items: Type.Array(IncomeTransactionDto)
})])

export const MoveInvestmentDto = Type.Object({
  instrumentId: Type.Integer()
})

export const MoveInvestmentPortfolioDto = Type.Object({
  portfolioId: Type.Integer()
})

export type InvestmentDtoType = Static<typeof InvestmentDto>
export type InvestmentCreateDtoType = Static<typeof CreateInvestmentDto>
export type InvestmentUpdateDtoType = Static<typeof UpdateInvestmentDto>
export type InvestmentTransactionDtoType = Static<typeof InvestmentTransactionDto>
export type CreateInvestmentTransactionDtoType = Static<typeof CreateInvestmentTransactionDto>
export type InvestmentTransactionsDtoType = Static<typeof InvestmentTransactionsDto>
export type IncomeTransactionDtoType = Static<typeof IncomeTransactionDto>
export type CreateIncomeTransactionDtoType = Static<typeof CreateIncomeTransactionDto>
export type IncomeTransactionsDtoType = Static<typeof IncomeTransactionsDto>
export type MoveInvestmentDtoType = Static<typeof MoveInvestmentDto>
export type MoveInvestmentPortfolioDtoType = Static<typeof MoveInvestmentPortfolioDto>

export const GetInvestmentSchema = {
  tags: ['investment'],
  response: {
    200: InvestmentDto
  }
}

export const GetInvestmentsSchema = {
  tags: ['investment'],
  response: {
    200: Type.Array(InvestmentDto)
  }
}

export const CreateInvestmentSchema = {
  tags: ['investment'],
  body: CreateInvestmentDto,
  response: {
    200: InvestmentDto
  }
}

export const UpdateInvestmentSchema = {
  tags: ['investment'],
  body: UpdateInvestmentDto,
  response: {
    200: InvestmentDto
  }
}

export const DeleteInvestmentSchema = {
  tags: ['investment'],
  response: {
    200: Type.Boolean()
  }
}

export const CreateInvestmentTransactionSchema = {
  tags: ['investment'],
  body: CreateCashAccountTransactionDto,
  response: {
    200: CreateCashAccountTransactionDto
  }
}

export const ImportInvestmentTransactionsSchema = {
  tags: ['investment'],
  body: Type.Array(CreateInvestmentTransactionDto),
  response: {
    200: Type.Array(InvestmentTransactionDto)
  }
}

export const UpdateInvestmentTransactionSchema = {
  tags: ['investment'],
  body: CreateCashAccountTransactionDto,
  response: {
    200: CreateCashAccountTransactionDto
  }
}

export const GetInvestmentTransactionSchema = {
  tags: ['investment'],
  response: {
    200: CreateCashAccountTransactionDto
  }
}

export const DeleteInvestmentTransactionSchema = {
  tags: ['investment'],
  response: {
    200: Type.Boolean()
  }
}

export const GetInvestmentTransactionsSchema = {
  tags: ['investment'],
  response: {
    200: InvestmentTransactionsDto
  }
}

export const CreateIncomeTransactionSchema = {
  tags: ['investment'],
  body: CreateCashAccountTransactionDto,
  response: {
    200: CreateCashAccountTransactionDto
  }
}

export const GetIncomeTransactionsSchema = {
  tags: ['investment'],
  response: {
    200: IncomeTransactionsDto
  }
}

export const GetIncomeTransactionSchema = {
  tags: ['investment'],
  response: {
    200: CreateCashAccountTransactionDto
  }
}

export const MoveInvestmentSchema = {
  tags: ['investment'],
  response: {
    200: Type.Boolean()
  }
}

export const SyncWithAccountSchema = {
  tags: ['investment'],
  body: Type.Object({
    accountId: Type.Integer()
  }),
  response: {
    200: Type.Boolean()
  }
}

export const MoveInvestmentPortfolioSchema = {
  tags: ['investment'],
  response: {
    200: Type.Boolean()
  }
}

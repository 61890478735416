<script setup lang="ts">
import { watch } from 'vue';
import TransactionForm from './TransactionForm.vue';
import { useAccountStore } from '@/stores/accountStore';
import { useRoute } from 'vue-router';

const accountStore = useAccountStore();
const route = useRoute();

watch(() => route.params.transactionId, (transactionId) => {
  if (!transactionId) {
    return;
  }
  accountStore.getCashAccountTransaction(route.params.accountId as string, transactionId as string);
}, { immediate: true });
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-card variant="flat">
        <transaction-form action="update" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore';
import { computed, onMounted } from 'vue';
import { ref } from 'vue';
import { SubscriptionTier } from '../../../backend/src/enums';
import { useI18n } from 'vue-i18n';
import type { UserSubscriptionDtoType } from '~/user.schema';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';

const props = defineProps({
  variant: {
    type: String,
    default: 'full'
  },
  isAnnual: {
    type: Boolean,
    default: true
  }
});

const authStore = useAuthStore();

const isLoading = ref(false);
const subscriptionInfo = ref<UserSubscriptionDtoType>(null);
const me = computed(() => authStore.user);
const { t } = useI18n();
const isCompact = computed(() => props.variant === 'compact');

const plans = computed(() => ([
  {
    tier: SubscriptionTier.Free,
    color: 'primary-lighten-5',
    colorLight: 'primary-lighten-5',
    button: {},
    active: false,
    title: t('plans.free.title'),
    price: 0,
    priceMonthly: 0,
    items: [
      t('subscription.features.portfolios', 1, { count: 1 }),
      t('subscription.features.investments', 10, { count: 10 }),
      t('subscription.features.bank_connections', 0, { count: 0 }),
      t('subscription.features.custom_groups', 1, { count: 1 }),
      !isCompact ? t('subscription.features.reports_some') : undefined,
      !isCompact ? t('subscription.features.support_some') : undefined
    ],
    permission: [0, 1],
    isComingSoon: false,
    trial: false,
  },
  {
    tier: SubscriptionTier.Starter,
    color: 'primary-lighten-4',
    colorLight: 'primary-lighten-4',
    active: false,
    title: t('plans.starter.title'),
    price: 5,
    priceMonthly: 7,
    items: [
      t('subscription.features.portfolios', 2, { count: 2 }),
      t('subscription.features.investments', 30, { count: 30 }),
      t('subscription.features.bank_connections', 4, { count: 4 }),
      t('subscription.features.custom_groups', 3, { count: 3 }),
      !isCompact.value ? t('subscription.features.reports_some') : undefined,
      !isCompact.value ? t('subscription.features.support_regular') : undefined
    ],
    permission: [0, 1, 2, 3],
    button: {},
    isComingSoon: false,
    trial: subscriptionInfo.value?.trialAllowed
  },
  {
    tier: SubscriptionTier.Investor,
    color: 'primary-lighten-2',
    colorLight: 'primary-lighten-3',
    active: true,
    title: t('plans.investor.title'),
    price: 10,
    priceMonthly: 14,
    items: [
      t('subscription.features.portfolios', 4, { count: 4 }),
      t('subscription.features.investments_unlimited'),
      t('subscription.features.bank_connections', 8, { count: 8 }),
      t('subscription.features.custom_groups', 5, { count: 5 }),
      !isCompact.value ? t('subscription.features.reports_most') : undefined,
      !isCompact.value ? t('subscription.features.support_regular') : undefined,
      !isCompact.value ? t('subscription.features.consolidated_views') : undefined
    ],
    permission: [0, 1, 2, 3, 5],
    button: {},
    isComingSoon: false,
    isPopular: true,
    trial: subscriptionInfo.value?.trialAllowed
  },
  {
    tier: SubscriptionTier.Expert,
    color: 'primary-lighten-2',
    colorLight: 'primary-lighten-1',
    active: false,
    title: t('plans.expert.title'),
    items: [
      t('subscription.features.portfolios', 10, { count: 10 }),
      t('subscription.features.investments_unlimited'),
      t('subscription.features.bank_connections', 16, { count: 16 }),
      t('subscription.features.custom_groups', 10, { count: 10 }),
      !isCompact.value ? t('subscription.features.reports_all') : undefined,
      !isCompact.value ? t('subscription.features.support_priority') : undefined,
      !isCompact.value ? t('subscription.features.consolidated_views') : undefined
    ],
    price: 25,
    priceMonthly: 29,
    permission: [0, 1, 2, 3, 5],
    button: {},
    isComingSoon: false,
    trial: subscriptionInfo.value?.trialAllowed
  }
].map((plan) => {
  const isActive = me.value.subscription.tier === plan.tier;
  let buttonText = t('subscription.order');

  if (isActive) {
    buttonText = t('subscription.active');
  } else if (plan.price <= 0) {
    buttonText = t('subscription.choose');
  } else if (plan.trial) {
    buttonText = t('subscription.try');
  }

  plan.button = {
    text: buttonText,
    disabled: isActive,
  }
  return plan;
})));

const upgrade = (plan: any) => {
  isLoading.value = true;
  fetchWrapper.post(`/api/v1/stripe/checkout?plan=${plan.tier}&annual=${props.isAnnual}`)
    .then((response) => {
      if (response.url) {
        document.location.href = response.url;
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
};

onMounted(async () => {
  try {
    subscriptionInfo.value = await authStore.getSubscriptionInfo({ purpose: 'choose-package' })
  } catch {

  } 
});
</script>

<template>
  <v-row>
    <v-col cols="12" sm="6" md="3" v-for="(plan, index) in plans" v-bind:key="index" class="d-flex align-stretch">
      <DisplayCard :bg='`bg-${plan.colorLight}`' :class="{popular: plan.isPopular}" style="overflow:hidden;">
        <v-chip class="popular-label" color="green" v-if="plan.isPopular">
          {{ $t('subscription.popular') }}
        </v-chip>
        <v-card-text class="text-center d-flex flex-column stretch justify-space-between pb-0" :class="{'px-2': isCompact}" style="height:100%;">
          <div>
            <h1 class="PlanTitle">{{ plan.title }}</h1>
            <div :style="`min-height:${isCompact ? '100' : '120'}px;`">
              <div class="d-flex flex-column align-center ga-2" :class="{'mt-5': isCompact, 'mt-10': !isCompact}">
                <h2 class="PriceFont">{{ isAnnual ? plan.price : plan.priceMonthly }} €</h2>
                <span v-if="isAnnual && plan.price > 0" style="line-height: 1em;" class="ml-3 text-center text-caption">
                  {{ $t('subscription.paid_monthly_monthly') }}
                </span>
              </div>
              <div class="text-center font-weight-bold mt-2 mb-4 pl-1">
                <template v-if="!isAnnual && plan.price > 0">
                  {{ plan.price }} € {{ $t('subscription.paid_annually') }}
                </template>
                <template v-else-if="isAnnual && plan.price > 0">
                  {{ plan.priceMonthly }} € {{ $t('subscription.paid_monthly') }}
                </template>
                <template v-else>
                  <div>{{ $t('subscription.free_forever') }}</div>
                </template>
              </div>
            </div>
            <v-btn
              variant="flat"
              :disabled="plan.button.disabled || isLoading"
              :color="`blue`"
              @click="upgrade(plan)"
              :loading="isLoading"
              size="large"
              style="min-width: 150px;"
              class="mt-4">
                <span class="text-uppercase">{{ plan.button.text }}</span>
            </v-btn>
            <div class="text-caption mt-3" v-if="plan.trial">
              <strong>
                {{ $t('subscription.trial', {
                  count: subscriptionInfo?.trialDays,
                }) }}
              </strong> {{ $t('subscription.trial_description') }}
            </div>
            <div class="text-caption mt-3" v-else-if="subscriptionInfo?.trialAllowed">
              <br />
            </div>
            <v-divider :class="`mt-5 bg-${plan.color}`"></v-divider>
            <v-list class="text-left" :class="`bg-${plan.colorLight}`">
              <v-list-item
                v-for="(item, index) in plan.items.filter(item => !!item)"
                :key="index"
                class="d-flex align-center px-0"
                style="min-height: auto;"
                density="compact"
              >
                <v-icon :size="isCompact ? 16 : 24">mdi-check</v-icon>
                {{ item }}
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
        <v-overlay
          v-if="plan.isComingSoon"
          :model-value="true"
          class="align-center justify-center"
          contained
          scrim="black"
          persistent
          >
            <v-chip color="amber" class="font-weight-medium" variant="elevated">
              <v-icon class="mr-2">mdi-clock-time-four-outline</v-icon>
              {{ $t('subscription.coming_soon') }}
            </v-chip>
          </v-overlay>
      </DisplayCard>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
.PlanTitle {
  position: relative;
  font-size: 1.8rem;
  margin: 15px 0;
}
.PriceFont {
  font-size: 4rem;
  line-height: 3rem;
  letter-spacing: -0.3rem;
}
.withBorder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.circleIcon {
  background-color: rgb(var(--v-theme-lightprimary));
  width: 80px;
  height: 80px;
  margin: 0 auto;
  line-height: 80px;
  color: rgb(var(--v-theme-primary));
  border-radius: 100%;
  margin-bottom: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.popular {
  background: linear-gradient(rgb(var(--v-theme-primary-lighten-3)), rgb(var(--v-theme-primary-lighten-3))) padding-box, linear-gradient(to right, #3699ff, #8950fc) border-box !important;
  border: 2px solid transparent !important;
  overflow: visible !important;
}

.popular-label {
  background: linear-gradient(to right, #3699ff, #8950fc) padding-box!important;
  color: #fff !important;
  font-weight: 600;
  font-size: 12px;
  border-radius: 5px;
  height: auto;
  padding: 5px;
  position: absolute;
  top: -14px;
  right: 5%;
}
</style>

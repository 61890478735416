<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { TransactionType } from '~/enums';
import { icons } from '@/const';

const props = defineProps({
  value: null,
  instrumentType: null,
  transaction: null,
  isLoan: {
    type: Boolean,
    default: false
  },
  isCashflow: {
    type: Boolean,
    default: false
  },
  hideIcon: {
    type: Boolean,
    default: false
  },
  upperCase: {
    type: Boolean,
    default: true
  }
})

const { t, te } = useI18n();

const getBroadKey = (key: string) => {
  return `instrumentType.${props.instrumentType}.${key}`
}

const mappedType = computed(() => {
  return props.transaction.relatedTransactionType ?? props.value
})

const value = computed(() => {
  const txType = props.isLoan ? 'loanTransactionType' : (props.isCashflow ? 'cashflowTransactionType' : 'transactionType')
  const type = mappedType.value
  const key = `${txType}.${type}.title`
  const keyShort = `${txType}.${type}.title_short`

  const broadKey = getBroadKey(key)
  const broadKeyShort = getBroadKey(keyShort)

  if (te(broadKeyShort)) {
    return t(broadKeyShort)
  } else if (te(broadKey)) {
    return t(broadKey)
  } else if (te(keyShort)) {
    return t(keyShort)
  } else {
    return t(key)
  }
})

const iconsMap = {
  [TransactionType.Split]: {
    icon: 'mdi-set-split',
    color: 'amber-darken-3'
  },
  [TransactionType.Conversion]: {
    icon: 'mdi-cash-sync',
    color: 'amber-darken-3'
  },
  [TransactionType.Deposit]: {
    icon: 'mdi-arrow-right-thin-circle-outline',
    color: 'success'
  },
  [TransactionType.LoanPrincipalPaid]: {
    icon: 'mdi-arrow-right-thin-circle-outline',
    color: 'primary'
  },
  [TransactionType.Withdraw]: {
    icon: 'mdi-arrow-left-thin-circle-outline',
    color: 'error'
  },
  [TransactionType.Fee]: {
    icon: 'mdi-cash-minus',
    color: 'error'
  },
  [TransactionType.Expenses]: {
    icon: 'mdi-cash-minus',
    color: 'error'
  },
  [TransactionType.Transfer]: {
    icon: 'mdi-cash-multiple',
    color: 'amber-darken-3'
  },
  [TransactionType.Writeoff]: {
    icon: 'mdi-cash-remove',
    color: 'error'
  },
  [TransactionType.InterestPaid]: {
    icon: 'mdi-cash-minus',
    color: 'error'
  },
  [TransactionType.OptionsIncome]: {
    icon: 'mdi-cash-plus',
    color: 'success'
  },
  [TransactionType.RentalIncome]: {
    icon: 'mdi-cash-plus',
    color: 'success'
  },
  [TransactionType.Tax]: {
    icon: 'mdi-cash-minus',
    color: 'error'
  },
  ...icons
}

const icon = computed(() => {
  if (props.hideIcon) {
    return false
  }
  return iconsMap[mappedType.value] ?? false
})

</script>

<template>
  <div class="d-flex align-center">
    <v-icon :icon="icon.icon" :color="icon.color" class="mr-1" />
    <span class="transaction-type" :class="{uppercase: props.upperCase}">{{ value }}</span> 
  </div>
</template>

<style lang="scss" scoped>
  .transaction-type {
    font-weight: bold;
    font-weight: 300;
    white-space: nowrap;

    &.uppercase {
      text-transform: uppercase;
    }
  }
</style>
<script setup lang="ts">
import { percentFormatter, currencyFormatter } from '@/const';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import ValueWithArrow from './shared/ValueWithArrow.vue';
import UpgradeLock from './UpgradeLock.vue';

const { t } = useI18n();

const props = defineProps({
  currencyCode: {
    type: String,
    required: false
  },
  performance: {
    type: null,
    required: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: 'primary'
  },
  isPartial: {
    type: Boolean,
    default: false
  }
});

const summaryStats = computed(() => {
  const performance = props.performance ?? {}
  const currencyCode = props.currencyCode ?? ''

  return [{
    title: t('performance.capital_gain'),
    rawValue: performance?.capitalGain ?? 0,
    rawReturn: performance?.capitalReturn ?? 0,
    value: () => currencyFormatter(performance?.capitalGain ?? 0, currencyCode),
    return: percentFormatter(Math.abs(performance?.capitalReturn ?? 0)),
    direction: performance?.capitalReturn > 0 ? 'up' : (performance?.capitalReturn < 0 ? 'down' : 'none')
  }, {
    title: t('performance.fee_gain'),
    rawValue: performance?.feesGain ?? 0,
    rawReturn: performance?.feesReturn ?? 0,
    value: () => currencyFormatter(performance?.feesGain ?? 0, currencyCode),
    return: percentFormatter(Math.abs(performance?.feesReturn ?? 0)),
    direction: performance?.feesReturn > 0 ? 'up' : (performance?.feesReturn < 0 ? 'down' : 'none')
  }, {
    title: t('performance.income_gain'),
    rawValue: performance?.cashflowGain ?? 0,
    rawReturn: performance?.cashflowReturn ?? 0,
    value: () => currencyFormatter(performance?.cashflowGain ?? 0, currencyCode),
    return: percentFormatter(Math.abs(performance?.cashflowReturn ?? 0)),
    direction: performance?.cashflowReturn > 0 ? 'up' : (performance?.cashflowReturn < 0 ? 'down' : 'none')
  }, {
    title: t('performance.currency_gain'),
    rawValue: performance?.currencyGain ?? 0,
    rawReturn: performance?.currencyReturn ?? 0,
    value: () => currencyFormatter(performance?.currencyGain ?? 0, currencyCode),
    return: percentFormatter(Math.abs(performance?.currencyReturn ?? 0)),
    direction: performance?.currencyReturn > 0 ? 'up' : (performance?.currencyReturn < 0 ? 'down' : 'none')
  }, {
    title: t('performance.total_gain'),
    rawValue: performance?.totalGain ?? 0,
    rawReturn: performance?.totalReturn ?? 0,
    value: () => currencyFormatter(performance?.totalGain ?? 0, currencyCode),
    return: percentFormatter(Math.abs(performance?.totalReturn ?? 0)),
    direction: performance?.totalReturn > 0 ? 'up' : (performance?.totalReturn < 0 ? 'down' : 'none'),
    bold: true
  }]
})

const isAnnualized = computed(() => {
  return props.performance?.isAnnualized ?? false
})
</script>

<template>
  <v-card elevation="4" :class="`bg-${color} d-flex align-stretch ${$vuetify.display.xs ? 'overflow-x-auto overflow-y-hidden' : ''}`" variant="outlined">
    <v-overlay :model-value="loading" scrim="#FFF" contained persistent />
    <v-row dense class="ga-2 pa-4 flex-nowrap">
      <v-col cols="3" sm="" class="pa-0 d-flex" v-for="(stat, i) in summaryStats" :key="i" dense>
        <v-card-text class="text-center stats d-flex justify-center flex-column pa-0">
          <div class="d-flex justify-center" style="font-size:20px;">
            <value-with-arrow
              :value="stat.rawValue"
              :arrow="false"
              :class="{'font-weight-light': !stat.bold, 'font-weight-medium': stat.bold}"
              :color-text="false"
              :currency="currencyCode"
            />
            <UpgradeLock v-if="isPartial" color="red" size="16" icon="mdi-exclamation" />
          </div>
          <div class="mt-1 font-weight-light d-flex justify-center">
            <value-with-arrow
              :value="stat.rawReturn"
              :arrow="true"
              :class="{'font-weight-light': !stat.bold, 'font-weight-medium': stat.bold}"
              :color-text="false"
            />
            <sup v-if="isAnnualized" class="font-weight-light text-caption">p.a.</sup>
          </div>
          <span class="mt-1  text-subtitle-1 font-weight-medium">{{ stat.title }}</span>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>
.stats {
  white-space: nowrap;
}
</style>

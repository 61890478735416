<script setup lang="ts">
import { ref, computed } from 'vue';
import { useInvestmentStore } from '@/stores/investmentStore';
import * as I from '~/investment.schema';
import FormContainer from '@/components/shared/FormContainer.vue';
import AccountSearchField from "@/components/inputs/AccountSearchField.vue";
import { fieldDesign } from '@/const';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { useAppStore } from '@/stores/app';

const emit = defineEmits(['update:modelValue', 'update:isValid', 'close']);
const props = defineProps(['action', 'investment', 'modelValue', 'isValid'])

const investmentStore = useInvestmentStore();
const appStore = useAppStore();

const formRef = ref(null);
const isLoading = ref(false);

const investment = computed(() => {
  return props.investment ?? investmentStore.investment as I.InvestmentDtoType;
})

const isValid = computed({
  get() {
    return props.isValid;
  },
  set(value) {
    emit('update:isValid', value);
  }
})

const form = ref<{ accountId?: number }>({
  accountId: undefined
})

const onSubmit = async () => {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;
  try {
    await fetchWrapper.post(`/api/v1/investment/${investment.value.id}/sync-with-account`, form.value);
    appStore.showNotice('Tehingud on edukalt sünkroniseeritud');   
    emit('close');
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <FormContainer
    color="primary"
    v-model="isValid"
    ref="formRef"
    title="Seo tehingud rahakontoga"
    mode="confirm"
    @submit="onSubmit"
    :is-form-loading="false"
    :is-button-loading="isLoading"
    :action="action"
  >
    <v-row>
      <v-col cols="12">
        See tegevus seob kõik selle investeeringuga seotud tehingud valitud rahakontoga.
        Muudetakse ainult neid tehinguid, mida pole veel ühegi rahakontoga seotud.
        Tegevuse tulemusel luuakse valitud rahakontole vastavad rahaliikumised.
      </v-col>
      <v-col cols="12">
        <AccountSearchField
          :fieldDesign="fieldDesign"
          v-model="form.accountId"
          :label="'Rahakonto'"
        />
      </v-col>
    </v-row>
  </FormContainer>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import ValueWithArrow from '@/components/shared/ValueWithArrow.vue';
import { useReport } from '@/report.helper';
import Avatar from '@/components/Avatar.vue';

const props = defineProps({
  report: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  isPercent: {
    type: Boolean,
    default: true,
  }
})

const { topN, bottomN } = useReport(computed(() => props.report));

const key = computed(() => props.isPercent ? 'totalReturn' : 'totalGain');
const subtitleKey = computed(() => !props.isPercent ? 'totalReturn' : 'totalGain');

const list = computed(() => {
  if (props.type === 'top') {
    return topN(5, key.value)
  }
  return bottomN(5, key.value)
});

</script>

<template>
  <v-list v-if="list.length > 0">
    <v-list-subheader class="font-weight-bold">{{ title }}</v-list-subheader>
    <v-list-item
      v-for="(investment, k) in list"
      :key="k"
      class="my-1"
    >
      <template v-slot:prepend>
        <Avatar :code="investment.code" :logo="investment?.logo" :size="32" />
      </template>
      <template v-slot:title>
        <Investment :holding="investment" flavour="name" />
      </template>
      <template v-slot:subtitle>
        <ValueWithArrow
          :value="investment.openAndClosedPositions[subtitleKey]"
          :arrow="true"
          :currency="!isPercent ? undefined : investment.baseCurrencyCode"
          :color-text="false"
        />
      </template>
      <template v-slot:append>
        <ValueWithArrow
          :value="investment.openAndClosedPositions[key]"
          :arrow="true"
          :currency="isPercent ? undefined : investment.baseCurrencyCode"
        />
      </template>
    </v-list-item>
  </v-list>
  <div v-else class="px-5 pb-5">
    <v-skeleton-loader
      :boilerplate="!isLoading"
      type="list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar"
    />
  </div>
</template>
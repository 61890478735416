import { InstrumentType, TransactionType } from "../enums";
import { ShareConfig } from "./Share.config";

class EtfConfig extends ShareConfig {
  type: InstrumentType = InstrumentType.ExchangeTradedFund;

  canBeMigratedToPublic: boolean = true

  allowedTransactionTypes = [
    TransactionType.Buy,
    TransactionType.Sell,
    TransactionType.Opening,
    TransactionType.Split,
    TransactionType.Fee,
  ]

  allowedIncomeTypes = [
    TransactionType.Dividend,
    TransactionType.OptionsIncome,
    TransactionType.Tax,
  ]
}

export default new EtfConfig();

import FullLayout from '@/layouts/full/FullLayout.vue';

const CategoryRoutes = {
  path: '/group',
  meta: {
    requiresAuth: true
  },
  component: FullLayout,
  children: [
    {
      name: 'list-groups',
      path: 'list',
      component: () => import('@/views/portfolio/groups/ListGroups.vue'),
      meta: {
        title: 'List groups'
      }
    },
    {
      name: 'manage-group',
      path: ':groupId/manage',
      component: () => import('@/views/portfolio/groups/ManageGroup.vue'),
      meta: {
        title: 'Manage group'
      }
    }
  ]
};

export default CategoryRoutes;

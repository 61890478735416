<template>
  <VOnboardingWrapper
    ref="wrapper"
    :steps="steps"
    @finish="onEnd()"
    @exit="onEnd()"
    :options="{
    labels: {
      previousButton: $t('label.back'),
      nextButton: $t('label.next'),
      finishButton: $t('label.finish'),
    }
  }"/>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding';
import 'v-onboarding/dist/style.css';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '@/stores/app';


const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();
const app = useAppStore();

const props = defineProps({
  openMenu: {
    type: Function,
    required: true
  },
  modelValue: {
    type: Boolean,
    required: true
  }
})

const item = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const wrapper = ref(null);
const { start } = useVOnboarding(wrapper);

const steps = [
  {
    attachTo: { element: '#portfolio-summary' },
    content: { 
      title: t('onboarding.guide.great'),
      description: t('onboarding.guide.summary')
    }
  },
  {
    attachTo: { element: '#portfolio-dashboard' },
    content: { 
      title: t('onboarding.guide.overview'),
      description: t('onboarding.guide.overview_description')
    }
  },
  {
    attachTo: { element: '#portfolio-portfolio' },
    content: { 
      title: t('onboarding.guide.portfolio'),
      description: t('onboarding.guide.portfolio_description')
    }
  },
  {
    attachTo: { element: '#portfolio-tools' },
    content: { 
      title: t('onboarding.guide.tools'),
      description: t('onboarding.guide.tools_description')
    }
  },
  {
    attachTo: { element: '#portfolio-reports' },
    content: { 
      title: t('onboarding.guide.reports'),
      description: t('onboarding.guide.reports_description')
    },
    on: {
      beforeStep: function () {
        app.openToolsMenu();
      }
    }
  },
  {
    attachTo: { element: '#portfolio-accounts' },
    content: { 
      title: t('onboarding.guide.accounts'),
      description: t('onboarding.guide.accounts_description')
    }
  },
  {
    attachTo: { element: '#portfolio-instruments' },
    content: { 
      title: t('onboarding.guide.instruments'),
      description: t('onboarding.guide.instruments_description')
    }
  },
  {
    attachTo: { element: '#portfolio-settings' },
    content: { 
      title: t('onboarding.guide.settings'),
      description: t('onboarding.guide.settings_description')
    }
  },
  {
    attachTo: { element: '#performance-period-select' },
    content: { 
      title: t('onboarding.guide.period'),
      description: t('onboarding.guide.period_description')
    }
  },
  {
    attachTo: { element: '#performance-group-by-select' },
    content: { 
      title: t('onboarding.guide.group_by'),
      description: t('onboarding.guide.group_by_description')
    }
  },
  {
    attachTo: { element: '#monetary-value-toggle' },
    content: { 
      title: t('onboarding.guide.money_vs_percentage'),
      description: t('onboarding.guide.money_vs_percentage_description')
    }
  },
  { 
    attachTo: { element: '#add-new-transaction' },
    content: {
      title: t('onboarding.guide.add_transaction'),
      description: t('onboarding.guide.add_transaction_description')
    }
  },
  { 
    attachTo: { element: '#portfolio-visible-columns' },
    content: {
      title: t('onboarding.guide.visible_columns'),
      description: t('onboarding.guide.visible_columns_description')
    }
  }
]

const onEnd = () => {
  window.localStorage.setItem('onboarding', 'true');
}

onMounted(() => {
  const hasJustCreatedFirstPortfolio = window.localStorage.getItem('_firstPortfolio') === 'true';
  const hasSeenOnboarding = window.localStorage.getItem('onboarding') === 'true';

  if ((!hasJustCreatedFirstPortfolio || hasSeenOnboarding)) {
    item.value = false;
    return;
  }
  item.value = true;
  start()
})
</script>

<style lang="scss">
.v-onboarding-item {
  margin-top: 10px !important;
}

[data-v-onboarding-wrapper] [data-popper-placement^='bottom'] > [data-popper-arrow] {
  top: 5px;
}
</style>

<template>
  <HoverHelpIcon
    :helpText="tooltipText"
    icon="mdi-police-badge-outline"
    :color="color"
    :size="size"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  size: {
    type: String,
    default: '20'
  },
  color: {
    type: String,
    default: 'amber'
  },
  icon: {
    type: String,
    default: 'mdi-crown-outline'
  },
  tooltip: {
    type: String,
  }
});

const tooltipText = computed(() => {
  return props.tooltip || t('tooltip.no_price_policy');
});
</script>

import FullLayout from '@/layouts/full/FullLayout.vue';
import ListCustomInstruments from '@/views/instrument/ListCustomInstruments.vue';
import EditInstrument from '@/views/instrument/EditInstrument.vue';

const InstrumentRoutes = {
  path: '/instrument',
  meta: {
    requiresAuth: true
  },
  component: FullLayout,
  children: [
    {
      name: 'all-instruments',
      path: '',
      component: ListCustomInstruments,
      meta: {
        title: 'All instruments'
      }
    },
    {
      name: 'instrument',
      path: ':instrumentId/settings',
      component: EditInstrument,
      meta: {
        title: 'Instrument settings'
      }
    }
  ]
};

export default InstrumentRoutes;

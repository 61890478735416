<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRouter } from 'vue-router';

const props = defineProps({
  canLeave: {
    type: Boolean,
    default: false
  },
  onLeave: {
    type: Function,
    default: () => Promise.resolve(true)
  },
  title: {
    type: String,
    required: false
  },
  message: {
    type: String,
    required: false
  }
});

const router = useRouter();
const { t } = useI18n();

const isConfirmLeaveVisible = ref(false);
const nextRoute = ref<any>(null);

onBeforeRouteLeave(async (to, from, next) => {
  if (isConfirmLeaveVisible.value && nextRoute.value) {
    isConfirmLeaveVisible.value = false;
    return next();
  }
  nextRoute.value = to;
  if (!props.canLeave) {
    const decision = await props.onLeave();
    if (decision) {
      isConfirmLeaveVisible.value = true;
    }
    next(false);
  } else {
    next();
  }
});

const confirmLeave = () => {
  router.push(nextRoute.value);
}

onMounted(() => {
  window.onbeforeunload = function() {
    if (!props.canLeave) {
      return props.message || t('label.confirm_leave_message')
    };
  };
});

onBeforeUnmount(() => {
  window.onbeforeunload = null;
});
</script>

<template>
  <Dialog
    v-model:model-visible="isConfirmLeaveVisible"
    :title="title || $t('label.confirm_leave')"
    :confirm="{show: true, label: $t('label.confirm'), color: 'primary'}"
    @confirm="confirmLeave"
  >
    {{ message || $t('label.confirm_leave_message') }}
  </Dialog>
</template>
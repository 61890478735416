import { useWaterfallChart, useTreemapChart, useAllocationChart } from "@/chart.helper";
import { useAuthStore } from "@/stores/authStore";
import { ref, computed, type Ref, watch } from "vue";

export const allocationChartTypes = [{
  title: 'mdi-chart-pie-outline',
  value: 'pie'
}, {
  title: 'mdi-chart-tree',
  value: 'tree'
}, {
  title: 'mdi-chart-waterfall',
  value: 'waterfall'
}];

export const useDiversityChart = (groupsFlattened: Ref<any>, chartInput: any) => {
  const authStore = useAuthStore();

  const { chartOptions: allocationChartOptions } = useAllocationChart(groupsFlattened, chartInput);
  const { chartOptions: waterfallChartOptions } = useWaterfallChart(groupsFlattened, chartInput);
  const { chartOptions: treeMapChartOptions } = useTreemapChart(groupsFlattened, chartInput);

  const chart = computed(() => {
    if (chartType.value === 'pie') {
      return allocationChartOptions.value;
    } else if (chartType.value === 'tree') {
      return treeMapChartOptions.value;
    } else {
      return waterfallChartOptions.value;
    }
  });

  const chartType = ref<'pie' | 'waterfall' | 'tree'>(authStore.getUserPreference(`assetAllocation.chartType`, 'pie'));

  watch(() => chartType.value, (value) => {
    authStore.setUserPreference(`assetAllocation.chartType`, value);
  });

  return {
    chart,
    chartType
  };
};
<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useInvestmentStore } from '@/stores/investmentStore';
import { computed, ref, watch } from 'vue';
import type { IncomeTransactionDtoType } from '~/investment.schema';
import PriceCell from '@/components/table/PriceCell.vue';
import { useI18n } from 'vue-i18n';
import DateCell from '@/components/table/DateCell.vue';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import { usePagination, useFilters } from '@/filter.helper';
import { onMounted } from 'vue';
import { usePortfolioStore } from '@/stores/portfolioStore';

const router = useRouter();
const investmentStore = useInvestmentStore();
const portfolioStore = usePortfolioStore();
const { t } = useI18n();
const { page, perPage, perPageOptions } = usePagination()
const selected = ref([]);
const deleteLoading = ref(false);
const portfolio = computed(() => portfolioStore.activePortfolio);

const incomes = computed(() => investmentStore.incomes);

const createIncome = () => {
  router.push({
    name: 'investment-create-income',
    params: { investmentId: investmentStore.investment.id }
  })
}

const editIncome = (income: IncomeTransactionDtoType) => {
  router.push({
    name: 'investment-edit-income',
    params: { investmentId: investmentStore.investment.id, incomeId: income.id }
  })
}

const { sync, updateRoute } = useFilters([{
  key: 'P',
  ref: page,
  type: 'int',
}, {
  key: 'PP',
  ref: perPage,
  type: 'int',
}], '_i')

function loadData () {
  if (!investmentStore.investment.id) {
    return;
  }
  investmentStore.getIncomes(investmentStore.investment.id, {
    limit: perPage.value,
    offset: (page.value - 1) * perPage.value,
  }).then(() => {
    selected.value = [];
    updateRoute();
  });
}

watch(() => [page.value, perPage.value, investmentStore.investment], () => {
  loadData();
})

const incomeHeaders = ref([{
  title: t('label.date'),
  align: 'start',
  sortable: false,
  key: 'dateTransactionAt',
}, {
  title: t('label.gross_payment'),
  align: 'end',
  sortable: false,
  key: 'gross',
}, {
  title: t('label.tax_withheld'),
  align: 'end',
  sortable: false,
  key: 'tax',
}, {
  title: t('label.fees'),
  align: 'end',
  sortable: false,
  key: 'fee',
}, {
  title: t('label.net_payment'),
  align: 'end',
  sortable: false,
  key: 'quantity',
}, {
  align: 'end',
  title: '',
  key: 'actions',
  sortable: false,
  width: '40px'
}])

const onUpdatePage = (_page: number) => {
  if (!incomes.value.loading && incomes.value.value !== undefined) {
    page.value = _page;
  }
}

const deleteSelected = async () => {
  if (!selected.value.length) {
    return;
  }
  deleteLoading.value = true;
  for(const id of selected.value) {
    await investmentStore.deleteTransaction(investmentStore.investment.id, id);
  }
  await loadData();
  deleteLoading.value = false;
};

onMounted(() => {
  sync();
  loadData();
})
</script>

<template>
  <DisplayCard :title="t('investment_page.income_title')" color="panel-heading-bg">
    <template v-slot:actions>
      <v-btn v-if="portfolio?.canEdit" @click="createIncome" variant="flat" color="action" :size="$vuetify.display.xs ? 'small' : 'default'" prepend-icon="mdi-plus">
        {{ t('investment_page.income_create') }}
      </v-btn>
    </template>
    <v-data-table-server
      class="mb-4"
      density="compact"
      v-model="selected"
      v-model:items-per-page="perPage"
      :page="page"
      @update:page="onUpdatePage"
      :headers="incomeHeaders"
      :items-length="incomes.value?.totalItems || 0"
      :items="incomes.value?.items"
      :loading="!!incomes.loading"
      item-value="id"
      :items-per-page-options="perPageOptions"
      :show-select="portfolio?.canEdit"
    >
      <template v-slot:footer.prepend>
        <v-btn 
          v-if="selected.length" 
          class="ml-4" 
          color="error"
          variant="tonal"
          size="small"
          :loading="deleteLoading"
          @click="deleteSelected">
          {{ t('label.delete') }}
        </v-btn>
        <v-spacer />
      </template>
      <template v-slot:item.dateTransactionAt="{ value }">
        <DateCell :value="value" />
      </template>
      <template v-slot:item.quantity="{ value, item }">
        <PriceCell :value="value" :currency-code="item.currencyCode" :transaction-type="item.transactionType" isTotalValue adjustSign />
      </template>
      <template v-slot:item.tax="{ value, item }">
        <PriceCell :value="-(value || 0)" :currency-code="item.currencyCode" transaction-type="tax" isTotalValue />
      </template>
      <template v-slot:item.fee="{ value, item }">
        <PriceCell :value="-(value || 0)" :currency-code="item.currencyCode" transaction-type="fee" isTotalValue />
      </template>
      <template v-slot:item.gross="{ value, item }">
        <PriceCell :value="item.tax + item.quantity + item.fee" :currency-code="item.currencyCode" :transaction-type="item.transactionType" isTotalValue />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon @click="editIncome(item)" size="small" class="me-2" v-if="portfolio?.canEdit">
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:no-data>
        {{ t('label.no_data') }}
      </template>
    </v-data-table-server>
  </DisplayCard>
</template>
<script setup lang="ts">
import AuthRegister from '../authForms/AuthRegister.vue';
import { ref } from 'vue';
import AuthCard from './AuthCard.vue';

const isRegistered = ref(false);
const onRegister = () => {
  isRegistered.value = true;
};

const my_awesome_script = document.createElement('script');
my_awesome_script.setAttribute('src','https://accounts.google.com/gsi/client');
document.head.appendChild(my_awesome_script);
</script>

<template>
  <AuthCard :title="$t('register_page.sign_up')" v-if="!isRegistered">
    <AuthRegister @register="onRegister" />
  </AuthCard>
  <AuthCard :title="$t('register_page.thank_you')" id="successful-register" v-else>
    <h4 class="text-h4 my-4 text-center font-weight-regular text-medium-emphasis">
      {{ $t('register_page.check_email') }}
    </h4>
  </AuthCard>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { currencyFormatter }  from '@/const';
import { TransactionType } from '~/enums';
import { useTransaction } from '@/transaction.helper';

const props = defineProps({
  mode: {
    type: String,
    default: 'normal'
  },
  totalCostbase: null,
  transaction: null,
  baseCurrencyCode: null,
  toCurrencyCode: null,
  amountType: {
    type: String,
    default: 'net'
  },
  color: {
    type: String,
    default: 'secondary'
  }
})


const transactionInfo = computed (() => {
  return useTransaction(props.transaction, props.baseCurrencyCode, props.amountType).value
});

const totalCostbase = computed(() => {
  // @ts-expect-error
  if ([TransactionType.Opening].includes(props.transactionType) && props.mode !== 'cash-account') {
    return null;
  }

  if (!props.transaction.quantity || !props.transaction.price) {
    return null;
  }

  return {
    title: 'Total',
    inBaseCurrency: currencyFormatter(transactionInfo.value.valueInBaseCurrency, props.baseCurrencyCode),
    costbase: currencyFormatter(transactionInfo.value.valueInLocalCurrency, props.toCurrencyCode),
    showSecondLine: props.toCurrencyCode !== props.baseCurrencyCode
  }
})

const marketValue = computed(() => {
  // @ts-expect-error
  if (![TransactionType.Opening].includes(props.transactionType)) {
    return null;
  }

  if (!props.transaction.quantity || !props.transaction.price) {
    return null;
  }

  const exchangeRate = parseFloat(props.transaction.exchangeRate ?? 1);
  const costbase = parseFloat(props.transaction.quantity) * parseFloat(props.transaction.price);
  const inBaseCurrency = costbase / exchangeRate;

  return {
    title: 'Market Value',
    inBaseCurrency: currencyFormatter(inBaseCurrency, props.baseCurrencyCode),
    costbase: currencyFormatter(costbase, props.transaction.currencyCode),
    showSecondLine: props.transaction.currencyCode !== props.baseCurrencyCode
  }
})

const averageCostPerShare = computed(() => {
  // @ts-expect-error
  if (![TransactionType.Opening].includes(props.transactionType)) {
    return null;
  }

  if (!props.transaction.quantity || !props.totalCostbase) {
    return null;
  }

  const exchangeRate = parseFloat(props.transaction.exchangeRate ?? 1);
  const averageCostPerShare = parseFloat(props.totalCostbase) / parseFloat(props.transaction.quantity);

  return {
    title: 'Average cost per share',
    inBaseCurrency: currencyFormatter(averageCostPerShare, props.baseCurrencyCode),
    costbase: currencyFormatter(averageCostPerShare / exchangeRate, props.transaction.currencyCode),
    showSecondLine: props.transaction.currencyCode !== props.baseCurrencyCode
  }
})

const item = computed(() => {
  if (props.mode === 'market-value') {
    return marketValue.value;
  }
  if (totalCostbase.value) {
    return totalCostbase.value;
  }
  if (averageCostPerShare.value) {
    return averageCostPerShare.value;
  }
  return null;
});
</script>

<template>
  <div class="pa-3" :class="'bg-' + props.color" v-if="item">
    <div class="d-flex align-center flex-column">
      <div class="text-subtitle-2 font-weight-bold text-uppercase">{{ item.title }}</div>
      <div class="font-weight-medium">
        <span class="text-h2">{{ item.inBaseCurrency }}</span>
      </div>
      <div class="text-h4" v-if="item.showSecondLine">
        <span class="font-weight-light">{{ item.costbase }}</span>
      </div>
    </div>
  </div>
</template>
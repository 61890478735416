<script setup lang="ts">
import { useLoanStore } from '@/stores/loanAccountStore';
import { onMounted, ref } from 'vue';
import { watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { currencyFormatter, signNumberBasedOnTransactionType, formatNumberBasedOnTransactionType, dateTimeFormatter } from '@/const';
import { HoldingType } from '~/enums';
import { useI18n } from 'vue-i18n';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import DateCell from '@/components/table/DateCell.vue';
import TransactionTypeCell from '@/components/table/TransactionTypeCell.vue';
import { useFilters, usePagination, usePerformanceFilter } from '@/filter.helper';
import PerformanceFiltersVue from '@/components/PerformanceFilters.vue';
import { CoinEuroIcon } from 'vue-tabler-icons';
import StatsCard from '@/components/shared/StatsCard.vue';
import { useAccountStore } from '@/stores/accountStore';
import PerformanceBar from '@/components/PerformanceBar.vue';
import NotesModule from './../investment/NotesModule.vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import Investment from '@/components/Investment.vue';

const router = useRouter();
const loanStore = useLoanStore();
const accountStore = useAccountStore();
const portfolioStore = usePortfolioStore();
const { t } = useI18n();

const loan = computed(() => loanStore.loan);
const showMonetaryValue = ref(true);
const { summaryPeriod, summaryRange } = usePerformanceFilter();
const { page, perPage, perPageOptions } = usePagination()
const selected = ref([]);

const { sync, updateRoute } = useFilters([{
  key: 'P',
  ref: page,
  type: 'int',
}, {
  key: 'PS',
  ref: perPage,
  type: 'int',
}])

const loadTransactions = () => {
  if (!loanStore.loan || !loanStore.loan.id) {
    return;
  }
  loanStore.getLoanAccountTransactions(loanStore.loan.id, {
    limit: perPage.value,
    offset: (page.value - 1) * perPage.value,
  }).then(() => {
    selected.value = [];
    updateRoute();
  });
}

watch(() => loanStore.loan, (loan) => {
  if (!loan || !loan.id) {
    return;
  }
  loadTransactions();
  accountStore.getAccountSummary(loan.id, summaryPeriod.value, summaryRange.value);
}, { immediate: true });

watch(() => [page.value, perPage.value], () => {
  loadTransactions();
});

const transactions = computed(() => loanStore.transactions);
const summary = computed(() => accountStore.accountSummary);

const headers = ref([{
  title: t('label.date'),
  align: 'start',
  sortable: false,
  key: 'dateTransactionAt',
}, {
  title: t('label.transaction_type_short'),
  align: 'start',
  sortable: false,
  key: 'transactionType',
}, {
  title: t('label.description'),
  align: 'start',
  sortable: false,
  key: 'description',
}, {
  title: t('label.related_account'),
  align: 'start',
  sortable: false,
  key: 'relatedHolding.name',
}, {
  title: t('label.sum'),
  align: 'end',
  sortable: false,
  key: 'quantity',
},{
  title: t('label.balance'),
  align: 'end',
  sortable: false,
  key: 'balance',
}, {
  align: 'end',
  title: '',
  key: 'actions',
  sortable: false,
  width: '40px'
}])

const createTransaction = () => {
  router.push({
    name: 'loan-create-transaction',
    params: { accountId: loanStore.loan.id }
  })
}

const editTransaction = (transaction) => {
  
  let name = 'loan-edit-transaction';
  let params: any = { investmentId: transaction.accountIid, transactionId: transaction.id };

  if (transaction.relatedHolding?.type === HoldingType.Investment) {
    if (transaction.isIncome) {
      name = 'investment-edit-income';
      params = { investmentId: transaction.relatedHolding.id, incomeId: transaction.relatedTransactionId };
    } else {
      name = 'investment-edit-transaction';
      params = { investmentId: transaction.relatedHolding.id, transactionId: transaction.relatedTransactionId };
    }
  }

  router.push({
    name,
    params,
  })
}

const loadItems = (params: any) => {
  if (!loanStore.loan.id) {
    return;
  }
}

const relatedInvestment = computed(() => {
  const loan = loanStore.loan;
  if (!loan || !loan.relatedHoldingId) {
    return null;
  }
  if (portfolioStore.portfolioInvestments.loading) {
    return null;
  }
  return (portfolioStore.portfolioInvestments || []).find((account) => account.id === loan.relatedHoldingId);
})

const accountInfoLines = computed(() => {
  const loan = loanStore.loan;
  if (!loan) {
    return [];
  }
  return [
    { title: t('label.loan_account_name'), subtitle: loan.name, visible: true },
    { title: t('label.currency'), subtitle: loan.currencyCode, visible: true },
    { title: 'Seotud investeering', key: 'related_investment', visible: !!relatedInvestment.value }
  ].filter((line) => line.visible);
})

const hasDifferentCurrency = computed(() => {
  return summary.value.groups[0]?.items[0]?.currencyCode !== summary.value.baseCurrencyCode;
})


onMounted(() => {
  sync();
})
</script>

<template>
  <PageLayout :heading="loan?.name">
    <template v-slot:actions>
      <PerformanceFiltersVue
        v-model="summaryPeriod"
        v-model:range="summaryRange"
        v-model:showMonetaryValue="showMonetaryValue"
      />
    </template>
    <v-row>
      <v-col cols="12" sm="6" md="4" class="d-flex align-stretch">
        <StatsCard
          :value="currencyFormatter(summary.value, summary.baseCurrencyCode)"
          :subtitle="`${t('account_page.balance_on')} ${dateTimeFormatter(summary?.groups?.[0]?.items?.[0]?.endDate)}`"
          :icon="CoinEuroIcon"
          :loading="!summary.portfolioId"
          color="primary-lighten-1"
        >
          <h3 class="text-h3 d-flex ga-2 align-center" v-if="summary.portfolioId">
            <div v-if="hasDifferentCurrency && summary.value">{{ currencyFormatter(summary.groups[0]?.items[0]?.quantity, summary.groups[0]?.items[0]?.currencyCode) }}</div>
            <div v-if="hasDifferentCurrency && summary.value"> = </div>
            <div>{{ currencyFormatter(summary.value, summary.baseCurrencyCode) }}</div>
            <HideBalancesButton />
          </h3>
          <div v-else><v-progress-circular size="32" indeterminate color="white" /></div>
        </StatsCard>
      </v-col>
      <v-col v-if="summary?.portfolioId" cols="12" sm="12" md="8" class="d-flex align-stretch">
        <PerformanceBar
          :currencyCode="summary.baseCurrencyCode"
          :performance="summary['openAndClosedPositions']"
          color="primary-lighten-4"
        />
      </v-col>
      <v-col cols="12" lg="9">
        <v-row>
          <v-col cols="12">
            <DisplayCard :title="t('account_page.transactions')" color="orange">
              <template v-slot:actions>
                <v-btn variant="flat" color="green" @click="createTransaction" :size="$vuetify.display.xs ? 'small' : 'default'" prepend-icon="mdi-plus-circle-outline">
                  {{ t('account_page.create_transaction') }}
                </v-btn>
              </template>
              <v-data-table-server
                class="mb-4"
                density="compact"
                v-model:items-per-page="perPage"
                v-model:page="page"
                v-model="selected"
                :headers="headers"
                :items-length="transactions.value.totalItems ?? 0"
                :items="transactions.value.items"
                :loading="!!transactions.loading"
                item-value="id"
                :items-per-page-options="perPageOptions"
              >
                <template v-slot:item.dateTransactionAt="{ value }">
                  <DateCell :value="value" />
                </template>
                <template v-slot:item.transactionType="{ value, item }">
                  <TransactionTypeCell :value="value" :transaction="item" :instrumentType="'loan'" is-loan />
                </template>
                <template v-slot:item.quantity="{ item }">
                  <span v-bind="formatNumberBasedOnTransactionType(item.transactionType, item.quantity)">{{ currencyFormatter(signNumberBasedOnTransactionType(item.transactionType, item.quantity), item.currencyCode) }}</span>
                </template>
                <template v-slot:item.balance="{ item }">
                  {{ currencyFormatter(item.balance, item.currencyCode) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon @click="editTransaction(item)" size="small" class="me-2">
                    mdi-pencil
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  {{ t('label.no_data') }}
                </template>
              </v-data-table-server>
            </DisplayCard>
          </v-col>
          <v-col cols="12">
            <NotesModule :holding="loan" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="3">
        <DisplayCard :title="t('loan_page.account_info')" color="orange">
          <v-list lines="one">
            <v-list-item
              v-for="(info, i) in accountInfoLines"
              :key="i"
              :title="info.title"
              :subtitle="info.subtitle"
            >
              <template v-if="info.key === 'related_investment'">
                <v-list-item-subtitle class="text-primary">
                  <Investment
                    :code="relatedInvestment.symbol"
                    :logo="relatedInvestment.logo"
                    :name="relatedInvestment.name"
                    :route="{ name: 'investment', params: { investmentId: relatedInvestment.id } }"
                  />
                </v-list-item-subtitle>
              </template>
            </v-list-item>
          </v-list>
          <div class="pa-4 d-flex flex-column" style="gap: 10px;">
            <v-btn
              :to="{name: 'loan-settings', params: { loanId: loan.id } }"
              color="primary"
              rounded="sm"
              size="small"
              variant="flat"
            >
              {{ $t('loan_page.settings') }}
            </v-btn>
          </div>
        </DisplayCard>
      </v-col>
    </v-row>
  </PageLayout>
</template>

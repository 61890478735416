import FullLayout from '@/layouts/full/FullLayout.vue';
import Layout from '@/views/account/Layout.vue';
import Account from '@/views/account/Account.vue';
import Settings from '@/views/account/Settings.vue';
import CreateTransaction from '@/views/account/CreateTransaction.vue';
import EditTransaction from '@/views/account/EditTransaction.vue';

const AccountRoutes = {
  path: '/account',
  meta: {
    requiresAuth: true
  },
  component: FullLayout,
  children: [
    {
      path: ':accountId',
      component: Layout,
      meta: {
        parent: 'portfolio-accounts'
      },
      children: [
        {
          name: 'account',
          path: '',
          component: Account,
          meta: {
            hideHeading: true,
            title: 'Account'
          }
        },
        {
          name: 'account-settings',
          path: 'settings',
          component: Settings,
          meta: {
            title: 'Account settings'
          }
        },
        {
          name: 'account-create-transaction',
          path: 'create-transaction',
          component: CreateTransaction,
          meta: {
            title: 'Create account transaction'
          }
        },
        {
          name: 'account-edit-transaction',
          path: 'edit-transaction/:transactionId',
          component: EditTransaction,
          meta: {
            title: 'Edit account transaction'
          }
        },
      ]
    },
  ]
};

export default AccountRoutes;

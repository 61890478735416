<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from 'vue';
import { fieldDesign, rules } from '@/const';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { computed } from 'vue';
import DisplayCard from "@/components/shared/DisplayCard.vue";

const { t } = useI18n();
const emit = defineEmits(['created', 'selected']);
const props = defineProps(['type', 'variant', 'prefill', 'title', 'allowAccounts'])

const portfolioStore = usePortfolioStore();

const cashAccounts = computed(() => {
  return portfolioStore.portfolioAccounts.item
})

const selectedAccount = ref(null);

const submit = async () => {
  emit('selected', selectedAccount.value)
}

</script>

<template>
  <v-row>
    <v-col cols="12" :lg="props.variant === 'search' ? 12 : 6">
      <DisplayCard :title="props.title">
        <div class="pa-6">
          <v-autocomplete
            v-bind="(fieldDesign as any)"
            :rules="[rules.required]"
            v-model="selectedAccount"
            :items="cashAccounts"
            :label="t('label.related_account')"
            item-title="name"
            item-value="id"
            return-object
          />
        </div>
        <v-divider></v-divider>
        <div class="pa-6 text-sm-right text-center">
          <v-btn @click="submit" color="primary">
            {{ t('button.select') }}
          </v-btn>
        </div>
      </DisplayCard>
    </v-col>
  </v-row>
</template>

<template>
  <v-text-field
    v-if="!isMobile"
    v-bind="fieldDesign"
    v-model="dateFormatted"
    :label="label"
    placeholder="DD.MM.YYYY"
    v-maska:[options]
    @blur="updateField"
    @click="onClick"
    @keydown="onKeydown"
    :rules="allRules"
    ref="dateFieldRef"
  >
    <template v-slot:prepend-inner>
      <v-menu
      :close-on-content-click="false"
      v-model="open"
      lazy
      transition="scale-transition"
      >
        <template v-slot:activator="{ props }">
          <v-icon v-bind="props">mdi-calendar</v-icon>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          hide-header
          show-adjacent-months
          @input="open = false"
        >
          <template v-slot:actions>
            <div class="d-flex">
                  <v-btn @click="date = new Date()">
                  Täna
                </v-btn>
            </div>
          </template>
        </v-date-picker>
      </v-menu>
    </template>
  </v-text-field>
  <v-text-field
    v-else
    v-bind="fieldDesign"
    v-model="value"
    :label="label"
    :rules="allRules"
    type="date"
  />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { computed } from 'vue';
import { vMaska } from "maska";
import { fieldDesign, rules } from '@/const';
import dayjs from 'dayjs';
import vuetify from '@/plugins/vuetify';

const emit = defineEmits([
  'update:modelValue',
]);

const props = defineProps({
  modelValue: null,
  label: {
    type: String,
  },
  required: {
    type: Boolean,
    default: true,
  },
  rules: {
    type: Array,
    default: () => [],
  },
});

const options = { mask: '##.##.####', eager: true, tokensReplace: true };

const open = ref(false);
const dateFormatted = ref('');
const date = ref(null);
const dateFieldRef = ref(null);

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

watch (date, (date) => {
  value.value = date ? dayjs(date.toISOString()).format('YYYY-MM-DD') : '';
  open.value = false
})

watch (value, (value) => {
  dateFormatted.value = value ? dayjs(value).format('DD.MM.YYYY') : '';
}, { immediate: true })

watch (dateFormatted, (dateFormatted) => {
  if (!dateFormatted || !dayjs(dateFormatted, 'DD.MM.YYYY', true).isValid()) {
    return
  }
  date.value = dateFormatted ? dayjs(dateFormatted, 'DD.MM.YYYY', true).toDate() : null;
}, { immediate: true })

function updateField () {
  const date = dateFormatted.value

  if (!date) {
    value.value = null
    return
  } 
  value.value = dayjs(date, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
}

function onClick (e) {
  open.value = true
}

function onKeydown (e) {
  open.value = false
}

const allRules = computed(() => {
  const rulesList: any = [
    ...props.rules,
    rules.value.date
  ]
  if (props.required) {
    rulesList.push(rules.value.required)
  }
  return rulesList
})

const isMobile = computed(() => vuetify.display.xs.value)
</script>

<style lang="scss" scoped>
:deep(.v-date-picker-month__weekday) {
  font-weight: 900 !important;
}
</style>
<template>
  <Dialog v-model:model-visible="visible"  :title="'Loo Interactive Brokers ühendus'">
    <p class="text-body-1">
      Sisesta FlexQuery ID ja Flex Token, et liidestuda Interactive Brokers kontoga.
      QueryID ja Tokeni loomise juhendit <a target="_blank" class="text-primary" href="https://portfellow.com/help/kuidas-seadistada-interactive-brokers-automaatne-import/">loe siit</a>.
    </p>
    <p class="mt-2 text-caption">
      Interactive Brokers liidestus käivitatakse automaatselt korra päevas, peale esimest sisselogimist.
      Täna tehtud tehinguid on näha alles järgmisel päeval.
    </p>
    <v-form>
      <v-row dense class="py-4">
        <v-col cols="12">
          <v-text-field
            v-model="form.flexQueryId"
            label="FlexQuery ID"
            v-bind="(fieldDesign as any)"
            dense
            required
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.apiKey"
            label="Token"
            v-bind="(fieldDesign as any)"
            outlined
            dense
            required
          />
          <p class="mt-3 text-caption">Token aegub 11 kuuga, peale mida tuleb genereerida uus token ja 
      see Portfellow rakendusesse sisestada.</p>
        </v-col>
      </v-row>
      <v-divider class="mb-5"></v-divider>
      <v-btn
        :loading="isLoading"
        :disabled="isLoading"
        variant="flat"
        color="green"
        @click="addConnection"
      >
        Alusta liidestamist
      </v-btn>
    </v-form>
  </Dialog>
</template>

<script setup lang="ts">
import { fieldDesign } from '@/const';
import { useAppStore } from '@/stores/app';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { computed, ref } from 'vue';

const emit = defineEmits(['close']);

const props = defineProps({
  visible: Boolean,
  account: {
    type: Object,
    required: false
  }
});

const appStore = useAppStore();

const visible = computed({
  get: () => props.visible,
  set: (value) => {
    emit('close');
  }
});

const isLoading = ref(false);
const form = ref({
  flexQueryId: '',
  apiKey: ''
});

const addConnection = async () => {
  isLoading.value = true;
  try {
    await fetchWrapper.put(`/api/v1/import/start/${props.account.providerId}?portfolioId=${props.account.portfolioId}&holdingId=${props.account.id}&service=ib`, {
      settings: {
        ...form.value
      }
    });
    appStore.showNotice('Konto ühendatud', 'success');
    emit('close');
  } catch (error) {
    console.error(error);
    isLoading.value = false;
  } finally {
    isLoading.value = false;
  }
}
</script>
<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore';
import Dashboard from './dashboard/Dashboard.vue';
import Welcome from './dashboard/Welcome.vue';
import { computed } from 'vue';
import { usePortfolioStore } from '@/stores/portfolioStore';

const userStore = useAuthStore();
const portfolioStore = usePortfolioStore();

const component = computed(() => {
  if (portfolioStore.portfolios.value?.length === 0 || !userStore.hasChosenTier) {
    return Welcome;
  }
  return Dashboard;
});

</script>

<template>
  <component :is="component" />
</template>

import { defineStore } from 'pinia';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';

export const useReportStore = defineStore('report', () => {
  const getDiversityReport = async (portfolioId: number, params: Record<string, string>): Promise<number> => {
    return fetchWrapper.get(`/api/v1/portfolio/${portfolioId}/report/diversity?${new URLSearchParams(params).toString()}`)
      .then((response) => {
        return response
      })
  }

  const getNetworthReport = async (portfolioId: number, params: Record<string, string>): Promise<number> => {
    return fetchWrapper.get(`/api/v1/portfolio/${portfolioId}/report/networth?${new URLSearchParams(params).toString()}`)
      .then((response) => {
        return response
      })
  }

  const getPriceReport = async (portfolioId: number, instrumentId: number, params: Record<string, string>): Promise<number> => {
    return fetchWrapper.get(`/api/v1/portfolio/${portfolioId}/report/${instrumentId}/price?${new URLSearchParams(params).toString()}`)
      .then((response) => {
        return response
      })
  }

  const getTransactionsReport = async (portfolioId: number, params: Record<string, any>): Promise<any> => {
    return fetchWrapper.get(`/api/v1/portfolio/${portfolioId}/report/transactions?${new URLSearchParams(params).toString()}`)
      .then((response) => {
        return response
      })
  }

  const getFutureFashflowReport = async (portfolioId: number, params: Record<string, any>): Promise<any> => {
    return fetchWrapper.get(`/api/v1/portfolio/${portfolioId}/report/future-cashflow?${new URLSearchParams(params).toString()}`)
      .then((response) => {
        return response
      })
  }

  const getPerformanceReport = async (portfolioId: number, params: Record<string, any>): Promise<any> => {
    return fetchWrapper.get(`/api/v1/portfolio/${portfolioId}/report/performance?${new URLSearchParams(params).toString()}`)
      .then((response) => {
        return response
      })
  }

  const getInstrumentPerformanceReport = async (portfolioId: number, instrumentId: number, params: Record<string, any>): Promise<any> => {
    return fetchWrapper.get(`/api/v1/portfolio/${portfolioId}/instrument/${instrumentId}/report/performance?${new URLSearchParams(params).toString()}`)
      .then((response) => {
        return response
      })
  }

  return {
    getDiversityReport,
    getTransactionsReport,
    getNetworthReport,
    getPriceReport,
    getFutureFashflowReport,
    getPerformanceReport,
    getInstrumentPerformanceReport
  }
});

<script setup lang="ts">
import { watch } from 'vue';
import { useInvestmentStore } from '@/stores/investmentStore';
import { useRoute } from 'vue-router'
import { computed } from 'vue';

const route = useRoute()
const investmentStore = useInvestmentStore();
const investment = computed(() => investmentStore.investment);

watch(() => route.params.investmentId, async newId => {
  investmentStore.get(newId as string);
}, { immediate:true });

</script>

<template>
  <PageLayout :heading="investment?.instrument?.name" v-if="!route.meta.hideHeading">
    <router-view />
  </PageLayout>
  <router-view v-else />
</template>

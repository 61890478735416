<script setup lang="ts">
import { watch } from 'vue';
import BaseBreadcrumb from '@/components/shared/BaseBreadcrumb.vue';
import { useLoanStore } from '@/stores/loanAccountStore';
import { useRoute } from 'vue-router'
import { computed } from 'vue';
import { usePortfolioStore } from '@/stores/portfolioStore';

const route = useRoute()
const loanStore = useLoanStore();
const portfolioStore = usePortfolioStore();

const loan = computed(() => {
  return loanStore.loan;
});

watch(() => route.params.loanId, async newId => {
  loanStore.get(newId as string);
}, { immediate:true });
</script>

<template>
  <PageLayout :heading="loan?.name" v-if="!route.meta.hideHeading">
    <router-view />
  </PageLayout>
  <router-view v-else />
</template>

<template>
  <div class="d-flex align-center justify-center">
    <Logo :color="isDark ? 'white' : 'black'" v-if="$vuetify.display.mdAndDown" url="https://portfellow.com" />
  </div>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify";
import Logo from '@/layouts/full/logo/LogoDark.vue';

const theme = useTheme();

const isDark = theme.current.value.dark;
</script>

import { createRouter, createWebHistory } from 'vue-router';
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';
import PublicRoutes from './PublicRoutes';
import PortfolioRoutes from './PortfolioRoutes';
import InvestmentRoutes from './InvestmentRoutes';
import AccountRoutes from './AccountRoutes';
import SettingsRoutes from './SettingsRoutes';
import ReportRoutes from './ReportRoutes';
import { useAuthStore } from '@/stores/authStore';
import InstrumentRoutes from './InstrumentRoutes';
import SubscribeRoutes from './SubscribeRoutes';
import LoanRoutes from './LoanRoutes';
import GroupRoutes from './GroupRoutes';
import { nextTick } from 'vue';
import TicketRoutes from './TicketRoutes';
import Error404Page from '@/views/pages/maintenance/error/Error404Page.vue';

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:pathMatch(.*)*',
      component: Error404Page
    },
    PublicRoutes,
    ReportRoutes,
    PortfolioRoutes,
    InvestmentRoutes,
    AccountRoutes,
    LoanRoutes,
    MainRoutes,
    SettingsRoutes,
    InstrumentRoutes,
    SubscribeRoutes,
    GroupRoutes,
    TicketRoutes,
    AuthRoutes
  ]
});

interface User {
  // Define the properties and their types for the user data here
  // For example:
  id: number;
  name: string;
}

// Assuming you have a type/interface for your authentication store
const publicPages = ['/auth/login'];

router.beforeEach(async (to, from, next) => {
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authRequired && auth.backendRefreshDone && auth.user || !authRequired) {
      next();
    } else if (authRequired && !auth.backendRefreshDone) {
      if (await auth.getMe() && auth.user) {
        next();
      } else {
        auth.returnUrl = to.fullPath;
        next('/auth/login');
      }
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();
  const isPublic = publicPages.includes(to.path);
  const isPrivate = !isPublic && to.matched.some((record) => record.meta.requiresAuth)
  const requiresTier = !to.matched.some((record) => record.meta.requiresTier === false)

  if (isPrivate && requiresTier && !auth.hasChosenTier) {
    next({name: 'dashboard'});
  } else {
    next();
  }
});

router.beforeEach((to, _from, next) => {
  const auth = useAuthStore();
  const isPrivate = to.matched.some((record) => record.meta.requiresAuth)
  const doesNotNeedTerms = to.matched.some((record) => record.meta.requiresTerms === false)

  if (!auth.hasAcceptedTerms && isPrivate && !doesNotNeedTerms) {
    next({name: 'terms'});
  } else {
    next();
  }
});

const DEFAULT_TITLE = 'Portfellow';

router.afterEach((_to, from) => {
  nextTick(() => {
    document.title = _to.meta.title ? `${DEFAULT_TITLE} - ${_to.meta.title}` : DEFAULT_TITLE;
  });
  if (from.name === 'import') {
    void useAuthStore().getMe();
  }
});

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")) {
    // @ts-expect-error
    window.location = '/';
  }
});
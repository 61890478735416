<template>
  <DisplayCard :title="$t('import_page.bulk_edit_title')" color="panel-heading-bg">
    <div class="pa-4">
      <p class="text-body-1 mb-2">
        {{ $t('import_page.bulk_edit_description')}}
      </p>
      <InvestmentTypeSelect
        v-model="item"
        :editable="true"
        :portfolio-id="portfolioId"
        :cash-account="cashAccount"
        :show-undefined="true"
        @create="$emit('createInstrument', item)"
      />
      <v-select
        v-if="allTypes.length > 0"
        clearable
        v-bind="(fieldDesign as any)"
        :items="allTypes"
        :label="t('label.transaction_type')"
        v-model="item.transactionType"
      />
    </div>
    <v-divider></v-divider>
    <div class="px-6 py-5 d-flex">
      <v-spacer />
      <v-btn @click="$emit('confirm', item)" color="blue">Rakenda</v-btn>
    </div>
  </DisplayCard>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import InvestmentTypeSelect from './InvestmentTypeSelect.vue';
import { useInstrumentConfig } from '@/instrument.helper';
import { useI18n } from 'vue-i18n';
import { fieldDesign } from '@/const';

defineEmits(['createInstrument', 'confirm']);

const props = defineProps({
  transactions: {
    type: Array,
    required: true
  },
  cashAccount: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

const { t, te } = useI18n();

const item = ref({
  instrument: {},
  original: {},
  account: {},
  counterparty: {},
  transactionType: null,
});

const portfolioId = computed(() => {
  // @ts-ignore
  return props.transactions[0]?.portfolioId;
});

watch(() => props.transactions, () => {
  const hasSameInstrument = props.transactions
    // @ts-ignore
    .every((transaction) => transaction.instrument.id === props.transactions[0].instrument.id);
  if (hasSameInstrument) {
    // @ts-ignore
    item.value.instrument = props.transactions[0].instrument;
  }
}, { deep: true, immediate: true });

const investment = computed(() => item.value.instrument);

const { iConf } = useInstrumentConfig(investment);

const getBroadKey = (key: string) => {
  const type = investment.value?.type;
  return `instrumentType.${type}.${key}`
}

const transactionTypeOptions = computed(() => {
  if (!iConf.value) {
    return [];
  }
  return [...iConf.value.allowedTransactionTypes, ...iConf.value.allowedIncomeTypes].map((type) => {
    const broadKey = getBroadKey(`transactionType.${type}.title`)
    return {
      title: te(broadKey) ? t(broadKey) : t(`transactionType.${type}.title`),
      value: type
    }
  })
})

const allTypes = computed(() => {
  const map: Record<any, any> = transactionTypeOptions.value.reduce((acc, type) => {
    // @ts-ignore
    acc[type.value] = {
      title: type.title,
      value: type.value,
    }
    return acc;
  }, {});
  props.transactions.forEach((transaction: any) => {
    if (transaction.instrument) {
      const { iConf } = useInstrumentConfig(ref(transaction));
      if (!iConf.value) {
        return;
      }
      [...iConf.value.allowedTransactionTypes, ...iConf.value.allowedIncomeTypes].map((type) => {
        const broadKey = getBroadKey(`transactionType.${type}.title`)
        return {
          title: te(broadKey) ? t(broadKey) : t(`transactionType.${type}.title`),
          value: type
        }
      }).forEach((type) => {
        if (!map[type.value]) {
          map[type.value] = {
            title: type.title,
            value: type.value,
          }
        }
      })
    }
  });

  return Object.values(map);
})

</script>

<script setup lang="ts">
import { computed, ref, type PropType } from 'vue';
import PanelTitle from './PanelTitle.vue';
import type { Transaction } from './types';
import { watch } from 'vue';
import { Currency, TransactionType } from '~/enums';
import TransactionForm from './TransactionForm.vue';
import Decimal from 'decimal.js';
import { VExpansionPanel } from 'vuetify/lib/components/index.mjs';
import dayjs from 'dayjs';
import InvestmentTypeSelect from './InvestmentTypeSelect.vue';
import { instrumentConfig } from '~/config';

const emit = defineEmits([
  'update:modelValue',
  'createInstrument',
  'delete',
  'split'
]);

const props = defineProps({
  modelValue: {
    type: Object as PropType<Transaction>,
    required: true
  },
  editable: {
    type: Boolean,
    default: true
  },
  cashAccount: {
    type: Object,
    required: false
  },
  uniqueKey: {
    type: String,
    required: true
  }
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const isFormValid = ref(true);
const formRef = ref(null);

const diff = computed(() => {
  const nr = new Decimal(value.value?._account?.netAmount || 0).sub(new Decimal(value.value?.account?.netAmount || 0)).abs().toNumber();
  return nr >= 0.01 ? nr : 0;
});

const validate = () => {
  // @ts-ignore
  return formRef.value?.validate();
};

const isValid = computed(() => {
  return {
    transactionType: hasValidTransactionType.value,
    instrument: !!value.value.instrument.id,
    form: isFormValid.value,
    currencyCode: hasValidCurrencyCode.value,
    quantity: !!value.value.account.quantity,
    date: hasValidDate.value
  }
});

const hasValidTransactionType = computed(() => {
  if (value.value?.instrument?.type) {
    const conf = instrumentConfig[value.value.instrument.type];
    if (conf && !conf.allTransactionTypes().includes(value.value.transactionType as any)) {
      return false;
    }
  }
  return Object.values(TransactionType).includes(value.value.transactionType as any);
});

const hasValidCurrencyCode = computed(() => {
  return Object.values(Currency).includes(value.value.account.currencyCode as Currency);
});

const hasValidDate = computed(() => {
  return !!value.value.date && dayjs(value.value.date).isValid() && dayjs(value.value.date).isAfter(dayjs('1970-01-01'));
});

watch(isValid, (obj) => {
  const allValid = Object.values(obj).every((v) => v);
  value.value.control.isValid = allValid;
  value.value.control.isTransactionTypeValid = obj.transactionType;
}, { immediate: true, deep: true })

watch(() => value.value.instrument.id, async () => {
  validate();
});
</script>

<template>
  <Lazy :component="VExpansionPanel" :minHeight="50" :unrender="false" elevation="0" class="panel" :key="uniqueKey" :value="uniqueKey" renderOnIdle>
    <panel-title
      v-model="value"
      :color="value.control.isValid ? 'light-green-lighten-5' : 'red-lighten-5'"
      :editable="editable"
    />
    <v-expansion-panel-text>
      <InvestmentTypeSelect
        v-model="value"
        :editable="editable"
        :portfolio-id="value.portfolioId"
        :cash-account="cashAccount"
        @create="emit('createInstrument', value)"
      />
      <div class="mt-5">
        <transaction-form
          v-model="value"
          v-model:is-valid="isFormValid"
          :account="value.instrument"
          :cash-account="cashAccount"
          :editable="editable"
          ref="formRef"
        />
        <div class="d-flex ga-2 flex-column">
          <v-alert
            v-if="diff"
            :prominent="true"
            color="#C51162"
            theme="dark"
            border
          >
            Erinevus imporditud väärtusest: <strong>{{ diff }}</strong>
          </v-alert>
        </div>
      </div>
      <div class="mt-5" v-if="editable">
        <v-divider />
        <div class="d-flex ga-2">
          <v-btn class="mt-4" size="small" color="error" @click="$emit('delete', value)">Kustuta</v-btn>
          <v-btn class="mt-4" size="small" color="blue" @click="$emit('split', value)">Tükelda</v-btn>
        </div>
      </div>
    </v-expansion-panel-text>
  </Lazy>
</template>


<style lang="scss">
.panel {
  border-radius: 0;
  border-bottom: 1px solid #e0e0e0;
}
</style>
<script setup lang="ts">
import { ref } from 'vue';
import ChoosePackage from '@/components/ChoosePackage.vue';
import ChoosePackageAnnual from '@/components/ChoosePackageAnnual.vue';

const isAnnual = ref(true);
</script>

<template>
  <PageLayout :heading="$t('subscription.package')" hide-back :maxWidth="1200">
    <template v-slot:actions>
      <ChoosePackageAnnual v-model="isAnnual" />
    </template>
    <ChoosePackage :isAnnual="isAnnual" />
    <v-row>
      <v-col cols="12">
        <h2 class="mt-10 text-darkprimary">
          {{ $t('subscription.compare') }}
        </h2>
      </v-col>
      <v-col cols="12">
        <v-card variant="outlined" elevation="1">
          <v-table class="compare">
            <thead>
              <tr class="heading bg-primary-lighten-1">
                <th style="min-width:200px;">
                  <span class="d-none d-sm-block">
                    {{ $t('subscription.investments_and_portfolio') }}
                  </span>
                </th>
                <th>
                  {{ $t('plans.free.title') }}
                </th>
                <th>
                  {{ $t('plans.starter.title') }}
                </th>
                <th>
                  {{ $t('plans.investor.title') }}
                </th>
                <th>
                  {{ $t('plans.expert.title') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.portfolio_limit') }}
                </td>
                <td>1</td>
                <td>2</td>
                <td>4</td>
                <td>10</td>
              </tr>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.investments_count') }}
                </td>
                <td>10</td>
                <td>30</td>
                <td>∞</td>
                <td>∞</td>
              </tr>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.grouping') }}
                  <HoverHelpIcon 
                    :help-text="$t('subscription.grouping_help')"
                  />
                </td>
                <td>1</td>
                <td>3</td>
                <td>5</td>
                <td>10</td>
              </tr>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.bank_links') }}
                </td>
                <td>0</td>
                <td>4</td>
                <td>8</td>
                <td>16</td>
              </tr>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.consolitated_views') }}
                  <HoverHelpIcon 
                    :help-text="$t('subscription.consolitated_views_help')"
                  />
                </td>
                <td></td>
                <td></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.shared_portfolios') }}
                  <HoverHelpIcon 
                    :help-text="$t('subscription.shared_portfolios_help')"
                  />
                </td>
                <td></td>
                <td></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr class="text-body-1">
                <td style="position: relative;">
                  {{ $t('subscription.permissions') }}
                  <HoverHelpIcon 
                    :help-text="$t('subscription.permissions_help')"
                  />
                </td>
                <td></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.importing') }}
                </td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr class="text-body-1">
                <td style="position: relative;">
                  {{ $t('subscription.bank_accounts') }} (<strong>LHV, Swedbank, SEB, Wise, Revolut</strong>)
                </td>
                <td></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr class="text-body-1">
                <td style="position: relative;">
                  {{ $t('subscription.brokers') }} (<strong>Interactive Brokers</strong>)
                </td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr class="text-body-1">
                <td style="position: relative;">
                  {{ $t('subscription.notes') }}
                  <HoverHelpIcon 
                    :help-text="$t('subscription.notes_help')"
                  />
                </td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
            </tbody>
            <thead>
              <tr class="heading bg-primary-lighten-1">
                <th>
                  {{ $t('subscription.investment_types') }}
                </th>
                <th>
                  {{ $t('plans.free.title') }}
                </th>
                <th>
                  {{ $t('plans.starter.title') }}
                </th>
                <th>
                  {{ $t('plans.investor.title') }}
                </th>
                <th>
                  {{ $t('plans.expert.title') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.stocks') }}
                </td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.custom_investments') }}
                </td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.cash_accounts') }}
                </td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.exchanges') }}
                </td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr class="text-body-1">
                <td>
                  {{ $t('subscription.baltic_exchange') }}
                </td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
            </tbody>
            <thead>
              <tr class="heading bg-primary-lighten-1">
                <th>
                  {{ $t('subscription.reports') }}
                </th>
                <th>
                  {{ $t('plans.free.title') }}
                </th>
                <th>
                  {{ $t('plans.starter.title') }}
                </th>
                <th>
                  {{ $t('plans.investor.title') }}
                </th>
                <th>
                  {{ $t('plans.expert.title') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-body-1">
                <td style="position: relative;" colspan="5">
                  {{ $t('subscription.performance_report') }}
                  <v-overlay
                    :model-value="true"
                    class="align-center justify-center"
                    contained
                    scrim="black"
                    persistent
                    >
                  <v-chip color="amber" class="font-weight-medium" variant="elevated">
                    <v-icon class="mr-2">mdi-clock-time-four-outline</v-icon>
                    {{ $t('subscription.report_coming_soon') }}
                  </v-chip>
                </v-overlay>
                </td>
              </tr>
              <tr style="position: relative;" class="text-body-1">
                <td>
                  {{ $t('subscription.allocation_report') }}
                </td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr style="position: relative;" class="text-body-1">
                <td>
                  {{ $t('subscription.transactions_report') }}
                </td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
              <tr class="text-body-1">
                <td style="position: relative;" colspan="5">
                  {{ $t('subscription.detailed_allocation') }}
                  <v-overlay
                    :model-value="true"
                    class="align-center justify-center"
                    contained
                    scrim="black"
                    persistent
                    >
                  <v-chip color="amber" class="font-weight-medium" variant="elevated">
                    <v-icon class="mr-2">mdi-clock-time-four-outline</v-icon>
                    {{ $t('subscription.report_coming_soon') }}
                  </v-chip>
                </v-overlay>
                </td>
              </tr>
              <tr class="text-body-1">
                <td style="position: relative;">
                  {{ $t('subscription.cashflow') }}
                </td>
                <td></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
                <td><v-icon>mdi-check</v-icon></td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>
    </v-row>
  </PageLayout>
</template>
<style scoped lang="scss">
table td:nth-child(n+2),
table th:nth-child(n+2) {
  text-align:center !important;
}

.compare td,
.compare th {
  line-height: 1.2em;
}
</style>

<template>
   <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="font-weight-bold">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pa-4 black--text"
        v-html="message"
      ></v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!options.noconfirm"
          color="primary"
          text
          class="body-2 font-weight-bold"
          @click.native="cancel"
          >
          {{ $t('label.cancel') }}
          </v-btn
        >
        <v-btn color="error" @click.native="agree">{{ $t('label.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const title = ref('');
const message = ref('');
const resolve = ref((ok: any) => { return; });
const reject = ref(null);
const dialog = ref(false);
const options = ref({
  color: "primary",
  width: 400,
  zIndex: 2000,
  noconfirm: false,
  callback: null
});

const agree = () => {
  dialog.value = false;

  if (options.value.callback) {
    options.value.callback(true);
    return;
  }

  resolve.value(true);
}

const cancel = () => {
  dialog.value = false;

  if (options.value.callback) {
    options.value.callback(false);
    return;
  }

  resolve.value(false);
}

const open = (_title: string, _message: string, _options: {}) => {
  dialog.value = true;
  title.value = _title;
  message.value = _message;
  options.value = Object.assign(options.value, _options);

  if (options.value.callback) {
    return
  }

  return new Promise((_resolve, _reject) => {
    resolve.value = _resolve;
    reject.value = _reject;
  });
}

defineExpose({
  open
});
</script>

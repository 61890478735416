<template>
  <v-text-field
    v-bind="({...fieldDesign, ...design} as any)"
    :rules="allRules"
    type="text"
    v-model="value"
    :label="label"
    :placeholder="placeholder"
    persistent-placeholder
  >
    <template v-slot:label>
      {{ props.label }}
      <HoverHelpIcon
        color="lightText"
        v-if="helpText && props.helpPosition === 'label'"
        size="16"
        :helpText="helpText"
      />
    </template>
  </v-text-field>
</template>

<script setup lang="ts">
import { fieldDesign } from '@/const';
import { computed } from 'vue';

const emit = defineEmits([
  'update:modelValue',
]);

const props = defineProps({
  modelValue: null,
  design: {
    type: Object,
    default: () => ({}),
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  helpText: {
    type: String,
    default: null,
  },
  helpPosition: {
    type: String,
    default: 'label',
  },
  rules: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const allRules = computed(() => {
  if (props.disabled) return []
  return [...props.rules]
});
</script>
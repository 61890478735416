import { i18n } from '@/plugins/i18n';
import { router } from '@/router';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/authStore';
import fetchRetry from 'fetch-retry';
import isNil from 'lodash/isNil';
import { Sema } from 'async-sema';

const Fetch = fetchRetry(fetch, {
  retries: 3,
  retryDelay: 800,
  retryOn: [503, 520]
});

const limiter = new Sema(5, { capacity: 10 });

const { t } = i18n.global

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE')
};

function request(method: string) {
  return (url: string, body?: object) => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const requestOptions: any = {
      method,
      headers: authHeader(url)
    };
    if (body) {
      if (body instanceof FormData) {
        requestOptions.body = body;
      } else {
        requestOptions.headers['Content-Type'] = 'application/json';
        requestOptions.body = JSON.stringify(body);
      }
    }
    return limiter.acquire()
      .then(() => Fetch(url, requestOptions))
      .then(handleResponse)
      .finally(() => limiter.release());
  };
}

// helper functions

function authHeader(url: string) {
  // return auth header with jwt if user is logged in and request is to the api url
  const { user } = useAuthStore();
  const isLoggedIn = !!user?.token;
  const isApiUrl = url.startsWith(import.meta.env.VITE_API_URL);
  let headers: Record<string, string> = {}

  if (isLoggedIn && isApiUrl) {
    headers = { Authorization: `Bearer ${user.token}` };
  }

  if (!isNil(localStorage.getItem('user_id'))) {
    headers['X-APP-User-Id-Hint'] = localStorage.getItem('user_id') ?? '';
  }

  return headers;
}

function handleResponse(response: any) {
  const appStore = useAppStore()
  const { showNotice } = appStore

  return response.text().then((text: string) => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      const { user } = useAuthStore();

      const isExpiredToken = (data.message === 'Authorization token expired')

      if (data.code === 'ACCEPT_TERMS') {
        router.push({ name: 'terms' });
        return Promise.reject(data);
      }

      if (data.message && showNotice && user && !isExpiredToken) {
        const key = `error.${data.message.replace(/\.|"/g, '_')}`;
        showNotice(t(key, data.message), 'error');
      }

      return Promise.reject(data || response.statusText);
    }

    return data;
  });
}

<script setup lang="ts">
import { usePortfolioStore } from '@/stores/portfolioStore';
import InvestmentForm from '../investment/InvestmentForm.vue';
import { computed } from 'vue';

const portfolioStore = usePortfolioStore();
const portfolio = computed(() => portfolioStore.selectedPortfolio);

</script>

<template>
  <PageLayout :heading="portfolio.name">
    <InvestmentForm type="create" />
  </PageLayout>
</template>

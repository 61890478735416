<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding';
import { onMounted } from 'vue';
import { ref } from 'vue';
import 'v-onboarding/dist/style.css'
import { useAuthStore } from '@/stores/authStore';
import ChoosePackage from '@/components/ChoosePackage.vue';
import ChoosePackageAnnual from '@/components/ChoosePackageAnnual.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const app = useAppStore();
const auth = useAuthStore();
const { t } = useI18n();

const wrapper = ref(null)
const { start } = useVOnboarding(wrapper)
const isAnnual = ref(true);
const hasChoosenPackage = computed(() => auth.hasChosenTier);

const steps = [
  { 
    attachTo: { element: '#welcome' },
    content: {
      title: t('onboarding.welcome_title'),
      description: t('onboarding.welcome_step_description') 
    }
  },
  { 
    attachTo: { element: '#settings-dd' },
    content: {
      title: t('onboarding.settings_title'),
      description: t('onboarding.settings_description') 
    }
  },
  { 
    attachTo: { element: '#notification-dd' },
    content: {
      title: t('onboarding.notification_title'),
      description: t('onboarding.notification_description')
    }
  },
  { 
    attachTo: {
      element: '#portfolios-dd' 
    },
    content: { 
      title: t('onboarding.portfolios_title'),
      description: t('onboarding.portfolios_description')
    },
    on: {
      afterStep: function () {
        app.openPortfoliosMenu();
        window.localStorage.setItem('onboarding-welcome', 'true');
      }
    }
  }
]

onMounted(() => {
  if (window.localStorage.getItem('onboarding-welcome') === 'true') {
    return;
  }
})
</script>

<template>
  <VOnboardingWrapper
    ref="wrapper"
    :steps="steps"
    :options="{labels: {
    previousButton: t('label.previous'),
    nextButton: t('label.next'),
    finishButton: t('label.finish'),
  }}"/>
  <div class="text-center d-flex flex-column justify-center align-center" style="min-height:50vh;" id="registered-onboarding">
    <h1 class="font-weight-black text-darkprimary" id="welcome">🎉 
      {{ $t('onboarding.welcome_heading') }}
    </h1>
    <div class="d-flex flex-column ga-3 mt-4" style="max-width:770px;">
      <template v-if="!hasChoosenPackage">
        <p>
          {{ $t('onboarding.welcome_description') }}
        </p>
        <p v-html="$t('onboarding.welcome_description_2', {
          count: 40
        })"></p>
      </template>
      <template v-else>
        <p v-html="$t('onboarding.welcome_description_3')"></p>
        <p>
          {{ $t('onboarding.welcome_description_4') }}
        </p>
      </template>
    </div>
    <div style="width:100%; max-width: 1000px;" class="text-left">
      <template v-if="!hasChoosenPackage">
        <div class="d-flex justify-end">
          <ChoosePackageAnnual class="mt-5" v-model="isAnnual" variant="compact" />
        </div>
        <ChoosePackage class="mt-2" variant="compact" :is-annual="isAnnual" />
        <v-sheet class="mt-5 bg-primary-lighten-4 pa-3 font-weight-normal text-body-1 text-center" border rounded :elevation="0">
          {{ $t('onboarding.see_packages_1') }} <router-link class="text-blue" :to="{name: 'subscribe'}">
            {{ $t('onboarding.see_packages_2') }}
          </router-link>.
        </v-sheet>
      </template>
      <template v-else>
        <div class="d-flex flex-column flex-sm-row ga-3 justify-sm-center mt-5">
          <v-btn :to="{ name:'create-portfolio' }" size="large" color="blue">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('onboarding.create_portfolio') }}
          </v-btn>
          <v-btn @click="start" color="primary-lighten-3" size="large">
            {{ $t('onboarding.start_tour') }}
          </v-btn>
        </div>
      </template>
    </div>
  </div>
</template>
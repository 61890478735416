import { SubscriptionTier } from "../../enums";

interface SubscriptionLimit {
  portfolios: number | null
  holdings: number | null
  consolidatedPortfolios: boolean
  linkAgreements: number
  customGroups: number
  permissions: boolean
}

export const SubscriptionLimits: Record<SubscriptionTier, SubscriptionLimit> = {
  [SubscriptionTier.Waitinglist]: {
    portfolios: 0,
    holdings: 0,
    consolidatedPortfolios: false,
    linkAgreements: 0,
    customGroups: 0,
    permissions: false,
  },
  [SubscriptionTier.Undefined]: {
    portfolios: 0,
    holdings: 0,
    consolidatedPortfolios: false,
    linkAgreements: 0,
    customGroups: 0,
    permissions: false,
  },
  [SubscriptionTier.Free]: {
    portfolios: 1,
    holdings: 10,
    consolidatedPortfolios: false,
    linkAgreements: 0,
    customGroups: 1,
    permissions: false,
  },
  [SubscriptionTier.Starter]: {
    portfolios: 2,
    holdings: 30,
    consolidatedPortfolios: false,
    linkAgreements: 4,
    customGroups: 3,
    permissions: true,
  },
  [SubscriptionTier.Investor]: {
    portfolios: 4,
    holdings: null,
    consolidatedPortfolios: true,
    linkAgreements: 8,
    customGroups: 5,
    permissions: true,
  },
  [SubscriptionTier.Expert]: {
    portfolios: null,
    holdings: null,
    consolidatedPortfolios: true,
    linkAgreements: 16,
    customGroups: 10,
    permissions: true,
  }
}

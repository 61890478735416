<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { computed } from 'vue';
const appStore = useAppStore();

const notice = computed(() => {
  return appStore.notice;
})

const hideNotice = () => {
  appStore.hideNotice();
}
</script>

<template>
  <v-snackbar
    :color="notice.type"
    variant="flat"
    rounded="md"
    location="top right"
    v-model="notice.visible"
    :close-on-back="false">
    {{ notice.message }}
    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="hideNotice">
        <v-icon size="20">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
import { createI18n } from 'vue-i18n';

import messages from '@/utils/locales/messages';

export const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('locale') || 'et',
  fallbackLocale: 'en',
  messages: messages,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  warnHtmlMessage: false
});

<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore';
import { ref } from 'vue';
import { onMounted } from 'vue';
import { computed } from 'vue';
import { SubscriptionTier } from '../../../../backend/src/enums';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { dateFormatter, currencyFormatter } from '@/const';
import { useI18n } from 'vue-i18n';

const authStore = useAuthStore();
const { t } = useI18n();

const me = computed(() => authStore.user);
const billingInfo = ref<{
  invoices: any[]
} | null>(null);
const manageSubscriptionLoading = ref(false);
const showAllInvoices = ref(false);


const showStripe = computed(() => {
  return me.value && ![SubscriptionTier.Free, SubscriptionTier.Undefined].includes(me.value.subscription.tier)
});

const filteredInvoices = computed(() => {
  return (billingInfo.value?.invoices || [])
    .slice(0, showAllInvoices.value ? undefined : 5)
    .map((invoice) => ({
      ...invoice,
      status: getInvoiceStatusInfo(invoice)
    }));
});

const hasHiddenInvoices = computed(() => {
  return (billingInfo.value?.invoices || []).length > 5;
});

const manageSubscription = () => {
  manageSubscriptionLoading.value = true;
  fetchWrapper.get('/api/v1/stripe/customer-portal').
    then((response) => {
      window.location.href = response.url;
    })
    .catch(() => {
      manageSubscriptionLoading.value = false;
    });
};

const getInvoiceStatusInfo = (invoice: any) => {
  return {
    color: invoice.isPaid ? 'success' : (invoice.status === 'draft' ? 'gray' : 'error'),
    label: invoice.isPaid ? t('billing_page.paid') : (invoice.status === 'draft' ? t('billing_page.draft') :  t('billing_page.unpaid'))
  };
};

onMounted(async () => {
  try {
    billingInfo.value = await authStore.getBillingInfo();
  } catch (_e) {
    //
  }
});

</script>

<template>
  <PageLayout :heading="$t('settings_page.plans_and_billing_tab')">
    <v-row>
      <v-col cols="12">
        <display-card :title="$t('settings_page.subscription_settings')" color="panel-heading-bg">
          <v-card-text>
            <v-row v-if="me">
              <v-col md="4">
                <h4 class="text-h5">{{ $t('billing_page.current_subscription') }}</h4>
                <span class="text-subtitle-2 text-disabled font-weight-medium"></span>
              </v-col>
              <v-col md="8">
                <h4 class="text-h4">
                  {{ $t(`plans.${me.subscription.tier}.title`) }}
                </h4>
                <v-btn color="primary" variant="flat" class="mt-2" :to="{ name: 'subscribe' }" size="small">
                  {{ me.subscription.tier !== 'free' ? $t('billing_page.change') : $t('billing_page.upgrade') }}
                </v-btn>
              </v-col>
            </v-row>
            <template v-if="showStripe">
              <v-divider class="my-4" />
              <v-row>
                <v-col md="4">
                  <h4 class="text-h5">
                    {{ $t('billing_page.manage_subscription') }}
                  </h4>
                </v-col>
                <v-col md="8">
                  <div class="d-flex align-start ga-1 flex-column">
                    <v-progress-circular color="blue" indeterminate v-if="manageSubscriptionLoading" />
                    <a class="text-h4 text-primary" href="#" @click="manageSubscription">
                      {{ $t('billing_page.manage_subscription_stripe') }}
                    </a>
                    {{ $t('billing_page.manage_subscription_description') }}
                  </div>
                </v-col>
              </v-row>
              <v-divider class="my-4" />
              <v-row>
                <v-col md="4">
                  <h4 class="text-h5">
                    {{ $t('billing_page.invoices') }}
                  </h4>
                </v-col>
                <v-col md="8">
                  <div class="d-flex">
                    <v-progress-circular color="blue" indeterminate v-if="!billingInfo" />
                    <v-table density="compact" v-else-if="filteredInvoices.length">
                      <thead>
                        <tr class="bg-table-heading-bg">
                          <th>{{ $t('billing_page.invoice_date') }}</th>
                          <th>{{ $t('billing_page.invoice_amount') }}</th>
                          <th>{{ $t('billing_page.invoice_status') }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="invoice in filteredInvoices" :key="invoice.invoicNr">
                          <td>
                            {{ dateFormatter(invoice.createdAt) }}
                          </td>
                          <td class="text-right">
                            {{ currencyFormatter(invoice.totalAmount, invoice.currency) }}
                          </td>
                          <td>
                            <v-chip :color="invoice.status.color" variant="flat" density="compact">
                              {{ invoice.status.label }}
                            </v-chip>
                          </td>
                          <td>
                            <div class="d-flex ga-2">
                              <v-btn color="primary" variant="flat" size="small" :href="invoice.link.pdf" target="_blank">
                                {{ $t('billing_page.download') }}
                              </v-btn>
                              <v-btn color="primary" variant="tonal" size="small" :href="invoice.link.hosted" target="_blank">
                                {{ $t('billing_page.view') }}
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="hasHiddenInvoices && !showAllInvoices">
                          <td colspan="4">
                            <v-btn variant="plain" color="primary" @click="showAllInvoices = !showAllInvoices">
                              {{ $t('billing_page.show_all') }}
                            </v-btn>
                          </td>
                        </tr>
                        <tr v-if="showAllInvoices">
                          <td colspan="4">
                            {{ $t('billing_page.more_in_stripe') }}
                          </td>
                        </tr>
                      </tbody>
                    </v-table>
                    <div class="text-body-1" v-else>
                      {{ $t('billing_page.no_invoices') }}
                    </div>
                 </div>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </display-card>
      </v-col>
    </v-row>
  </PageLayout>
</template>

export enum PriceSource {
  Eodhd = 'eodhd',
  Yahoo = 'yahoo',
  NasdaqBalticWeb = 'nasdaq-baltic-web',
  Eepk = 'eepk',
  Funderbeam = 'funderbeam'
}

export enum SubscriptionStatus {
  Pending = 'pending',
  Active = 'active',
  Cancelled = 'cancelled',
  Deleted = 'deleted',
  Expired = 'expired'
}

export enum SubscriptionTier {
  Waitinglist = 'waitinglist',
  Undefined = 'undefined',
  Free = 'free',
  Starter = 'starter',
  Investor = 'investor',
  Expert = 'expert'
}

<script setup lang="ts">
import { computed, ref } from 'vue';
import { watch } from 'vue';
import AmountField from './AmountField.vue';
import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import { usePriceStore } from '@/stores/priceStore';
import type { PriceDtoType } from '~/price.schema';
import SuggestPrice from './SuggestPrice.vue';

const emit = defineEmits([
  'update:modelValue',
  'update:currencyCodeValue'
]);

const props = defineProps({
  modelValue: null,
  currencyCodeValue: null,
  date: {
    type: String,
    default: '',
    required: true,
  },
  instrumentId: {
    default: null,
    required: false
  },
  label: {
    type: String,
  },
  helpText: {
    type: String,
    default: null,
  },
  rules: {
    type: Array,
    default: () => [],
  },
  currencyOptions: {
    type: Array,
    default: null,
  },
  hideCurrency: {
    type: Boolean,
    default: false,
  },
  allCurrencies: {
    type: Boolean,
    default: false,
  },
  calculated: {
    type: Number,
    default: null,
  },
  originalValue: {
    type: Number,
    default: null,
  },
  design: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hint: {
    type: String,
    default: null,
  },
  prefill: {
    type: Boolean,
    default: true,
  }
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const priceStore = usePriceStore();
const officialPrice = ref<PriceDtoType | undefined>(undefined);
const automaticValue = ref<number | undefined>(undefined);

const useOfficialPrice = (e: MouseEvent) => {
  e.preventDefault();
  emit('update:modelValue', officialPrice.value?.price);
  return false;
}

const refreshPrice = (date: string) => {
  if (!props.instrumentId || !isNumber(props.instrumentId) || isNil(date) || !dayjs(date).isValid()) {
    officialPrice.value = undefined;
    return;
  }
  priceStore.getInstrumentPriceAt(props.instrumentId, date).then((price) => {
    officialPrice.value = price;
  });
}

watch(() => [props.date, props.instrumentId], () => {
  refreshPrice(props.date);
}, { immediate: true });

watch(officialPrice, (price: PriceDtoType | undefined) => {
  if (!props.prefill) {
    return;
  }
  if (price?.price && !value.value || value.value === automaticValue.value) {
    value.value = price?.price;
    automaticValue.value = price?.price;
  }
});

</script>


<template>
    <AmountField
      :design="design"
      v-model="value"
      :currency-code-value="currencyCodeValue"
      :label="$t('label.price_short')"
      :disabled="disabled"
      :rules="rules"
      :hint="hint"
      :help-text="helpText"
    >

    </AmountField>
    <suggest-price
      v-if="officialPrice"
      :density="design.density"
      :official-price="officialPrice"
      :use-official-rate="useOfficialPrice"
      :label="$t('label.close_price')"
    />
</template>
<script setup lang="ts">
import { computed, ref, type PropType } from 'vue';
import type { AccountDtoType } from '~/cash-account.schema';
import { currencyFormatter, dateFormatter, fieldDesign, currencyCodes } from '@/const';
import { useTransactionForm } from '@/utils/transaction.utils';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { useAccountStore } from '@/stores/accountStore';
import { useAppStore } from '@/stores/app';
import { useRouter } from 'vue-router';
import { useAccountLink } from '@/utils/account.utils';
import { useI18n } from 'vue-i18n';
import { usePortfolioStore } from '@/stores/portfolioStore';

const props = defineProps({
  account: {
    type: Object as PropType<AccountDtoType>,
    required: true,
  }
});

defineEmits(['link']);

const accountStore = useAccountStore();
const appStore = useAppStore();
const portfolioStore = usePortfolioStore();
const router = useRouter();
const { t } = useI18n();

const portfolio = computed(() => portfolioStore.activePortfolio);
const mode = ref('view');

const form = ref({
  date: '',
  balance: 0,
  currencyCode: ''
})

const { canBeLinked } = useAccountLink(computed(() => props.account));

const {
  getInitialDate
} = useTransactionForm(computed(() => props.account), ref(null as any));

const onEdit = (item?: any) => {
  mode.value = 'edit';
  form.value.date = item?.date ?? props.account?.balanceDate ?? getInitialDate();
  form.value.balance = item?.balance ?? props.account?.balance ?? 0;
  form.value.currencyCode = item?.currencyCode ?? props.account?.currencyCode ?? '';
};

const updateBalance = async () => {
  try {
    await fetchWrapper.put(`/api/v1/account/${props.account.id}/balance`, {
      ...form.value,
    });
    appStore.showNotice(t('account_page.messages.balance_updated'), 'success');
    accountStore.refresh()
  } finally {
    mode.value = 'view';
  }
}

defineExpose({ onEdit })
</script>

<template>
  <DisplayCard :title="$t('label.account_balance')" color="primary-lighten-1">
    <template v-slot:actions v-if="mode === 'view'">
      <DropdownMenu
        v-if="portfolio?.canEdit"
        :activator-size="$vuetify.display.xs ? 'small' : 'default'"
        :label="$t('header.actions')"
        density="default"
        color="blue"
        :items="[{
          value: 'transaction',
          onClick: () => onEdit(),
          title: $t('account_page.update_balance'),
          icon: 'mdi-pencil'
        }, {
          value: 'import',
          id: 'import-transactions',
          onClick: () => router.push({ name: 'import', params: { portfolioId: account.portfolioId }, query: { accountId: account.id } }),
          title: $t('label.import'),
          icon: 'mdi-microsoft-excel'
        }, {
          value: 'link',
          onClick: () => $emit('link'),
          title: $t('account_page.add_link'),
          icon: 'mdi-sync',
          visible: canBeLinked,
        }].filter(x => x.visible !== false)"
      />
    </template>
    <template v-slot:actions v-else>
      <div class="d-flex ga-1">
        <v-btn
          color="success"
          @click="updateBalance"
          :size="$vuetify.display.xs ? 'small' : 'default'"
        >
          <v-icon>mdi-check</v-icon>
          {{ $t('label.save') }}
        </v-btn>
        <v-btn 
          variant="tonal"
          color="success"
          @click="mode = 'view'"
          :size="$vuetify.display.xs ? 'small' : 'default'"
        >
          <v-icon>mdi-close</v-icon>
          {{ $t('label.cancel') }}
        </v-btn>
      </div>
    </template>
    <v-table style="border-radius:0;">
      <thead>
        <tr class="bg-primary-lighten-3">
          <th>
            {{ $t('label.currency') }}
          </th>
          <th>
            {{ $t('label.balance') }}
          </th>
          <th>
            {{ $t('label.balance_date') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span v-if="mode === 'edit'">
              <v-select
                v-bind="fieldDesign"
                :items="currencyCodes"
                v-model="form.currencyCode"
                density="compact"
                :disabled="true"
              />
            </span>
            <span v-else>{{ account.currencyCode }}</span>
          </td>
          <td>
            <AmountField
              v-if="mode === 'edit'"
              v-model="form.balance"
              hide-currency
              :design="{density:'compact'}"
            />
            <span v-else>{{ currencyFormatter(account.balance, account.currencyCode) }}</span>
          </td>
          <td>
            <DateField
              v-if="mode === 'edit'"
              v-model="form.date"
              :label="$t('label.balance_date')"
              density="compact"
            />
            <span v-else>{{ dateFormatter(account.balanceDate) }}</span>
          </td>
        </tr>
      </tbody>
    </v-table>
  </DisplayCard>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue';
import TransactionForm from './../investment/TransactionForm.vue';
import { useLoanStore } from '@/stores/loanAccountStore';
import { useRoute } from 'vue-router';
import { InstrumentType } from '~/enums';

const loanStore = useLoanStore();
const route = useRoute();

watch(() => route.params.transactionId, (transactionId) => {
  if (!transactionId) {
    return;
  }
  loanStore.getLoanAccountTransaction(route.params.loanId as string, transactionId as string);
}, { immediate: true });

const loan = computed(() => {
  return {
    ...loanStore.loan,
    type: InstrumentType.TakenLoan
  }
});
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-card variant="flat">
        <transaction-form
          action="update"
          :investment="loan"
          :transaction="loanStore.transaction"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

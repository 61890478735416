<script setup lang="ts">
import { computed } from 'vue';
import { currencyFormatter, formatNumberBasedOnTransactionType, signNumberBasedOnTransactionType } from '@/const';
import { TransactionType } from '~/enums';

const props = defineProps({
  value: null,
  baseValue: null,
  currencyCode: String,
  baseCurrencyCode: String,
  transactionType: null,
  adjustSign: {
    type: Boolean,
    default: false
  },
  isTotalValue: {
    type: Boolean,
    default: false
  },
  maximumFractionDigits: {
    type: Number,
    default: 5
  },
  disableColors: {
    type: Boolean,
    default: false
  }
});

const styles = computed(() => {
  let styles = {
    class: {}
  }
  if (props.adjustSign && !props.disableColors) {
    Object.assign(styles, formatNumberBasedOnTransactionType(props.transactionType, props.value, props.isTotalValue));
  } else if (!props.adjustSign && !props.disableColors) {
    if (props.value > 0) {
      // @ts-expect-error
      styles.class['text-success'] = true
    } else if (props.value < 0) {
      // @ts-expect-error
      styles.class['text-error'] = true;
    }
  }
  if (props.value === 0 || !showPrice.value) {
    // @ts-expect-error
    styles.class['text-disabled'] = true
  }
  return styles;
});

const formattedValue = computed(() => {
  const value = props.adjustSign  ? signNumberBasedOnTransactionType(props.transactionType, props.value) : props.value;
  return currencyFormatter(value, props.currencyCode, props.maximumFractionDigits, undefined, props.isTotalValue ? 'exceptZero' : undefined);
});

const formattedBaseValue = computed(() => {
  return currencyFormatter(props.baseValue, props.baseCurrencyCode, props.maximumFractionDigits, undefined, props.isTotalValue ? 'exceptZero' : undefined);
});

const showPrice = computed(() => {
  if ([TransactionType.Split].includes(props.transactionType)) {
    return false;
  }
  return props.isTotalValue || !props.transactionType || [TransactionType.Buy, TransactionType.Sell, TransactionType.Opening].includes(props.transactionType);
});

const showBasePrice = computed(() => {
  return showPrice.value && props.baseValue !== null && props.baseValue !== undefined && props.baseCurrencyCode !== props.currencyCode;
});
</script>

<template>
  <span v-if="showPrice" v-bind="styles" class="balance">
    {{ formattedValue }}
  </span>
  <span v-if="showBasePrice" class="balance">
    ({{ formattedBaseValue }})
  </span>
  <span v-if="!showPrice" class="text-disabled">-</span>
</template>


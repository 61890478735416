<template>
  <v-btn-toggle
    :style="$vuetify.display.smAndUp || !fullOnMobile ? '' : 'width: 100%;'"
    class="toggle bg-primary-lighten-4"
    :class="{'grow': grow, 'has-multiple': options.length > 2 && !noColumns}"
    v-model="value"
    :rules="[rules.required]"
    :color="color"
    :mandatory="mandatory"
    :density="(density as any)">
    <v-btn
      v-for="(o,i) in options"
      :key="i"
      :disabled="disabled"
      :class="{'bg-primary-lighten-4': o.value !== value, icon: mode === 'icon'}"
      :value="o.value">
      <v-icon v-if="mode === 'icon'">{{ o.title }}</v-icon>
      <template v-else>{{ o.title }}</template>
      <template v-if="o.help">
        <span class="ml-1">
          <HoverHelpIcon :color="o.value === value ? 'white' : undefined" :helpText="o.help" />
        </span>
      </template>
    </v-btn>
  </v-btn-toggle>
</template>

<script setup lang="ts">
import { rules } from '@/const';
import { computed } from 'vue';

const emit = defineEmits([
  'update:modelValue',
  'update:currencyCodeValue'
]);

const props = defineProps({
  modelValue: null,
  options: {
    type: Array,
    default: () => []
  },
  density: {
    type: String,
    default: 'default'
  },
  mode: {
    type: String,
    default: 'text'
  },
  fullOnMobile: {
    type: Boolean,
    default: false
  },
  mandatory: {
    type: Boolean,
    default: true
  },
  grow: {
    type: Boolean,
    default: true
  },
  color: {
    type: String,
    default: 'secondary'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  noColumns: {
    type: Boolean,
    default: false
  }
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});
</script>

<style lang="scss" scoped>
.toggle {
  display: inline-block;
  padding: 5px;
  box-sizing: content-box;
  gap: 5px;
  border-radius: 10px !important;

  &.v-btn-group--density-compact.v-btn-group {
    height: 28px;

    :deep(.v-btn) {
    
      &.icon {
        min-width: 20px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  &.grow {
    display: flex;
  }

  :deep(.v-btn) {
    flex: 1;
    border-radius: 5px !important;
    
    &.icon {
      min-width: 30px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &.has-multiple {
    @media only screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      height: auto;

      :deep(.v-btn) {
        flex: none;
        height: 30px !important;
        display: block !important;
      }
    }
  }
}
</style>

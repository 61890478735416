<script setup lang="ts">
import { watch } from 'vue';
import TransactionForm from './TransactionForm.vue';
import { useInvestmentStore } from '@/stores/investmentStore';
import { useRoute, useRouter } from 'vue-router';
import { TransactionCategory } from '~/enums';

const investmentStore = useInvestmentStore();
const route = useRoute();
const router = useRouter();

watch(() => route.params.transactionId, (transactionId) => {
  if (!transactionId) {
    return;
  }
  investmentStore.getTransaction(parseInt(route.params.investmentId as any), parseInt(transactionId as any));
}, { immediate: true });

watch(() => investmentStore.transaction, (transaction) => {
  if (!transaction) {
    return;
  }
  if (transaction.category === TransactionCategory.Income) {
    router.push({ name: 'investment-edit-income', params: { investmentId: route.params.investmentId, incomeId: transaction.id } });
  }
}, { immediate: true });
</script>

<template>
  <v-row>
    <v-col cols="12" lg="12">
      <transaction-form action="update" />
    </v-col>
  </v-row>
</template>

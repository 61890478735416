<template>
  <div class="d-flex flex-column flex-sm-row ga-1 align-start">
    <div class="d-flex flex-row flex-sm-column align-start align-sm-end">
      <v-icon :style="$vuetify.display.smAndUp ? '' : 'transform: rotate(275deg) scaleX(-1);'" class="mb-n2" color="blue">mdi-redo</v-icon>
      <span style="line-height:1em; text-align: center;" class="text-blue font-weight-bold text-caption pr-sm-2">🚀 <span v-html="$t('subscription.annual_info')">

      </span></span>
    </div>
    <ButtonToggle :density="variant === 'compact' ? 'compact' : undefined" full-on-mobile v-model="value" :options="[
      {value: true, title: t('subscription.annual')},
      {value: false, title: t('subscription.monthly')}
    ]" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  modelValue: Boolean,
  variant: {
    type: String,
    default: 'default'
  }
});

const emit = defineEmits(['update:modelValue']);
const { t } = useI18n();

const value = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emit('update:modelValue', value)
});
</script>
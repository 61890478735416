<template>
  <v-divider class="my-3 mr-1" :class="{'mr-3': !$vuetify.display.xs}" vertical></v-divider>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
        color="primary"
        v-bind="props"
        :append-icon="currencyOptions.length > 0 ? 'mdi-chevron-down' : undefined"
        variant="text"
        class="currency-btn pa-0"
        :class="{'mr-n2': $vuetify.display.xs}"
      >
        {{ value }}
      </v-btn>
    </template>
    <v-list v-if="currencyOptions.length > 0">
      <CurrencyItem
        v-for="(item, index) in currencyOptionsExtended"
        :key="index"
        :currency="item.value"
        @onSelect="value = item.value"
      />
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { currencyCodes, currencyCodesMap } from '@/const';
import type { Currency } from '~/enums';
import CurrencyItem from './CurrencyItem.vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: null,
  instrumentCurrency: null,
  portfolioCurrency: null,
  allCurrencies: {
    type: Boolean,
    default: false
  },
  currencyOptions: {
    type: Array,
    default: null
  }
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const currencyOptions = computed(() => {
  const options = []

  if (props.currencyOptions && props.currencyOptions.length > 0) {
    return props.currencyOptions
  }

  if (props.instrumentCurrency) {
    options.push({
      title: props.instrumentCurrency,
      value:props.instrumentCurrency
    })
  }

  if (props.portfolioCurrency && props.portfolioCurrency !== props.instrumentCurrency) {
    options.push({
      title: props.portfolioCurrency,
      value: props.portfolioCurrency
    })
  }

  currencyCodes.value.forEach((currencyCode) => {
    if (props.allCurrencies && ![props.instrumentCurrency, props.portfolioCurrency].includes(currencyCode)) {
      options.push({
        title: currencyCode,
        value: currencyCode
      })
    }
  })

  return options;
});

const currencyOptionsExtended = computed(() => {
  return currencyOptions.value.map((option: any) => {
    return {
      ...option,
      ...currencyCodesMap[option.value as Currency]
    }
  })
});
</script>

<style lang="scss">
.currency-btn {
  min-width: 50px;

  .v-btn__append {
    margin-left: 0;
  }
}
</style>

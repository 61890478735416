<template>
  <PageLayout :heading="$t('notes_page.title')" :showLoader="isPageLoading" hide-back>
    <v-row>
      <v-col cols="12">
        {{ $t('notes_page.intro') }}
      </v-col>
      <v-col cols="12">
        <NotesModule
          :title="portfolio?.name"
          :note="portfolioNote"
          color="panel-heading-bg"
          icon="mdi-briefcase-variant"
          v-if="portfolioNote"
        />
      </v-col>
      <v-col cols="12" class="mt-5" v-if="investmentNotes.length">
        <DisplayCard icon="mdi-clipboard-text-outline" :title="$t('notes_page.investment_notes_title')" variant="text" color="primary-lighten-1">
          <v-table class="radius-0">
            <thead>
              <tr class="bg-table-heading-bg">
                <th>
                  {{ $t('label.investment') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="note in investmentNotes" :key="note.holdingId">
                <td>
                  <Investment
                    :isCash="false"
                    :isLoan="false"
                    :showPortfolios="false"
                    :size="32"
                    :holding="note.holding"
                  />
                </td>
              </tr>
            </tbody>
          </v-table>
        </DisplayCard>
      </v-col>
    </v-row>
  </PageLayout>
</template>

<script lang="ts" setup>
import { usePortfolioStore } from '@/stores/portfolioStore';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { ref, watch, computed } from 'vue';
import NotesModule from '../investment/NotesModule.vue';
import Investment from '@/components/Investment.vue';

const portfolioStore = usePortfolioStore();

const isPageLoading = ref(false);
const notes = ref<{
  portfolioId: number
  holdingId: number | undefined
  content: string
}[]>([]);

const portfolio = computed(() => portfolioStore.selectedPortfolio);

const loadNotes = async () => {
  try {
    isPageLoading.value = true;
    const response = await fetchWrapper.get(`/api/v1/portfolio/${portfolioStore.selectedPortfolioId}/notes`);
    notes.value = response;
  } catch (error) {
    // Ignore for now
  } finally {
    isPageLoading.value = false;
  }
};

const portfolioNote = computed(() => {
  let note = notes.value.find((note) => note.portfolioId === portfolioStore.selectedPortfolioId && !note.holdingId);
  if (!note) {
    note = {
      portfolioId: portfolioStore.selectedPortfolioId,
      holdingId: undefined,
      content: ''
    };
  }
  return note;
});

const investmentNotes = computed(() => {
  return notes.value.filter((note) => note.holdingId);
});

watch(() => portfolioStore.selectedPortfolioId, (id) => {
  if (id) {
    loadNotes();
  }
}, { immediate: true });
</script>

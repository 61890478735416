<template>
  <v-dialog v-model:model-value="visible"  transition="dialog-bottom-transition" max-width="700"  :persistent="false" scrollable>
    <v-card>
      <v-toolbar color="primary-lighten-1">
        <v-toolbar-title>
          {{ $t('account_page.add_connection') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :loading="isLoading" :disabled="isLoading || !canAddMoreConnections" variant="flat" color="blue" prepend-icon="mdi-plus" size="small" @click="addConnections" class="mr-4">
          {{ $t('account_page.add_bank_connection') }}
          <v-avatar size="20" color="blue" class="ml-1">
            <v-img :src="account.logo" />
          </v-avatar>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="isLoading">
        <v-progress-circular indeterminate color="primary" />
      </v-card-text>
      <v-card-text v-else class="pa-4 d-flex flex-column ga-2">
        <div class="mb-2">
          <v-alert
            v-if="!limitsInfo?.maxPaidCount"
            dismissible
            elevation="2"
            color="amber"
            icon="mdi-crown-outline"
            @click="$router.push({ name: 'subscribe' })"
            style="cursor:pointer;"
            class="text-body-1"
          >
            {{ $t('account_page.upgrade_to_premium_connections') }}
          </v-alert>
          <v-alert
            v-else-if="!canAddMoreConnections"
            color="red"
            border
            dense
            outlined
            class="text-body-1"
          >
            <span v-html="$t('account_page.max_connections_reached', {
              paidCount: limitsInfo.paidCount,
              maxPaidCount: limitsInfo.maxPaidCount
            })" />
          </v-alert>
        </div>
        <p class="text-body-1" v-if="connections.length > 0" v-html="$t('account_page.connections_description', {
          accountName: account.name,
        })" />
        <v-list v-if="connections.length > 0" class="pa-0" lines="two" select-strategy="classic">
          <v-list-item class="px-1" v-for="connection in connections" :key="connection.id">
            <template v-slot:prepend="{ isActive }">
              <v-img class="mr-2 rounded-xl elevation-4" width="35" :src="connection.logo" cover />
            </template>
            <v-list-item-title>
              <strong>{{ connection.iban }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              <strong>{{connection.owner}}</strong> {{ connection.name }}
            </v-list-item-subtitle>
             <template v-slot:append>
              <v-btn  
                v-if="!connection.holding_id"
                size="small"
                color="green"
                variant="flat"
                @click="link(connection)"
                :loading="isLoading"
              >
                {{ $t('account_page.connect') }}
              </v-btn>
              <v-menu v-else location="bottom">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    size="small"
                    color="green"
                    variant="tonal"
                    append-icon="mdi-dots-vertical"
                    :loading="isUnlinkLoading"
                  >
                    {{ $t('account_page.connected') }}
                  </v-btn>
                </template>
                <v-list density="compact" class="elevation-1">
                  <v-list-item>
                    <v-list-item-title class="text-body-1">
                      <strong>{{ connection?.holding?.name }}</strong>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="unlink(connection)">
                    <v-list-item-title>
                      {{ $t('account_page.disconnect') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-list-item>
        </v-list>
        <div class="text-body-1 d-flex flex-column ga-3">
          <p v-if="connections.length <= 0" v-html="$t('account_page.no_connections_description')" />
          <video loop autoplay v-if="connections.length <= 0">
            <source src="https://static.portfellow.com/GoCardless.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  <CreateAccountConnectionConfirm
    v-model:model-visible="showAddConnectionsConfirmation"
    :isLoading="isLoading"
    @close="showAddConnectionsConfirmation = false"
    @confirm="addConnectionsConfirm"
  />
  <Dialog :title="$t('account_page.confirm_title')" v-model:model-visible="showLinkConfirmation" class="text-body-1">
    {{ $t('account_page.confirm_link') }} <br />
    <strong>{{ selectedConnection.owner }} - {{ selectedConnection.iban }}</strong> <br />
    <p class="mt-2">
      {{ $t('account_page.confirm_link_with') }}
      <strong>{{ account.name }}</strong>?</p>
    <v-divider class="my-4"></v-divider>
    <div class="d-flex ga-2">
      <v-btn color="primary" @click="linkConfirm" :loading="isLoading">
        {{ $t('account_page.confirm') }}
      </v-btn>
      <v-btn color="error" @click="showLinkConfirmation = false">
        {{ $t('account_page.cancel') }}
      </v-btn>
    </div>
  </Dialog>
  <UnlinkDialog v-if="unlinkAccount" :account="unlinkAccount" v-model:model-visible="showUnlinkDialog" @unlinked="onUnlinked" />
</template>

<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/authStore';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import UnlinkDialog from '@/views/account/UnlinkDialog.vue';
import { computed, watch } from 'vue';
import { ref } from 'vue';
import CreateAccountConnectionConfirm from './CreateAccountConnectionConfirm.vue';

const props = defineProps({
  visible: Boolean,
  account: {
    type: Object,
    required: false
  }
});

const emit = defineEmits(['close']);

const visible = computed({
  get: () => props.visible,
  set: (value) => {
    if (!value) {
      emit('close');
    }
  }
});

const appStore = useAppStore();
const authStore = useAuthStore();

const connections = ref<any[]>([]);
const isLoading = ref(false);
const isUnlinkLoading = ref(false);
const showAddConnectionsConfirmation = ref(false);
const showLinkConfirmation = ref(false);
const selectedConnection = ref(null);
const showUnlinkDialog = ref(false);
const unlinkAccount = ref(null);

const canAddMoreConnections = computed(() => {
  const limits = authStore.limits;
  // @ts-ignore
  return limits?.links?.availableLimit > 0;
})

const limitsInfo = computed(() => {
  const limits = authStore.limits;
  // @ts-ignore
  return limits?.links;
})

const getAllAccounts = async () => {
  try {
    isLoading.value = true;
    connections.value = await fetchWrapper.get('/api/v1/import/connections/all?service=go-cardless');
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
}

const addConnections = async () => {
  showAddConnectionsConfirmation.value = true;
}

const addConnectionsConfirm = async () => {
  showAddConnectionsConfirmation.value = false;
  isLoading.value = true;
  try {
    const result = await fetchWrapper.put(`/api/v1/import/start/${props.account.providerId}?portfolioId=${props.account.portfolioId}&holdingId=${props.account.id}`);
    if (result.redirect) {
      window.location = result.link;
    } else {
      isLoading.value = false;
    }
  } catch (error) {
    console.error(error);
    isLoading.value = false;
  } finally {
    // Ignore
  }
}

const link = (connection: any) => {
  showLinkConfirmation.value = true;
  selectedConnection.value = connection;
}

const linkConfirm = async () => {
  isLoading.value = true;
  showLinkConfirmation.value = false;
  try {
    await fetchWrapper.put(`/api/v1/import/link`, {
      linkId: selectedConnection.value.id,
      holdingId: props.account.id
    });
    emit('close');
    appStore.showNotice(t('account_page.messages.connected'), 'success');
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
}

const refresh = async () => {
  await Promise.all([
    getAllAccounts(),
    authStore.getMyLimits()
  ]);
}

const unlink = async (connection: any) => {
  try {
    isUnlinkLoading.value = true;
    unlinkAccount.value = connection.holding;
    showUnlinkDialog.value = true; 
  } finally {
    isUnlinkLoading.value = false;
  }
}

const onUnlinked = () => {
  showUnlinkDialog.value = false;
  refresh();
}

watch(() => props.visible, (value) => {
  if (value) {
    refresh();
  }
})
</script>
<template>
  <v-app :theme="appStore.theme" :class="[appStore.theme]">
    <LayoutNotice />
    <div class="page-wrapper" style="padding:0;">
      <RouterView />
    </div>
  </v-app>
</template>
<script setup lang="ts">
import { RouterView } from 'vue-router';
import { useAppStore } from '@/stores/app';
import LayoutNotice from '../components/LayoutNotice.vue';

const appStore = useAppStore();
</script>

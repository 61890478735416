<script setup lang="ts">
import { computed, watch } from 'vue';
import { useAccountStore } from '@/stores/accountStore';
import { useRoute } from 'vue-router'

const route = useRoute()
const accountStore = useAccountStore();
const account = computed(() => accountStore.account);

watch(() => route.params.accountId, async newId => {
  accountStore.get(newId as string);
}, { immediate:true });
</script>

<template>
  <PageLayout :heading="account?.name" v-if="!route.meta.hideHeading">
    <router-view />
  </PageLayout>
  <router-view v-else />
</template>

<template>
  <v-col cols="12" lg="3">
    <AmountField
      v-model="form.account.grossAmount"
      v-model:currency-code-value="form.account.currencyCode"
      :label="t('label.gross_payment')"
      :rules="[rules.required]"
      allCurrencies
    />
  </v-col>
  <v-col cols="12" lg="3">
    <AmountField
      v-model="form.account.tax"
      v-model:currency-code-value="form.account.currencyCode"
      :label="t('label.tax_withheld')"
      hideCurrency
    />
  </v-col>
  <v-col cols="12" lg="3" v-if="!isNil(form.account.fee)">
    <AmountField
      v-model="form.account.fee"
      v-model:currency-code-value="form.account.currencyCode"
      :label="t('label.fees')"
      hideCurrency
    />
  </v-col>
  <v-col cols="12" lg="3">
    <div>
      Netoväljamakse: <strong>{{ currencyFormatter(form.account.netAmount, form.account.currencyCode) }}</strong>
    </div>
    <div v-if="isNil(form.account.fee)">
      <v-btn prepend-icon="mdi-plus" @click="form.account.fee = 0" size="x-small" variant="tonal" color="primary">Lisa tasud</v-btn>
    </div>
  </v-col>
</template>

<script setup lang="ts">
import { currencyFormatter, rules } from '@/const';
import isNil from "lodash/isNil";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps({
  form: {
    type: null,
    required: true
  }
})
</script>
<script setup lang="ts">
import { computed, watch } from 'vue';
import { ref } from 'vue';
import SelectFileStep from './SelectFileStep.vue';
import ConfirmStepVue from './ConfirmStep.vue';
import { useRoute } from 'vue-router';
import { relatedAccount } from '@/utils/account.utils';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { mapToIntnernal } from './utils';
import PageLayout from '@/layouts/PageLayout.vue';
import { useI18n } from 'vue-i18n';

enum ImportState {
  ChooseFile,
  SelectColumns,
  Confirm
}

const { t } = useI18n();
const route = useRoute();
const portfolioStore = usePortfolioStore();

const preparedTransactions = ref<any>(null);
const showOverlay = ref(false);

const state = computed(() => {
  if(preparedTransactions.value) {
    return ImportState.Confirm;
  } else {
    return ImportState.ChooseFile;
  }
})

watch(() => route.query.accountId, async (_accocuntId) => {
  if (!_accocuntId) {
    return;
  }
  if (!route.query.importType) {
    return;
  }
  let accocuntId = parseInt(_accocuntId as any as string);

  showOverlay.value = true;
  const transactions = await portfolioStore.prepareUnconfirmedTransactions(portfolioStore.activePortfolioId, accocuntId as any as number)

  preparedTransactions.value = {
    transactions: mapToIntnernal(transactions),
    cashAccountId: accocuntId,
    cashAccount: relatedAccount(ref(accocuntId))
  }
  showOverlay.value = false
}, { immediate: true });

const title = computed(() => {
  return preparedTransactions.value?.cashAccount?.name || t('import_page.title');
});
</script>

<template>
  <v-overlay v-model="showOverlay" class="align-center justify-center">
    <v-progress-circular size="128" indeterminate color="white" />
  </v-overlay>
  <PageLayout :heading="title" v-if="!showOverlay">
    <SelectFileStep
      v-if="!showOverlay && state === ImportState.ChooseFile"
      v-model="preparedTransactions"
    />
    <ConfirmStepVue
      v-else-if="!showOverlay && state === ImportState.Confirm"
      :transactions="preparedTransactions.transactions"
      :cash-account-id="preparedTransactions.cashAccountId"
      :cash-account="preparedTransactions.cashAccount"
    />
  </PageLayout>
</template>

import { InstrumentType, TransactionType } from "../enums";
import { InstrumentConfig } from "./Instrument.config";

export class TakenLoanConfigClass extends InstrumentConfig {
  type: InstrumentType = InstrumentType.TakenLoan;
  showQuantityInValue: boolean = false;
  quantityIsCurrency: boolean = true;
  icon: string = '📅';

  allowedTransactionTypes = [
    TransactionType.Withdraw,
    TransactionType.LoanPrincipalPaid,
    TransactionType.InterestPaid,
    TransactionType.Fee,
  ]

  allowedIncomeTypes = [
  ]

  defaultPrice: number = 1
  hasPrice: boolean = false
  showQuantity: boolean = false
}

export default new TakenLoanConfigClass();
